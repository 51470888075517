import React from "react";
import "./Pau.css";
import Carousel from "react-bootstrap/Carousel";
// import wheatPoplar from "../Assests/poplartreesWheat.png";
function Pau(props) {

  var imagesFunc = () => {
    var images = [];
    images.push("pauPoplar.JPG");
    images.push("pauDifference.JPG");
    images.push("pauWheat.JPG");

    images.push("pauFertilizerUrea.JPG");
    images.push("pauNutriient_N_Urea.JPG");
    images.push("pauZn1.JPG");

    images.push("pauZN.JPG");



    images.push("pauFertize.jpeg");
    images.push("pauMultipleCrop.jpeg");
    images.push("pauWeight.jpeg");
    images.push("pauInsects.jpeg");
    images.push("pauMultipleCropAna.jpeg");

    images.push("pau_leaves.jpeg");
    images.push("pausundi.jpeg");

   




    return images;
  };

  const getImage = (image) => {
    return (
      <img
        className="l-block w-100 imageCustomThigs"
        src={require(`../Assests/pau/${image}`)}
        alt="First slide"
      />
    );
  };

  return (
    <div className="superMain">
     <h1>  PAU visual information</h1>   
    <div className="mainContinerPau">
      <Carousel variant="light">
        {imagesFunc().map((item) => (
          <Carousel.Item>{getImage(item)}</Carousel.Item>
        ))}

     
      </Carousel>
    </div>
    </div>
  );
}

export default Pau;

// <Carousel.Item>
// <img
//   className="l-block w-100 imageCustomThigs"
//   src={wheatPoplar}
//   alt="Second slide"
// />
// <Carousel.Caption>
//   <h5>Second slide label</h5>
//   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
// </Carousel.Caption>
// </Carousel.Item>
// <Carousel.Item>
// <img
//   className="l-block w-100 imageCustomThigs"
//   src={wheatPoplar}
//   alt="Third slide"
// />
// <Carousel.Caption>
//   <h5>Third slide label</h5>
//   <p>
//     Praesent commodo cursus magna, vel scelerisque nisl consectetur.
//   </p>
// </Carousel.Caption>
// </Carousel.Item>
