import React, { useState } from "react";
import "./NewBuyerDetails.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
const NewBuyerDetails = ({ formdata, callback }) => {
  const apiUrl = `${process.env["REACT_APP_API_URL"]}customer/`;
  const toolTipsPopover = (title) => (
    <Popover
      className="d-inline-block"
      id="popover-trigger-hover-focus"
      title="rrr"
    >
      <div className="standardPading">
        <strong>{title}</strong>
      </div>
    </Popover>
  );
  const [formUserData, setFormUserData] = useState(
    formdata
      ? formdata
      : {
          name: "",
          phone1: "",
          fatherName: "",
          address: "",
          OtherName: "",
          phone2: "",
          fromAddCustomer : false
        }
  );
  const [formErrors, setFormErrors] = useState({});
  const handleInputChange = (e, field) => {
    // Remove any non-numeric characters from input
    // Set the state with the formatted phone number
    console.log(e, field);
    setFormUserData({ ...formUserData, [field]: e });
  };
  // Function to validate form fields
  const validateForm = () => {
    const errors = {};
    // Check if any required field is empty
    setFormErrors({});
    if (!formUserData.name || !formUserData.phone1 || !formUserData.address) {
      errors.requiredFields = "Please fill in all required fields ";
    }
    setFormErrors(errors);
    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  const handlePhoneNumberChange = (e) => {
    // Remove any non-numeric characters from input
    const inputPhoneNumber = e.target.value.replace(/\D/g, "");
    // Format the phone number as per Indian format (e.g., +91 XXXXXXXXXX)
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 0) {
      formattedPhoneNumber += inputPhoneNumber.substring(0, 5);
      if (inputPhoneNumber.length > 5) {
        formattedPhoneNumber += " " + inputPhoneNumber.substring(5);
      }
    }
    // Set the state with the formatted phone number
    setFormUserData({ ...formUserData, phone1: formattedPhoneNumber });
  };
  const handlePhoneNumberChange1 = (e) => {
    // Remove any non-numeric characters from input
    const inputPhoneNumber = e.target.value.replace(/\D/g, "");
    // Format the phone number as per Indian format (e.g., +91 XXXXXXXXXX)
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 0) {
      formattedPhoneNumber += inputPhoneNumber.substring(0, 5);
      if (inputPhoneNumber.length > 5) {
        formattedPhoneNumber += " " + inputPhoneNumber.substring(5);
      }
    }
    // Set the state with the formatted phone number
    setFormUserData({ ...formUserData, phone2: formattedPhoneNumber });
  };
  const handleCancelEdit = async (event) => {
    event.preventDefault();
    callback({
      ...formUserData,
      isEditing: false, // Initially set to false
      isDeletedTapped: false,

      temporary: {},
    });
  };
  const handleAddRow = async (event) => {
    event.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      try {
        await handleSubmit();

        //   await getClones(); // Reload the listing after adding a new clone
      } catch (error) {
        console.error("Error adding clone:", error);
      }
    } else {
      // Form is not valid, display error message
      console.log(
        "Form submission failed. Please fill in all required fields."
      );
    }
  };
  const handleSubmit = async () => {
    await axios
      .post(`${apiUrl}createUpdate`, formUserData)
      .then(function (response) {
        // navigate("/listing");
        if (response.data.status) {
          callback(response.data.item);
          let item = response.data.item;
          item = {
            ...item,
            isEditing: false, // Initially set to false
            isDeletedTapped: false,
            fromAddCustomer: formUserData.fromAddCustomer,
            temporary: {},
          };
          callback(item);

          //   navigate("/listing");
          // window.page.reload();
        } else {
          alert("Something went Wrong");
          // window.page.reload();
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  return (
    <div>
      <h4 class="h4_heading">Buyer Detail</h4>
      <h1>{formUserData.isEditing}</h1>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={formUserData.name}
              required
              isInvalid={!formUserData.name.length}
              onChange={(e) => handleInputChange(e.target.value, "name")}

              //   readOnly={true}
            />
            <Form.Control.Feedback type="invalid">
              Please enter name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label> Son of </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Son of"
              value={formUserData.fatherName}
              onChange={(e) => handleInputChange(e.target.value, "fatherName")}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Phone 1</Form.Label>
            <Form.Control
              type="tel"
              value={formUserData.phone1}
              onChange={handlePhoneNumberChange}
              placeholder="XXXXX XXXXX"
              maxLength="11" // Maximum length including country code and spaces
              required
              isInvalid={!formUserData.phone1.length}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your phone number.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>User Address</Form.Label>
          <Form.Control
            placeholder="1234 Main St"
            type="text"
            value={formUserData.address}
            onChange={(e) => handleInputChange(e.target.value, "address")}
            isInvalid={!formUserData.address.length}
          />
          <Form.Control.Feedback type="invalid">
            Please enter Address.
          </Form.Control.Feedback>
        </Form.Group>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Other Contact</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={formUserData.OtherName}
              onChange={(e) => handleInputChange(e.target.value, "OtherName")}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Phone 2</Form.Label>
            <Form.Control
              type="tel"
              value={formUserData.phone2}
              onChange={handlePhoneNumberChange1}
              placeholder="XXXXX XXXXX"
              maxLength="11" // Maximum length including country code and spaces
            />
          </Form.Group>
        </Row>

        {formErrors.requiredFields && <p>{formErrors.requiredFields}</p>}

        {formUserData.isEditing ? (
          <>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={toolTipsPopover("Cancel editing")}
            >
              <button
                type="submit"
                className="btnRF-cross btn-sm"
                onClick={handleCancelEdit}
              >
                <i class="bi bi-x-square-fill"></i>{" "}
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={toolTipsPopover("Save it")}
            >
              <button className="btnRF-save btn-sm" onClick={handleAddRow}>
                <i class="bi bi-floppy-fill"></i>{" "}
              </button>
            </OverlayTrigger>
          </>
        ) : (
          <Form.Group as={Col} controlId="formGridEmdail">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              splacement="top"
              overlay={toolTipsPopover("Add new Customer")}
            >
              <button className="btn-sm" onClick={handleAddRow}>
                <i className="bi bi-plus-square"></i>
              </button>
            </OverlayTrigger>
          </Form.Group>
        )}
      </Form>

      {/* village and tehsil */}
    </div>
  );
};

// export default Admin;
export default NewBuyerDetails;
