import React, { useState } from "react";
import styles from "./FertilizerNutrientCalculator.css";

import FertilizerNutrientCalculator2Container from "./FertilizerNutrientCalculator2Container.js";

function FertilizerNutrientCalculator() {
  // ----------------------------------------
  const [f_nutreientInput, setF_nutreientInput] = useState(20);
  const [f_nutreientPercentageInput, setF_nutreientPercentageInput] = useState(8.8);


const getf_nutreientPercentageInput = (e) => {

    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setF_nutreientPercentageInput(someKilla);

  
  };
  const getf_nutreientInput = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setF_nutreientInput(someKilla);
  };

  const f_Result = () => {
   
    return ((f_nutreientInput / f_nutreientPercentageInput) * 100)
  }

 // ----------------------------------------
 const [n_fertilizerInput, setN_fertilizerInput] = useState(125);
 const [n_nutreientPercentageInput, setN_nutreientPercentageInput] = useState(8.8);


const getN_nutreientPercentageInput = (e) => {

   let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
   setN_nutreientPercentageInput(someKilla);

 
 };
 const getn_fertilizerInput = (e) => {
   let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
   setN_fertilizerInput(someKilla);
 };

 const n_Result = () => {
   
   return ((n_fertilizerInput * n_nutreientPercentageInput)  / 100)
 }



  // ----------------------------------------


  return (
    <>
      <div className="FerNutCalculator">
       <div>
        <h1> Fertilizer Nutrient Calculation </h1>
        <p>
          {" "}
          These calulations helps to determine fertilzer or Nutrients that
          farmer add in soil. Also Before applying fertilisers of any type,
          farmer should access the nutrient content of soil, and understand that
          other factors - soil type, soil depth, current pasture or crop type
          abd previous paddock histor
        </p>

        <div className="div_SuperF_N">

          {/*  ----------nutrientRequiredContainer--------------- */}

          <div className="nutrientRequiredContainer">
            <h3> Calculating the amount of 
                {" "}
            <span className="nutrientUnit">
            Nutrient
                            </span>
                            {" "}
                 required</h3>
            <p>
              This infomation can be used to calculate the amount of a nutrient
              needed for a given amount of a fertiliser:
            </p>
            <hr />
            <div>
              <h4>General Formula </h4>
              <p className="nestedFN">
                <span className="nutrientUnit">
                  Amount of nutrient (kg/Acre)
                </span>
                <span className="operatorFN"> = </span>{" "}
                <span className=" fertilizerUnit">
                  {" "}
                  Amount of fertiliser(kg/Acre){" "}
                </span>
                <span className="operatorFN"> X </span>
                <span className="nutrientPercentageUnit">
                  % nutrient in fertiliser
                </span>{" "}
                <span className="operatorFN"> ÷ </span> 100.
              </p>
              <hr />
              <h4>Example </h4>
              <p className="nestedFN">
                You plan to apply{" "}
                <span className=" fertilizerUnit">
                  {" "}
                  125 kg/acre of single superphosphate{" "}
                </span>{" "}
                with
                <span className="nutrientPercentageUnit">8.8% P</span>.
                <br />
             
              </p>
              <hr />
                <h4> Apply Calculation </h4>
                <p>
                  <form action="/action_page.php">
                    <table>
                      <tr >
                        <th rowSpan={2}>
                          <label for="fname">
                            {" "}
                            <span className="nutrientUnit">
                              Amount of nutrient (kg/Acre)
                            </span>
                          </label>{" "}
                        </th>
                      
                        <th className="nestedFN" >
                        {" "}
                          <span className="operatorFN"> = </span>{" "}
                          <input
                            type="number"
                              className="fertilizerUnitInput  "
                            name="number_n_fertilizerInput"
                            placeholder=" Amount of fertiliser(kg/Acre)"
                              onChange={getn_fertilizerInput}
                              value={n_fertilizerInput}
                             

                

                          />{" "}
                        
                          <span className="operatorFN"> X </span>
                          <input
                            type="number"
                               className="nutrientPercentageUnitInput  "
                            name="number"
                            placeholder=" % nutrient in fertiliser"
                              onChange={getN_nutreientPercentageInput}
                              value={n_nutreientPercentageInput}

                      
                            
                          />{" "}
                          {" "}
                          <span className="operatorFN"> ÷ </span> 100.
                        </th>
                      </tr>
                      <tr>
                      
                        <td>  <label for="fname">
                            {" "}
                            <span className="operatorFN"> = </span>
                            <span className="nutrientUnit">
                              {n_Result()} (kg/Acre)
                            </span>
                          </label>{" "}</td>
                      </tr>
                    </table>
                  </form>
                </p>
            </div>
          </div>
          {/*  ----------fertiliserRequiredContainer--------------- */}
          <div className="fertiliserRequiredContainer">
            <h3> Calculating the amount of  
            {" "}
            <span className="fertilizerUnit">
            fertiliser
                </span>  {" "}
                required</h3>
            <p>
              This infomation can be used to calculate the amount of a
              fertiliser needed for a given amount of a nutrient:
            </p>
            <hr />

            <div>
              <h4>General Formula </h4>
              <p className="nestedFN">
                <span className="fertilizerUnit">
                  Amount of fertiliser kg/Acre{" "}
                </span>
                <span className="operatorFN"> =</span>{" ( "}
                <span className="nutrientUnit"> amount of nutrient (kg/Acre ) </span>
                <span className="operatorFN"> ÷ </span>{" "}
                <span className="nutrientPercentageUnit">
                  % nutrient in fertiliser
                </span>{" ) "}
                <span className="operatorFN"> x </span> 100.
              </p>
              <hr />
              <h4>Example </h4>
              <p className="nestedFN">
                You need{" "}
                <span className="nutrientUnit">
                  {" "}
                  20 units (kg) /Acre of phosphorus (P){" "}
                </span>{" "}
                <span className="operatorFN"> and </span> you plan to{" "}
                <span className="operatorFN"> use </span>
                <span className="nutrientPercentageUnit">
                  single-super phosphate with 8.8% P.
                </span>
              </p>
              <hr />
                <h4> Apply Calculation </h4>
                <p>
                  <form action="/action_page.php">
                    <table>
                      <tr >
                        <th rowSpan={2}>
                          <label for="fname">
                            {" "}
                            <span className="fertilizerUnit">
                            Amount of fertiliser (kg/Acre )
                            </span>
                          </label>{" "}
                        </th>
                      
                        <th className="nestedFN" >
                        {" "}
                          <span className="operatorFN"> = </span>{" ( "}
                          <input
                            type="number"
                              className="nutrientUnitInput  "
                            name="email"
                            placeholder="amount of nutrient (kg/Acre ) "
                               onChange={getf_nutreientInput}
                              value={f_nutreientInput}
                          />{" "}
                        
                          <span className="operatorFN"> ÷  </span>
                          <input
                            type="number"
                               className="nutrientPercentageUnitInput  "
                            name="number"
                            placeholder=" % nutrient in fertiliser"
                              onChange={getf_nutreientPercentageInput}
                              value={f_nutreientPercentageInput}
                          />{" "}
                          {" ) "}
                          <span className="operatorFN"> X </span> 100.
                        </th>
                      </tr>
                      <tr>
                      
                        <td className="">  <label for="fname">
                            {" "}
                            <span className="operatorFN"> = </span>
                            <span className="fertilizerUnit">
                              {f_Result()} (kg/Acre)
                            </span>
                          </label>{" "}</td>
                      </tr>
                    </table>
                  </form>
                </p>



            </div>
          </div>



        </div>

 

        </div>
        <div>
            <FertilizerNutrientCalculator2Container/>
            
        </div>
      </div>
    </>
  );
}

export default FertilizerNutrientCalculator;
