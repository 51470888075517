import React, { useState } from "react";

import styles from "./Calculator.module.css";

export default function Calculator() {
  // ----------------------------------------
  const killaThis = React.useRef();
  const [killaInput, setKillaInput] = useState(0);

  const getInputFieldKilla = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setKillaInput(someKilla);
  };
  // ----------------------------------------
  const hectareThis = React.useRef();
  const [hectareInput, setHectareInput] = useState(0);

  const getHectareInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setHectareInput(someKilla);
  };
  // ----------------------------------------
  const bighaThis = React.useRef();
  const [bighaInput, setBighaInput] = useState(0);

  const getBighaInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setBighaInput(someKilla);
  };

  // ----------------------------------------
  const kanaalThis = React.useRef();
  const [kanaalInput, setKanaalInput] = useState(0);

  const getKanaalInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setKanaalInput(someKilla);
  };
  // ----------------------------------------
  const marlaThis = React.useRef();
  const [marlaInput, setMarlaInput] = useState(0);

  const getMarlaInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setMarlaInput(someKilla);
  };

  // ----------------------------------------
  const biswaIThis = React.useRef();
  const [biswaInput, setBiswaInput] = useState(0);

  const getBiswaInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setBiswaInput(someKilla);
  };

  // ----------------------------------------
  const sarsahIThis = React.useRef();
  const [sarsahiInput, setSarsahiInput] = useState(0);

  const getSarsahiInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setSarsahiInput(someKilla);
  };

  // ----------------------------------------
  const lenghtThis = React.useRef();
  const [lenghtInput, setLenthInput] = useState(0);

  const getLenthInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setLenthInput(someKilla);
  };

  // ----------------------------------------

  const breadthThis = React.useRef();
  const [breadthInput, setBreadthInput] = useState(0);

  const getBreadthInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setBreadthInput(someKilla);
  };
  // ----------------------------------------
  const lenghtMeterThis = React.useRef();
  const [lenghtMeterInput, setLenthMeterInput] = useState(0);

  const getLenthMeterInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setLenthMeterInput(someKilla);
  };

  // ----------------------------------------

  const breadthMeterThis = React.useRef();
  const [breadthMeterInput, setBreadthMeterInput] = useState(0);

  const getBreadthMeterInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setBreadthMeterInput(someKilla);
  };

  function actionMarlaToSqFt(param) {
    return param * 9 * 5.5 * 5.5;
  }
  function actionBiswaToSqFt(param) {
    return param * 15 * 5.5 * 5.5;
  }

  const sqFt_hectare = hectareInput * 107639;

  const sgFt_Killa = actionBiswaToSqFt(killaInput * 96);

  const sgFt_bigha = actionBiswaToSqFt(bighaInput * 20.0);

  const sgFt_kanaal = actionBiswaToSqFt(kanaalInput * 12.0);

  const sgFt_biswa = actionBiswaToSqFt(biswaInput);

  const sgFt_marla = actionMarlaToSqFt(marlaInput);

  const sgFt_sarsahi = actionMarlaToSqFt(sarsahiInput / 9);

  const sgFt_lb = lenghtInput * breadthInput;
  const sqMeter_lb = lenghtMeterInput * breadthMeterInput;

  const sqFt_meter = sqMeter_lb * 10.7639;

  let sqft_total =
    sgFt_Killa +
    sgFt_bigha +
    sgFt_kanaal +
    sgFt_biswa +
    sgFt_marla +
    sgFt_sarsahi +
    sgFt_lb +
    sqFt_meter +
    sqFt_hectare;

  const actionClear = (e) => {
    // ______________
    hectareThis.current.value = "";
    setHectareInput(0);

    // do cool stuff here!
    killaThis.current.value = "";
    setKillaInput(0);

    // ______________
    bighaThis.current.value = "";
    setBighaInput(0);

    // ______________
    kanaalThis.current.value = "";
    setKanaalInput(0);

    // ______________
    marlaThis.current.value = "";
    setMarlaInput(0);

    // ______________
    biswaIThis.current.value = "";
    setBiswaInput(0);

    // ______________
    sarsahIThis.current.value = "";
    setSarsahiInput(0);

    // ______________
    lenghtThis.current.value = "";
    setLenthInput(0);

    // ______________
    breadthThis.current.value = "";
    setBreadthInput(0);

    // ______________
    lenghtMeterThis.current.value = "";
    setLenthMeterInput(0);

    // ______________
    breadthMeterThis.current.value = "";
    setBreadthMeterInput(0);
  };

  return (
    // <div  >
    //   <h1 className={styles.someBody12} > This is the first preview of calculator</h1>
    // </div>
    <>
      <div className=" ">
        <div className={styles.calculatorConatiner}>
          <form>
            {/* _____---------______________ */}
            <div className="form-group row my-2 ">
              <label
                htmlFor="colFormLabel"
                className="col-sm-3 col-form-label "
              >
                Killa
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  value={killaInput}
                  className="form-control "
                  id="colFormLabel"
                  placeholder="Enter the Killa"
                  onChange={getInputFieldKilla}
                  ref={killaThis}
                />
              </div>
            </div>
            {/* _____---------______________ */}
            <div className="form-group row my-2">
              <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                + Hectare
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  className="form-control"
                  id="colFormLabel"
                  placeholder="Enter the Hectare"
                  onChange={getHectareInput}
                  ref={hectareThis}
                />
              </div>
            </div>
            {/* _____---------______________ */}
            <div className="form-group row my-2">
              <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                + Bigha
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  className="form-control"
                  id="colFormLabel"
                  placeholder="Enter the Bigha"
                  onChange={getBighaInput}
                  ref={bighaThis}
                />
              </div>
            </div>
            {/* _____---------______________ */}
            <div className="form-group row my-2">
              <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                + Kanaal
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  className="form-control"
                  id="colFormLabel"
                  placeholder="Enter Kanaal"
                  onChange={getKanaalInput}
                  ref={kanaalThis}
                />
              </div>
            </div>
            {/* _____---------______________ */}
            <div className="form-group row my-2">
              <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                + Biswa
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  className="form-control"
                  id="colFormLabel"
                  placeholder="Enter Biswa"
                  onChange={getBiswaInput}
                  ref={biswaIThis}
                />
              </div>
            </div>
            {/* _____---------______________ */}
            <div className="form-group row my-2">
              <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                + Marla
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  className="form-control"
                  id="colFormLabel"
                  placeholder="Enter Marla"
                  onChange={getMarlaInput}
                  ref={marlaThis}
                />
              </div>
            </div>
            {/* _____---------______________ */}
            <div className="form-group row my-2">
              <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                + Sarsahi
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  className="form-control"
                  id="colFormLabel"
                  placeholder="Enter Sarsahi"
                  onChange={getSarsahiInput}
                  ref={sarsahIThis}
                />
              </div>
            </div>

            {/* _____---------______________ */}

            {/* 777777777--------------------------------------- */}
            <div>
              <div className="row my-2 ">
                {/* _____---------______________ */}
                <div className="col my-2">
                  <label htmlFor="colFormLabel" className="col col-form-label">
                    + Length(feet)
                  </label>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      id="colFormLabel"
                      placeholder="Enter Lenth in feet"
                      onChange={getLenthInput}
                      ref={lenghtThis}
                    />
                  </div>
                </div>
                <div className="col  my-2 ">
                  <label htmlFor="colFormLabel" className="col col-form-label">
                    * breadth(feet)
                  </label>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control pull-right"
                      id="colFormLabel"
                      placeholder="Enter Breadth in feet "
                      onChange={getBreadthInput}
                      ref={breadthThis}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row my-2 ">
                {/* _____---------______________ */}
                <div className="col my-2">
                  <label htmlFor="colFormLabel" className="col col-form-label">
                    + Length(Meter)
                  </label>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      id="colFormLabel"
                      placeholder="Enter Lenth in Meter"
                      onChange={getLenthMeterInput}
                      ref={lenghtMeterThis}
                    />
                  </div>
                </div>
                <div className="col  my-2 ">
                  <label htmlFor="colFormLabel" className="col col-form-label">
                    * breadth(Meter)
                  </label>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control pull-right"
                      id="colFormLabel"
                      placeholder="Enter Breadth in Meter "
                      onChange={getBreadthMeterInput}
                      ref={breadthMeterThis}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* ffjkhkjhjkdfjkdh */}
          <div className="container">
            <button
              type="button"
              className="btn btn-success my-2 "
              onClick={actionClear}
            >
              Clear
            </button>
          </div>
        </div>

        <div className={styles.calculatorConatinerResult}>
          <h1>Result is </h1>

          {/* _____________________________________________________________________________________________________________ */}
          <table className="table table-bordered">
            <thead>
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="3">Total =</td>
                {/* <td></td> */}
              </tr>
            </thead>

            <tbody>
              <tr>
                <td></td>
                <td>
                  + {killaInput} <strong>killa. </strong>
                </td>
                <td>
                  = {sgFt_Killa} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                <td></td>
                <td>
                  + {hectareInput} <strong>hectare. </strong>
                </td>
                <td>
                  = {sqFt_hectare} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                <td></td>
                <td>
                  + {bighaInput} <strong>bigha. </strong>
                </td>
                <td>
                  = {sgFt_bigha} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                <td></td>
                <td>
                  + {kanaalInput} <strong>Kanaal. </strong>
                </td>
                <td>
                  = {sgFt_kanaal} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                <td></td>
                <td>
                  + {biswaInput} <strong>biswa. </strong>
                </td>
                <td>
                  = {sgFt_biswa} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                <td></td>
                <td>
                  + {marlaInput} <strong>marla. </strong>
                </td>
                <td>
                  = {sgFt_marla} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                <td></td>
                <td>
                  + {sarsahiInput} <strong>sarsahi. </strong>
                </td>
                <td>
                  = {sgFt_sarsahi} <strong>SqFt. </strong>{" "}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>
                  + {lenghtInput} <strong> Lt(ft) </strong> * {breadthInput}{" "}
                  <strong> Bt(ft) </strong>{" "}
                </td>
                <td>
                  = {sgFt_lb} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
              {/* ------------------- */}
              <tr>
                <td></td>
                <td>
                  + {lenghtMeterInput} <strong> Lt(Meter) </strong> *{" "}
                  {breadthMeterInput} <strong> Bt(Meter) </strong> <br /> ={" "}
                  {sqMeter_lb} <strong> Sq(Meter). </strong>
                </td>
                <td>
                  = {sqFt_meter} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
            </tbody>
          </table>
          {/* ___________________________________ */}
          <table className="table table-striped table-bordered ">
            <tr>
              {/* <th scope="row">3</th> */}
              <td colSpan="3">
                Grand <strong>Total</strong> in differnt units.
              </td>
            </tr>
            <tbody>
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total} <strong>SqFt. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}

              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total * 0.092903} <strong>Meter Square. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}

              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total / actionBiswaToSqFt(1.0 * 96)}{" "}
                  <strong>killa. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total * 0.000009290303997} <strong>hectare. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total / actionBiswaToSqFt(1.0 * 20.0)}{" "}
                  <strong>bigha. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total / actionBiswaToSqFt(1.0 * 12.0)}{" "}
                  <strong>Kanaal. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total / actionBiswaToSqFt(1.0 * 1.0)}{" "}
                  <strong>biswa. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total / actionMarlaToSqFt(1.0 * 1.0)}{" "}
                  <strong>marla. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {(sqft_total / actionMarlaToSqFt(1.0 * 1.0)) * 9}{" "}
                  <strong>sarsahi or Sq karma. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total / 9} <strong>Gazz Sq or Yard Sq. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
              <tr>
                {/* <th scope="row">3</th> */}
                <td colSpan="2"></td>
                <td>
                  = {sqft_total / 107639.111} <strong>Hectare. </strong>{" "}
                </td>
              </tr>
              {/* ___________________________________ */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
