import React, { useState } from "react";
import styles from "./LenghtCalculator.css";

function LenghtCalculator() {
  // ----------------------------------------
  const hectareThis = React.useRef();
  const [hectareInput, setHectareInput] = useState(0);
  const [currentUnitInput, setCurrentUnitInput] = useState("feet");

  const [info , setInfo] = useState({ 
           cm : 0 , 
           inches : 0,
           feet : 0 , 
           gazz : 0 , 
           meter : 0 , 
           karma : 0 
           })

  const getHectareInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
     setHectareInput(someKilla);

     updateValues(currentUnitInput , someKilla )
  };

  const updateValues = (unit , currentInputPass) => {
    
    const newObject = {cm : 0 , 
      inches : 0,
      feet : 0 , 
      gazz : 0 , 
      meter : 0 , 
      karma : 0 }



     switch(unit){

      case "centimeter":
     
    
        newObject.cm = currentInputPass
        newObject.inches = currentInputPass / 2.54 
        newObject.feet = currentInputPass / 30.48
        newObject.gazz = currentInputPass / 91.44
        newObject.meter = currentInputPass / 100
        newObject.karma =  currentInputPass / 167.64
        break
      case "inch": 
    
      newObject.cm = currentInputPass * 2.54
      newObject.inches = currentInputPass
      newObject.feet = (currentInputPass / 30.48 ) * 2.54
      newObject.gazz = (currentInputPass / 91.44 ) * 2.54
      newObject.meter = (currentInputPass / 100 ) * 2.54
      newObject.karma =  (currentInputPass / 167.64) * 2.54
       break
        case "feet":
       
          newObject.cm = currentInputPass * 30.48
          newObject.inches = (currentInputPass / 2.54 ) * 30.48
          newObject.feet = currentInputPass
          newObject.gazz = (currentInputPass / 91.44 ) * 30.48
          newObject.meter = (currentInputPass / 100 ) * 30.48
          newObject.karma =  (currentInputPass / 167.64 ) * 30.48
    
          break

        case "gazz": 
     
        newObject.cm = currentInputPass * 91.44
        newObject.inches = (currentInputPass / 2.54 ) * 91.44
        newObject.feet = (currentInputPass / 30.48 ) * 91.44 
        newObject.gazz = currentInputPass
        newObject.meter = (currentInputPass / 100 ) * 91.44 
        newObject.karma =  (currentInputPass / 167.64 ) * 91.44

        break

          case "meter":
          
          
                newObject.cm = currentInputPass * 100
                newObject.inches = (currentInputPass / 2.54 ) * 100
                newObject.feet = (currentInputPass / 30.48 ) * 100
                newObject.gazz = (currentInputPass / 91.44 ) * 100
                newObject.meter = currentInputPass
                newObject.karma =  (currentInputPass / 167.64 ) * 100
        




                break
          case "karma": 
                 //  fdf
              

                newObject.cm = currentInputPass * 167.64
                newObject.inches = (currentInputPass / 2.54 ) * 167.64
                newObject.feet = (currentInputPass / 30.48 ) * 167.64
                newObject.gazz = (currentInputPass / 91.44 ) * 167.64 
                newObject.meter = (currentInputPass / 100 ) * 167.64
                newObject.karma =  currentInputPass



                 break


      default:
      //  fdf
    }
  
    setInfo(newObject)
    
  }


  const getchangeUnit= (e) => {
    // do cool stuff here!
    var ee= document.getElementById("pet-select");
    setCurrentUnitInput(ee.value)
    
    updateValues(ee.value , hectareInput)






  };

  return (
    <>
      <div className="mainLengthCalContainer">
        <div className="mainSubHeading">
          <h1>Length Converter</h1>
          <hr />
        </div>
        <div>
          <input
            type="number"
            className="form-control widthExtra inlineExtra  labelme inlineExtra"
            id="colFormLabel"
            placeholder="Enter the Length"
            onChange={getHectareInput}
            ref={hectareThis}
          />

          <label className="labelme inlineExtra" htmlFor="pet-select">
            Choose the Unit
          </label>

          <select
            name="pets"
            id="pet-select"
            onChange={getchangeUnit}
            className="form-control widthExtra inlineExtra"
          >
            <option value="">--Please choose an Unit--</option>
            <option value="centimeter">centimeter</option>
            <option value="inch">inch</option>
            <option value="feet" selected >feet</option>
            <option value="gazz">gazz (yard)</option>
            <option value="meter">meter</option>
            <option value="karma">karma (Gatha)</option>
          </select>
        </div>
        <div>
          <br />
  
          <table className="tableExtra">
            <tr>
              <th colSpan={3}>Result=</th>
              
            </tr>
            <tr>
                
              <td className="alnright" colSpan={2}>{hectareInput} <span className="divUnit">{currentUnitInput} </span> </td>
              <td className="alnright" > = {info.cm}  <span className="divUnit">centimeter</span> </td>
            </tr>
            {/* //-------------------- */}
            <tr>
              <td className="alnright"  colSpan={2}> </td>
              <td className="alnright" > = {info.inches}  <span className="divUnit">inches</span> </td>
            </tr>
             {/* //-------------------- */}
             <tr>
              <td className="alnright"  colSpan={2}> </td>
              <td className="alnright" > = {info.feet} <span className="divUnit">feet</span> </td>
            </tr>
              {/* //-------------------- */}
              <tr>
              <td  className="alnright" colSpan={2}> </td>
              <td className="alnright" > = {info.gazz}   <span className="divUnit">gazz (Yard )</span> </td>
            </tr>
              {/* //-------------------- */}
              <tr>
              <td colSpan={2} className="alnright"> </td>
              <td className="alnright"> = {info.meter}  <span className="divUnit">meter</span> </td>
            </tr>
              {/* //-------------------- */}
              <tr>
              <td colSpan={2} className="alnright" > </td>
              <td className="alnright" > = {info.karma} <span className="divUnit">karma (Gatha)</span> </td>
            </tr>
      
      
          </table>
        </div>
      </div>
    </>
  );
}

export default LenghtCalculator;
