import React from 'react'
import "./ImageClass.css";


 function ImageClass(props) {
  return (
    // <span className= "rajiFarmTile1_Span"> Raji Farm </span>  
    <img
    src={ props.src}
    alt="alt text"
    className={props.className}
  />)
}



  export {ImageClass};



