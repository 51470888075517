import "./MoneyCalculator.css";
import React, { useState } from "react";

function MoneyCalculator(props) {
  const totalBankNotes = () => {
    var tot =
      input_2000 +
      input_500 +
      input_200 +
      input_100 +
      input_50 +
      input_20 +
      input_10 +
      input_5 +
      input_2 +
      input_1;

    return tot;
  };

  const totalBankNotesAmount = () => {
    var tot =
      input_2000 * 2000 +
      input_500 * 500 +
      input_200 * 200 +
      input_100 * 100 +
      input_50 * 50 +
      input_20 * 20 +
      input_10 * 10 +
      input_5 * 5 +
      input_2 * 2 +
      input_1 * 1;
    return tot;
  };

  // --------------2000--------------------
  const self_2000 = React.useRef();
  const [input_2000, setInput_2000] = useState(0);

  const getInputField2000 = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_2000(someKilla);
  };

  // --------------500--------------------
  const self_500 = React.useRef();
  const [input_500, setInput_500] = useState(0);

  const getInputField500 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_500(someKilla);
  };

  // --------------200--------------------
  const self_200 = React.useRef();
  const [input_200, setInput_200] = useState(0);

  const getInputField200 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_200(someKilla);
  };

  // --------------100--------------------
  const self_100 = React.useRef();
  const [input_100, setInput_100] = useState(0);

  const getInputField100 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_100(someKilla);
  };

  // --------------50--------------------
  const self_50 = React.useRef();
  const [input_50, setInput_50] = useState(0);

  const getInputField50 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_50(someKilla);
  };

  // --------------20--------------------
  const self_20 = React.useRef();
  const [input_20, setInput_20] = useState(0);

  const getInputField20 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_20(someKilla);
  };

  // --------------10--------------------
  const self_10 = React.useRef();
  const [input_10, setInput_10] = useState(0);

  const getInputField10 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_10(someKilla);
  };

  // --------------5--------------------
  const self_5 = React.useRef();
  const [input_5, setInput_5] = useState(0);

  const getInputField5 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_5(someKilla);
  };

  // --------------2--------------------
  const self_2 = React.useRef();
  const [input_2, setInput_2] = useState(0);

  const getInputField2 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_2(someKilla);
  };
  // --------------1--------------------
  const self_1 = React.useRef();
  const [input_1, setInput_1] = useState(0);

  const getInputField1 = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setInput_1(someKilla);
  };

  const getImage = (image) => {
    return (
      <img
        className="imageContainer_Table_Notes"
        src={require(`../Assests/notes/${image}`)}
      />
    );
  };
  const actionClear = (e) => {
    self_2000.current.value = "";
    setInput_2000(0);

    self_500.current.value = "";
    setInput_500(0);

    self_200.current.value = "";
    setInput_200(0);

    self_100.current.value = "";
    setInput_100(0);

    self_50.current.value = "";
    setInput_50(0);

    self_20.current.value = "";
    setInput_20(0);

    self_10.current.value = "";
    setInput_10(0);

    self_5.current.value = "";
    setInput_5(0);

    self_2.current.value = "";
    setInput_2(0);

    self_1.current.value = "";
    setInput_1(0);
  };

  return (
    // <span className= "rajiFarmTile1_Span"> Raji Farm </span>
    <>
      <div className="mainMoneyCalculator">
        <div>
          <div className="container">
            <h1 className="heading"> Bank Notes </h1>
            <button
              type="button"
              className="btn btn-success my-2  headCleanbutton"
              onClick={actionClear}
            >
              Clear
            </button>
          </div>

          <table>
            <tr className="tr1">
              <th className="imagecolumnAttribute">Pic</th>
              <th className="imageNoteAttribute">Note</th>
              <th className="imageNoteInputeAttribute">Input</th>
              <th> Result</th>
            </tr>
            {/* -------------------------------2000------------------------------ */}
            <tr className="tr1">
              <td>{getImage("cc2000c.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 2000 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_2000}
                  className="form-control tdRedult"
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField2000}
                  ref={self_2000}
                />
              </td>
              <td  className="tdRedult" >₹ {input_2000 * 2000}</td>
            </tr>
            {/* ---------------------------500---------------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc500c.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 500 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_500}
                  className="form-control tdRedult "
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField500}
                  ref={self_500}
                />
              </td>
              <td className="tdRedult">₹ {input_500 * 500}</td>
            </tr>

            {/* --------------------------200----------------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc200c.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 200 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_200}
                  className="form-control tdRedult"
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField200}
                  ref={self_200}
                />
              </td>
              <td  className="tdRedult" >₹ {200 * input_200}</td>
            </tr>
            {/* --------------------------------100----------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc100c.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 100 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_100}
                  className="form-control tdRedult"
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField100}
                  ref={self_100}
                />
              </td>
              <td  className="tdRedult" >₹ {input_100 * 100}</td>
            </tr>
            {/* ----------------------------50--------------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc50c.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 50 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_50}
                  className="form-control tdRedult"
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField50}
                  ref={self_50}
                />
              </td>
              <td  className="tdRedult" >₹ {input_50 * 50}</td>
            </tr>
            {/* ----------------------------20--------------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc20c.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 20 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_20}
                  className="form-control tdRedult"
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField20}
                  ref={self_20}
                />
              </td>
              <td  className="tdRedult" >₹ {input_20 * 20}</td>
            </tr>
            {/* -----------------------------10-------------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc10c.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 10 * </td>
              <td >
                {" "}
                <input
                  type="number"
                  value={input_10}
                  className="form-control tdRedult"
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField10}
                  ref={self_10}
                />
              </td>
              <td  className="tdRedult">₹ {input_10 * 10}</td>
            </tr>
            {/* ------------------------------5------------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc5c.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 5 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_5}
                  className="form-control tdRedult"
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField5}
                  ref={self_5}
                />
              </td>
              <td  className="tdRedult" >₹ {input_5 * 5}</td>
            </tr>
            {/* ------------------------------2------------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc2coin.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 2 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_2}
                  className="form-control  tdRedult"
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField2}
                  ref={self_2}
                />
              </td>
              <td  className="tdRedult" >₹ {input_2 * 2}</td>
            </tr>

            {/* ------------------------------1------------------------------- */}
            <tr className="tr1">
              <td>{getImage("cc1coin.jpeg")}</td>
              <td className="imageNoteValueAttribute">₹ 1 * </td>
              <td>
                {" "}
                <input
                  type="number"
                  value={input_1}
                  className="form-control tdRedult "
                  id="colFormLabel"
                  placeholder="Enter Note Values"
                  onChange={getInputField1}
                  ref={self_1}
                />
              </td>
              <td  className="tdRedult">₹ {input_1 * 1}</td>
            </tr>

            {/* cc2coin.jpeg */}

            {/* ------------------------------Total------------------------------- */}
            <tr className="tr2">
              <td>
                {" "}
                <button
                  type="button"
                  className="btn btn-success my-2  headCleanbutton2"
                  onClick={actionClear}
                >
                  Clear
                </button>
              </td>
              <td>Note Total</td>

              <td  className="tdRedult" >{totalBankNotes()}</td>
              <td>₹ {totalBankNotesAmount()}</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}

export default MoneyCalculator;
