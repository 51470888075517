import React from "react";
import "./iconsColor.css";
import "./BuyerDetails.css";
import "bootstrap-icons/font/bootstrap-icons.css";
const BuyerDetails = ({ formdata, callback }) => {
  const handleEdit = () => {
    callback(formdata);
  };

  return (
    <div>
      <div className="right-tools-div">
        <button
          className="btnRF-edit btn-sm float-right"
          onClick={() => handleEdit()}
        >
          <i className="bi bi-pen"></i>
        </button>
      </div>
      <div class="buyer-details">
        <div class="left-div">
          <div>
            <label for="name">Name: </label> &nbsp;
            <span id="name">{formdata.name}</span>
            &nbsp;&nbsp;
            <label for="name"> S/O : </label> &nbsp;
            <span id="name">{formdata.fatherName}</span>
          </div>
          <div>
            <label for="Phone">Phone: </label> &nbsp;
            <span id="Phone">
              <a href={"tel:" + formdata.phone1}>{formdata.phone1}</a>
            </span>
          </div>
          <div>
            <label for="Address">Address: </label> &nbsp;
            <span id="Address">{formdata.address}</span>
          </div>
          <hr className="hr50"></hr>
          <div>
            <label for="Phone">Other Contact: </label> &nbsp;
            <span id="Phone">{formdata.OtherName}</span>
          </div>
          <div>
            <label for="Phone">Phone2: </label> &nbsp;
            <span id="Phone">
              <a href={"tel:" + formdata.phone2}>{formdata.phone2}</a>
            </span>
          </div>
        </div>
        <div class="right-div">
          <div>
            <label for="serial">Customer Serial Number:</label> &nbsp;
            <span id="serial">{formdata.srno}</span>
          </div>
          <div>
            <label for="date">Date:</label> &nbsp;
            <span id="date">{formdata.createdAt}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
// export default Admin;
export default BuyerDetails;
