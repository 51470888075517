import React from "react";
import "./CounterApp.css";

import { useState ,useRef } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSpeechSynthesis } from 'react-speech-kit';
import PrintButton from './PrintButton';
function CounterApp(props) {
  const [name, setName] = React.useState("");
  const [countMe, setCountMe] = React.useState(0);
  const [entries, setEntries] = useState([]);
  const [comment, setComment] = useState("");
  const { speak } = useSpeechSynthesis();
  const inputRef = useRef(null);
  const decrement = (e) => {
    console.log("decrement");

    if (countMe > 0) {
      if (window.confirm("Are you sure you want to decrement?")) {
        setCountMe(countMe - 1);
        const [, ...remainingEntries] = entries; // Remove the first entry using array destructuring
        setEntries([...remainingEntries]);
        setComment("");
        inputRef.current.focus()
      }
    } else {
      alert("Counter cannot be negative!");
    }
  };
  const increment = (e) => {
    setCountMe(countMe + 1);
    console.log("increment");
    if ("vibrate" in navigator) {
        // vibration API supported
      navigator.vibrate(1000);
    }
    speak({ text: ` ${countMe + 1}` });
    inputRef.current.focus()
    setEntries([
      { id: countMe + 1, timestamp: new Date(), comment: comment },
      ...entries,
    ]);
    setComment("");
  };

  function dateReturn(datesend) {
    let diffTime = Math.abs(new Date().valueOf() - datesend.valueOf());
    let days = diffTime / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;
    [days, hours, minutes, secs] = [
      Math.floor(days),
      Math.floor(hours),
      Math.floor(minutes),
      Math.floor(secs),
    ];

    const dd =
      days > 0
        ? `${days} d`
        : "" + hours > 0
        ? `${hours} h`
        : "" + minutes > 0
        ? `${minutes} m`
        : "" + secs > 0
        ? `${secs} s`
        : "0 s";

    // const greeting = `${days} d, ${hours} h,${minutes} m,${secs} s `;
    return dd;
  }

  const reset = (e) => {
    console.log("increment");

    if (countMe > 0) {
      if (window.confirm("Are you sure you want to RESET?")) {
        setCountMe(0);

        setEntries([]);
        setComment("");
        inputRef.current.focus()
      }
    }
  };
  var actionChange_rr_treesCount = (e) => {
    setComment(e.target.value);
  };

  return (
    <>
      <div>
        <h1>Counter App</h1>
        <div id="counter">{countMe}</div>
        <table>
          <tr>
            <td>
              {" "}
              <input
                className="inputfieldValue"
                type="text"
                ref={inputRef}
                value={comment}
                name="name_2000"
                onChange={actionChange_rr_treesCount}
              />{" "}
            </td>

            <td>
              {" "}
              <button onClick={reset}> reset </button>
              <PrintButton contentId="entries-table" fileName={'CounterData.pdf'} className="" />
            </td>
          
          </tr>
          <tr>
            <td>
              <button onClick={increment}> Increment ++ </button>
            </td>
            <td>
              {" "}
              <button className="buttonDelete" onClick={decrement}> Decrement -- </button>{" "}
            </td>
           
          </tr>
        </table>
     

        {entries.length > 0 && (
          <div id="entries-container">
            <h2>Entries Table</h2>
            <div style={{ overflow: "auto", height: "450px", width: "100%" }}>
              <table id="entries-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Comment</th>
                    <th> before</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>

                <tbody>
                  {entries.map((entry) => (
                    <tr key={entry.id}>
                      <td>{entry.id}</td>
                      <td>{entry.comment}</td>
                      <td>{dateReturn(entry.timestamp)}</td>
                      <td>{entry.timestamp.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CounterApp;
