import React from "react";
import "./TextBanner.css";
import {
  RFarm_Title_2,
  RFarm_Title,
  RFarm_poplarTileTitle_2,
  LifeMemberPau,
} from "./GerenicView.js";

function TextBanner() {
  return (
    // <span className= "rajiFarmTile1_Span"> Raji Farm </span>
    <div>



  
      <div  className="Address-banner-Attribute">
      <marquee direction="left">
  <p>
  <span className="class-Note ">Note!</span> { " "}: {" "}Only branch at {" "}
    <span className="fa fa-map-marker"></span>
    <span>{" "} Address: {" "} </span> 
    <a 
      href="https://maps.app.goo.gl/7gsmJ25CVLanPzvT7" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <span>Raji farm: Near Gurmat Missionary College, Or [Sant Kartar Singh computerised Dharam Kanda], Chaunta Kalan, Ropar, Punjab 140111, India.
      <span>{"   "}Tap to get Directions {" "} </span> {"   "}

      
      <span className="class_vision">

      "Raji farm grows more than trees—it grows a vision of harmony between people,
      crops, and nature, where agroforestry leads the way to a greener tomorrow."</span>  </span> 
    </a>
  </p>
</marquee>
      </div>

     

      <div  className="TextBannerAttribute">
      <marquee direction="left">
        Booking of Poplar Nursery
        <span className="class-Clone"> (109,110,112,113,WSL-39)</span> are started, 📞
        Call Raji- <a href="tel://9814164511">9814164511 </a> , Ajay-
        <a href="tel://9914999433">9914999433 </a>, Vijay-
        <a href="tel://7347200037">7347200037 </a>...... we welome you at{" "}
        <RFarm_Title_2 />

      </marquee>
        </div>
    
    </div>
  );
}

//   function RFarm_Title_2() {
//     return (
//       <span className= "clones"> Raji Farm </span>
//     )
//   }

export { TextBanner };
