import React, { useEffect, useState } from "react";
/* global google */
import "./MapCart.css";
import sample from "./AllMapsOfDistrictsSample/FinalAmritsar.json";
import sample2 from "./AllMapsOfDistrictsSample/FinalBathinda.json";
import Barnala from "./AllMapsOfDistrictsSample/FinalBarnala.json";
import FaridKot from "./AllMapsOfDistrictsSample/FinalFaridkot.json";
import Fatehgarh from "./AllMapsOfDistrictsSample/finalFatehgarhSahib.json";
import Fazilka from "./AllMapsOfDistrictsSample/FinalFazilka.json";
import Ferozpur from "./AllMapsOfDistrictsSample/FinalFerozpur.json";
import Gurdaspur from "./AllMapsOfDistrictsSample/finalGurdaspur.json";
import Hoshiarpur from "./AllMapsOfDistrictsSample/FinalHoshiarpur.json";
import Jalandhar from "./AllMapsOfDistrictsSample/FinalJalandhar.json";
import Kapurthala from "./AllMapsOfDistrictsSample/FinalKapurthala.json";
import Ludhiana from "./AllMapsOfDistrictsSample/FinalLudhiana.json";
import Mansa from "./AllMapsOfDistrictsSample/finalMansa.json";
import Moga from "./AllMapsOfDistrictsSample/FinalMoga.json";
import Mohali from "./AllMapsOfDistrictsSample/FinalMohali.json";
import Muktsar from "./AllMapsOfDistrictsSample/FinalMuktsar.json";
import Pathankot from "./AllMapsOfDistrictsSample/FinalPathankot.json";
import Patiala from "./AllMapsOfDistrictsSample/FinalPatiala.json";
import Ropar from "./AllMapsOfDistrictsSample/FinalRopar.json";
import Sangrur from "./AllMapsOfDistrictsSample/FinalSangrur.json";
import SBSNagar from "./AllMapsOfDistrictsSample/FinalSBSNagar.json";
import Taran from "./AllMapsOfDistrictsSample/FinalTarnTaran.json";

import Map from "./Map";

import MapCelMain from "./MapCelMain";
function MapCart() {
  const amritsarArray = sample?.villages;
  const bathindaArray = sample2?.villages;

  const allVilages = amritsarArray.concat(bathindaArray, Barnala.villages
    ,FaridKot.villages,Fatehgarh.villages,Fazilka.villages,Ferozpur.villages
    ,Gurdaspur.villages,Hoshiarpur.villages,Jalandhar.villages,Kapurthala.villages,
    Ludhiana.villages,Mansa.villages,Moga.villages,Mohali.villages,Muktsar.villages,
    Pathankot.villages,Patiala.villages,Ropar.villages, Sangrur.villages,SBSNagar.villages,
    Taran.villages
    );
  const [loadAdddata, setLoadAdddata] = useState([
    {
      formattedAddress: " Abdal, Amritsar, Punjab, India, 143502",
      name: "Abdal",
      districtName: "Amritsar",
      lng: 75.079365699999997,
      otherInfo: "Amritsar -I",
      districtNameType: "amritsar",
      lat: 31.742606200000001,
    },
  ]);
  const [loadAdddata2,setLoadAdddata2] = useState(loadAdddata)
  const [showData1, setShowData1] = useState(allVilages);
  const [searchedtext, setSerchedText] = useState(null);
  const [seechedText,setSerchedText1] = useState(null)
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState({ lat: 30.121360200000002, lng: 75.493455299999994});
  const [latLong, setLatLong] = useState({
    lat: 30.371173,
    lng: 74.88726059999999,
  });
  const [latLong1, setLatLong1] = useState(latLong);
  const [showData, setShowData] = useState(allVilages);
  const handleChangeDistict = (value) => {
    // alert(value);
    switch (value) {
      case "Amritsar":
        setShowData(amritsarArray);
        setShowData1(amritsarArray);
        break;
      case "Barnala":
        setShowData(Barnala.villages);
        setShowData1(Barnala.villages);
        break;
      case "Bathinda":
        setShowData(bathindaArray);
        setShowData1(bathindaArray);
        break;
      case "FaridKot":
        setShowData(FaridKot.villages);
        setShowData1(FaridKot.villages);
        break;
      case "Fatehgarh":
        setShowData(Fatehgarh.villages);
        setShowData1(Fatehgarh.villages);
        break;
      case "Fazilka":
        setShowData(Fazilka.villages);
        setShowData1(Fazilka.villages);
        break;
      case "Ferozpur":
        setShowData(Ferozpur.villages);
        setShowData1(Ferozpur.villages);
        break;
      case "Gurdaspur":
        setShowData(Gurdaspur.villages);
        setShowData1(Gurdaspur.villages);
        break;
      case "Hoshiarpur":
        setShowData(Hoshiarpur.villages);
        setShowData1(Hoshiarpur.villages);
        break;
      case "Jalandhar":
        setShowData(Jalandhar.villages);
        setShowData1(Jalandhar.villages);
        break;
      case "Kapurthala":
        setShowData(Kapurthala.villages);
        setShowData1(Kapurthala.villages);
        break;
      case "Ludhiana":
        setShowData(Ludhiana.villages);
        setShowData1(Ludhiana.villages);
        break;
      case "Mansa":
        setShowData(Mansa.villages);
        setShowData1(Mansa.villages);
        break;
      case "Moga":
        setShowData(Moga.villages);
        setShowData1(Moga.villages);
        break;
      case "Mohali":
        setShowData(Mohali.villages);
        setShowData1(Mohali.villages);
        break;
      case "Muktsar":
        setShowData(Muktsar.villages);
        setShowData1(Muktsar.villages);
        break;
      case "Pathankot":
        setShowData(Pathankot.villages);
        setShowData1(Pathankot.villages);
        break;
      case "Patiala":
        setShowData(Patiala.villages);
        setShowData1(Patiala.villages);
        break;
      case "Ropar":
        setShowData(Ropar.villages);
        setShowData1(Ropar.villages);
        break;
      case "Sangrur":
        setShowData(Sangrur.villages);
        setShowData1(Sangrur.villages);
        break;
      case "SBSNagar":
        setShowData(SBSNagar.villages);
        setShowData1(SBSNagar.villages);
        break;
      case "Taran":
        setShowData(Taran.villages);
        setShowData1(Taran.villages);
        break;
      default:
        setShowData1(allVilages);
        setShowData(allVilages);
        break;
    }
  };
  const updatedListTT = (list) => {
    // setShowData(list)
  };
  
  useEffect(()=>{
    setLoadAdddata2(loadAdddata);
  //   return () => {
  //     // cleanup
  // }
  },[loadAdddata])

  const handleSearch = (e,value) => {
    const query = e.target.value;
    if(value=="left"){
    setSerchedText(query);
    let items = showData.filter(
      (item) => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    // console.log("********",items)
    setShowData1(items);
    }else{
      setSerchedText1(query);
      let items = loadAdddata.filter(
        (item) => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
        setLoadAdddata2(items)
    }
  };

  const loadArray = (item) => {
    let d = [...loadAdddata];
    if (!loadAdddata.map((item) => item.name).includes(item.name)) {
      d.push(item);
      setLoadAdddata(d);
    } else {
      alert("Already addedd");
    }
  };

  const handleCenter = (item) => {
    setLoading(false);
    // localStorage.setItem("lat",item.lat)
    // localStorage.setItem("lng",item.lng)
    setLatLong1(item);
    setLatLong(item);
    setLoading(true);
    // window.location.reload();
    setPosition({ lat: item.lat, lng: item.lng });
  };

  const RemoveArray = (index) => {
    const filterArray = loadAdddata.filter((item, index1) => index1 != index);
    setLoadAdddata(filterArray);
  };

  return (
    <>
      <div className="mainLengthMapCart">
        <div>
          <h3> This page is collection of punjab villages and to navigate the village in google Maps</h3>
          <div>
            {/* <div className="rightContainer">
              {loadAdddata.length && "Added City Name"}
              {loadAdddata.length>0 &&
              <div>
              <input
                type="text"
                onChange={(e) => handleSearch(e,"right")}
                placeholder="Search by Name"
              />
            </div>}
            <div  style={{overflow:"auto",height:"500px"}}>             {loadAdddata2.length > 0 &&
                loadAdddata2.map((item, index) => {
                  return (
                    <>
                      <ul>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleCenter(item)}
                        >
                          <MapCelMain item={item}  searchedtext={seechedText} />
                        </li>
                        <button onClick={() => RemoveArray(index)}>
                          Discard
                        </button>
                      </ul>
                      {/* </div> */}
                    {/* </>
                  );
                })}
                </div>
 
            </div>
            <div className="mapContainer">
              {loading && (
                <Map center={position} zoom={10} places={loadAdddata} />
              )}
            </div> */}
            <div className="leftContainer">
        <table >
          <tr>
            <td ><label>Choose a Distict:</label></td>
            <td colSpan={2}>    <select onChange={(e) => handleChangeDistict(e.target.value)}>
                <option value={"All"}>All</option>
                <option value={"Amritsar"}>Amritsar</option>
                <option value={"Bathinda"}>Bathinda</option>
                <option value={"Barnala"}>Barnala</option>
                <option value={"FaridKot"}>FaridKot</option>
                <option value={"Fatehgarh"}>Fatehgarh Sahib</option>
                <option value={"Fazilka"}>Fazilka Sahib</option>
                <option value={"Ferozpur"}>Ferozpur</option>
                <option value={"Gurdaspur"}>Gurdaspur</option>
                <option value={"Hoshiarpur"}>Hoshiarpur</option>
                <option value={"Jalandhar"}>Jalandhar</option>
                <option value={"Kapurthala"}>Kapurthala</option>
                <option value={"Ludhiana"}>Ludhiana</option>
                <option value={"Mansa"}>Mansa</option>
                <option value={"Moga"}>Moga</option>
                <option value={"Mohali"}>Mohali</option>
                <option value={"Muktsar"}>Muktsar</option>
                <option value={"Pathankot"}>Pathankot</option>
                <option value={"Patiala"}>Patiala</option>
                <option value={"Ropar"}>Ropar</option>
                <option value={"Sangrur"}>Sangrur</option>
                <option value={"SBSNagar"}>SBSNagar</option>
                <option value={"Taran"}>Taran</option>
              </select></td>
          </tr>

          <tr>
            <td ><label>Search by Name</label></td>
            <td><label>  <input
                  type="text"
                  onChange={(e) => handleSearch(e,"left")}
                  placeholder="Search by Name"
                /></label></td>
                <td> Villages  count is <b>  {showData1.length} </b></td>
            </tr>
            <tr>
            <td colSpan={3}><label>If you didn't find your village Please message me on 734720037 , so that I can add the village in the list.</label></td>
            </tr>
        </table>

              
          
              <div>
              
              </div>
              
              <div  style={{overflow:"auto",height:"500px"}}> 


     
              <table id="customers">         
     <tr>
    <th>     Villages with count  {showData1.length}
      </th>
    <th> Tehsil</th>
    <th> District </th>
    {/* <th>  Address </th> */}
  </tr>
  {showData1.length &&
                showData1.map((item, index) => {
                  return (
                    <MapCelMain item={item} searchedtext={searchedtext} />
                    // <>
                    //    <MapCelMain item={item} searchedtext={searchedtext} />
                    //   {/* <ul>
                    //     <li
                    //       style={{
                    //         border: "1px solid",
                    //         padding: " 5px 10px",
                    //         display: "inline-table",
                    //       }}
                    //     >
                    //       <MapCelMain item={item} searchedtext={searchedtext} />
                    //     {/* </li>
                    //     <button onClick={() => loadArray(item)}>Add</button>
                    //   </ul> */} 
                    // </>
                  );
                })}
  
  
  </table>  






           
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MapCart;
