import "./FooterRF.css";
import React, { useState, useEffect } from "react";
import mainLogoWall from "./Assests/wallLogo.png";
import instaRajiFarm from "./Assests/instaLogo.jpeg";
import fbLogo from "./Assests/fbLogo.png";


import { ImageClass } from "./components/ImageClass.js";
import { TextBanner } from "./components/TextBanner.js";
function FooterRF() {
  return (
    <>
      <div className="FooterContainerMain">
      <TextBanner/>
      <a href="/"><ImageClass src={mainLogoWall} className="imageAttibuteFooter" /></a>
    
        <div className="mapDivFooter">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1268.9295623461321!2d76.45195106245376!3d30.928448071231248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1664195751901!5m2!1sen!2sin"
            width="180px"
            height="180px"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
  </div> 
                 <div className="ContactDiv">
                    <h5>Contact us</h5>
                   
                    <div >
                      <div >
                      <a target="_blank"  href="https://www.google.com/maps/@30.9285645,76.4516285,3a,75y,186.62h,75.89t/data=!3m6!1e1!3m4!1shzGqrtpFX4ZI4nqJpEnEMg!2e0!7i13312!8i6656?entry=ttu"> 

                        <p>
                        <span className="fa fa-map-marker"> </span>  <span>Address:</span> Raji farm: Near Gurmat
                          Missionary College, {"  "} <br /> Chaunta Kalan,Ropar, Punjab
                         {"  "} <br />
                          140111,India
                        </p>
                        </a> 
                     
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      
                      <div className="text pl-3">
                        <p> <span className="fa fa-phone"></span> {" "}
                          <span>Phone:</span>
                          <a href="tel://7347200037">
                            7347200037 , 9814164511,9914999433
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="text pl-3">
                   
                      <div className="text pl-3">
                        <p><span className="fa fa-paper-plane"></span> {" "}
                          <span>Email:</span>
                          <a href="mailto:rajifarm@gmail.com">
                            rajifarm@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      
                      <div className="text pl-3">
                        <p>
                          <span>  <span className="fa fa-globe"></span> Website:</span>
                          <a href="/">Rajifarm.com</a>
                        </p>
                      </div>
                 
                    
                    </div>

                    <a target="_blank"  href="https://instagram.com/rajifarm?igshid=MTk0NTkyODZkYg=="><ImageClass src={instaRajiFarm} className="imageAttibuteFooterSocialSite" /></a>  

                    <a target="_blank"  href="https://www.facebook.com/rajiPantlia/"><ImageClass src={fbLogo} className="imageAttibuteFooterSocialSite" /></a> 
                  </div>
      
     

      </div>
    </>
  );
}

export default FooterRF;
