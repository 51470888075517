

import React, { useState } from "react";
import styles from "./FertilizerNutrientCalculator2Container.css";
import FertilizerNutrientCalculator2 from "./FertilizerNutrientCalculator2.js";
function FertilizerNutrientCalculator2Container() {
  // ----------------------------------------
  const [f_nutreientInput, setF_nutreientInput] = useState(20);
  const [f_nutreientPercentageInput, setF_nutreientPercentageInput] =
    useState(8.8);

  return (
    <>
    <div className="mainFNCal2">

         <h3 className="commonFC2_tr"> How to calculate Pure Element and   Oxide form </h3>
        <div>
        <FertilizerNutrientCalculator2 
       themN ={"tableContainerFM2_Nitrogen"}
       PureElement = {"N (Nitrogen "}
       MultiplyByPure = {4.426}
       Oxideform=  {"NO3 (Nitrate) "}
       MultiplyByOxideform = {0.226}
       themeTR ={"tr_N"}  />
        
        {/* ------------------------- */}
       
        <FertilizerNutrientCalculator2 
       themN ={"tableContainerFM2_Nitrogen"}
       PureElement = {"N (Nitrogen)"}
       MultiplyByPure = {1.288}
       Oxideform=  {"NH4 (Ammonium)" }
       MultiplyByOxideform = {0.776}
       themeTR ={"tr_N"}  />


        {/* ------------------------- */}
       
        <FertilizerNutrientCalculator2 
       themN ={"tableContainerFM2_P"}
       PureElement = {"P (Phosphorus)"}
       MultiplyByPure = {2.292}
       Oxideform=  {"P2O5 (Phosphorus pentoxide )"}
       MultiplyByOxideform = {0.436}
       themeTR ={"tr_P"}  />


      {/* ------------------------- */}
       
      <FertilizerNutrientCalculator2 
       themN ={"tableContainerFM2_Nitrogen"}
       PureElement = {"K (Potasium)"}
       MultiplyByPure = {1.205  }
       Oxideform=  {"K2O"}
       MultiplyByOxideform = {0.83}
       themeTR ={"tr_N"}  />




      {/* ------------------------- */}
       
      <FertilizerNutrientCalculator2 
       themN ={"tableContainerFM2_Nitrogen"}
       PureElement = {"Ca (Calcium)"}
       MultiplyByPure = {1.399  }
       Oxideform=  {"CaO"}
       MultiplyByOxideform = {0.715}
       themeTR ={"tr_N"}  />



      {/* ------------------------- */}
       
      <FertilizerNutrientCalculator2 
       themN ={"tableContainerFM2_Nitrogen"}
       PureElement = {"Mg (Magnesium)"}
       MultiplyByPure = {1.658  }
       Oxideform=  {"MgO"}
       MultiplyByOxideform = {0.603}
       themeTR ={"tr_N"}  />

 {/* ------------------------- */}
       
 <FertilizerNutrientCalculator2 
       themN ={"tableContainerFM2_Nitrogen"}
       PureElement = {"S (Sulfur)"}
       MultiplyByPure = {2.996  }
       Oxideform=  {"SO4"}
       MultiplyByOxideform = {0.334}
       themeTR ={"tr_N"}  />

  
 {/* ------------------------- */}
       
 <FertilizerNutrientCalculator2 
       themN ={"tableContainerFM2_Nitrogen"}
       PureElement = {"S (Sulfur)"}
       MultiplyByPure = {2.497  }
       Oxideform=  {"SO3"}
       MultiplyByOxideform = {0.4}
       themeTR ={"tr_N"}  />
       {/* ------------------------- */}

        </div>
      
      </div>
      
 
    </>
  );
}

export default FertilizerNutrientCalculator2Container;
