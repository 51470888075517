
import React, { useState } from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PlantCart from "./PlantCart.js"

import "./BillViewContoller.css";
const BillViewContoller = ({index , bill}) => {
 
  return (
    <div>
      <h2>{index}{bill.srno}</h2>
      {JSON.stringify(bill)}
         <PlantCart bill={bill}/>

      <table>
          <tr>
            <td> Date </td>
            <td> Mode </td>
            <td> Given Amount</td>
            <td> Balance </td>
          </tr>

          <tr>
            <td> 30 Agust </td>
            <td> CASh </td>
            <td> 5000</td>
            <td> 80000 </td>
          </tr>

          <tr>
            <td> 30 Agust </td>
            <td> CASh </td>
            <td> 2000</td>
            <td> 60000 </td>
          </tr>

          <tr>
            <td> 30 Agust </td>
            <td> PTM </td>
            <td> 60000</td>
            <td> 00 </td>
          </tr>
        </table>
   


    </div>
  )
};

  // export default Admin;
export default BillViewContoller;

