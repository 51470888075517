import "./PlantCalulator.css";
import React, { useState, useEffect } from "react";

function PlantCalulator() {
  const [rr_treesCount, setRr_treesCount] = useState(10);

  var actionChange_rr_treesCount = (e) => {
    setRr_treesCount(e.target.value);
  };

  const [cc_treesCount, setCC_treesCount] = useState(20);

  var actionChange_cc_treesCount = (e) => {
    setCC_treesCount(e.target.value);
  };
  //cc_treesCount
  //actionChange_cc_treesCount

  const [br_treesCount, setBr_treesCount] = useState(10);

  var actionChange_BR_TreesCount = (e) => {
    setBr_treesCount(e.target.value);
  };

  const [bc_treesCount, setBc_treesCount] = useState(10);

  var actionChange_Bc_TreesCount = (e) => {
    setBc_treesCount(e.target.value);
  };

  var intValue_Col_col = () => {
    return ~~(200 / cc_treesCount);
  };
  var intValue_row_row = () => {
    return ~~(200 / rr_treesCount);
  };
  //intValue_Col_col
  //intValue_row_row

  var plantRenderInnerPlot = () => {
    var columns = intValue_Col_col();
    var rows = intValue_row_row();
    return (
      <>
        {[...Array(rows)].map((x, i) => (
          <tr className="tr_pc">
            {[...Array(columns)].map(() => (
              <td className="plant td_Pc"></td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  var intValBRT = () => {
    return ~~(178 / br_treesCount);
  };

  var plantRenderBRT = () => {
    var columns = ~~1;
    var rows = intValBRT();
    return (
      <>
        {[...Array(columns)].map((x, i) => (
          <tr className="tr_pc">
            {[...Array(rows)].map(() => (
              <td className="plant_RowBoundary1 td_Pc td_RC "></td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  var totalBoundryCountrees = () => {
    return intValBCL() + intValBRT();
  };

  var intValBCL = () => {
    return ~~(220 / bc_treesCount);
  };

  var plantRenderBCL = () => {
    var columns = ~~1;
    var rows = intValBCL();
    return (
      <>
        {[...Array(columns)].map((x, i) => (
          <tr className="tr_pc">
            {[...Array(rows)].map(() => (
              <td className="plant_ColumnBoundary1 td_Pc td_BC"></td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="superMainPC">
      <div className="  divHeadingContainer ">
        <h1>About Plant Calulator</h1>
        At the time of transplating, Famers grow the plants in different style
        based on his requirements. In Plant Calculator, farmer do have the
        option to estimate the trees, on the boundaries and inner plot. Here in
        below input fields farmer add the spacing Between the plants in feet.
        The calucation will will be show in the results.
      </div>

      <div className="graph">
        <div className="divMain contaninerInputPlantsDimensions">
          <div className="divBoundaryRow">
            <table>
              <tbody>
                <tr>
                  <th colSpan={2} className="aligmentText">
                    {" "}
                    Boundary Row TOP-BOTTOM (BRT-BRB){" "}
                  </th>
                </tr>

                <tr>
                  <th className="tdIput">
                    {" "}
                    space Between Tree - Tree (Horizotal){" "}
                  </th>
                  <td className="tdIput">
                    <input
                      className="inputTextField tdIputMain"
                      type="number"
                      value={br_treesCount}
                      name="name_2000"
                      min="3"
                      max="50"
                      onChange={actionChange_BR_TreesCount}
                    />{" "}
                    feet
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="divBoundarycolumn">
            <table>
              <tbody>
                <tr>
                  <th colSpan={2} className="aligmentText">
                    {" "}
                    Boundary Column LEFT - RIGHT (BCL -BCR){" "}
                  </th>
                </tr>

                <tr>
                  <th className="tdIput">
                    {" "}
                    space Between Tree - Tree (Vertical){" "}
                  </th>

                  <td className="tdIput">
                    <input
                      className="inputTextField tdIputMain"
                      type="number"
                      value={bc_treesCount}
                      name="name_2000"
                      min="3"
                      max="50"
                      onChange={actionChange_Bc_TreesCount}
                    />{" "}
                    feet
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="divRow_Row">
            <table>
              <tbody>
                <tr>
                  <th colSpan={2} className="aligmentText">
                    {" "}
                    INNER PLOT (ROW-ROW) (COLUMN-COLUMN)
                  </th>
                </tr>

                <tr>
                  <th className="tdIput">
                    {" "}
                    space Between Tree - Tree (ROW-ROW)(Vertical){" "}
                  </th>

                  <td className="tdIput">
                    <input
                      className="inputTextField tdIputMain"
                      type="number"
                      value={rr_treesCount}
                      name="name_2000"
                      min="5"
                      max="50"
                      onChange={actionChange_rr_treesCount}
                    />{" "}
                    feet
                  </td>
                </tr>

                <tr className="trIput">
                  <th className="tdIput">
                    {" "}
                    space Between Tree - Tree (Column-Column)(Horizotal){" "}
                  </th>

                  <td className="tdIput">
                    <input
                      className="inputTextField tdIputMain"
                      type="number"
                      value={cc_treesCount}
                      name="name_2000"
                      min="5"
                      max="50"
                      onChange={actionChange_cc_treesCount}
                    />{" "}
                    feet
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="divMain">
          <div className="plantInner ">
            <div className="br1 fontInfo">Boundary Row TOP (BRT)</div>
            <div className="innerPlotMain fontInfo">
              INNER PLOT (ROW-ROW) (COLUMN-COLUMN)
            </div>
            <div className="br2"> Boundary Row BOTTOM (BRB) </div>

            <div className="bc1  fontInfo" style={{ width: "10px" }}>
              {" "}
              Boundary COLUMN LEFT (BCL){" "}
            </div>
            <div className="bc2 fontInfo" style={{ width: "10px" }}>
              {" "}
              Boundary COLUMN RIGHT (BCL){" "}
            </div>
          </div>
        </div>
      </div>

      <div className="graph">
        <div className="divMain divMainResult">
          <h2> Result </h2>
          <table>
            <tr className="td_Result_br">
              <td>Boundary-Row TOP (BRT)</td>
              <td>{intValBRT()} Trees</td>
              <th rowSpan={3}>{intValBRT() * 2} Trees </th>
            </tr>
            <tr className="td_Result_br">
              <td className="td_Result_br">Boundary-Row Bottom (BRB)</td>
              <td className="td_Result_br">{intValBRT()} Trees</td>
            </tr>

            <tr className="td_Result_br">
              <th colSpan={2}>Total Boundary-Rows Trees</th>
            </tr>
            {/* ------------------- */}

            <tr className="td_Result_bc">
              <td className="td_Result_bc">Boundary Column LEFT (BCL)</td>
              <td className="td_Result_bc">{intValBCL()} Trees</td>
              <th rowSpan={3} className="td_Result_bc">
                {intValBCL() * 2} Trees{" "}
              </th>
            </tr>
            <tr className="td_Result_bc">
              <td className="td_Result_bc">Boundary Column RIGHT (BCR)</td>
              <td className="td_Result_bc">{intValBCL()} Trees</td>
            </tr>

            <tr className="td_Result_bc">
              <th colSpan={2} className="td_Result_bc">
                Total Boundary-column Trees
              </th>
            </tr>


            <tr>
              <th colSpan={2}>Total Trees in Boundaries  </th>

              <th>
                {totalBoundryCountrees() * 2}{" "}
                Trees
              </th>
            </tr>

            {/* ------------------- */}

            <tr className="td_Result_InnerPlot">
              <td className="td_Result_InnerPlot">Rows in Inner Plot</td>
              <td className="td_Result_InnerPlot">{intValue_row_row()}</td>
              <th className="td_Result_InnerPlot" rowSpan={3}>
                {intValue_Col_col() * intValue_row_row()} Trees{" "}
              </th>
            </tr>
            <tr>
              <td className="td_Result_InnerPlot">Columns in Inner Plot </td>
              <td className="td_Result_InnerPlot">{intValue_Col_col()} </td>
            </tr>

            <tr className="td_Result_InnerPlot">
              <th colSpan={2} className="td_Result_InnerPlot">
                Tolal Trees Inner Plot (Rows * Column)
              </th>
            </tr>

            <tr>
              <th colSpan={2}>Total Trees in Acre </th>

              <th>
                {intValue_Col_col() * intValue_row_row() +
                  totalBoundryCountrees() * 2}{" "}
                Trees
              </th>
            </tr>
          </table>
        </div>

        <div className="divMain">
          <div className="plantInner">
            <div className="br1">
              <table className="tableInner">{plantRenderBRT()}</table>
            </div>
            <div className="innerPlotMain">
              <table className="tableInner">{plantRenderInnerPlot()}</table>
            </div>
            <div className="br2">
              {" "}
              <table className="tableInner">{plantRenderBRT()}</table>{" "}
            </div>
            {/* 
          console.log(`--color-logo: ${color}`); */}

            <div className="bc1  " style={{ width: "10px" }}>
              {" "}
              <table className="tableInner">{plantRenderBCL()}</table>{" "}
            </div>
            <div className="bc2" style={{ width: "10px" }}>
              {" "}
              <table className="tableInner">{plantRenderBCL()}</table>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PlantCalulator;
