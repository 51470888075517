import React from 'react';
import "./SettingsView.css";
const SettingsView = () => {
  return (
    <div>
      <h2>Settings View</h2>
      {/* Add settings content here */}
    </div>
  )
};

  // export default Admin;
export default SettingsView;

