import React from "react";
import "./Fard.css";

import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./CurrentLocationRajiFarm.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CurrentLocationRajiFarm(props) {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  return (
    <>
      <div className="CurrentLocationBackgrondPlus">
        <h2> This app is try to fetch the current location of yours. </h2>
        <div>
          {latitude && longitude ? (
            <p>
              Latitude: {latitude},<br /> Longitude: {longitude}
              <br />
              <div>
                <a
                  href={
                    "https://www.google.com/maps/place/" +
                    latitude +
                    "," +
                    longitude
                  }
                  target="_blank"
                >
                  Go to Google App
                </a>
              </div>
            </p>
          ) : (
            <p>Fetching location...</p>
          )}
        </div>
      </div>
    </>
  );
}

export default CurrentLocationRajiFarm;
