import "./PotatoDeficiency.css";
import React, { useState, useEffect } from "react";

function PotatoDeficiency() {
  return (
    <>
      <div className="potatoDeficiencyContainer">
      
        <div>
        <h2> Nutrient Deficiencies and Excesses in Potato</h2>
          <table>
            <tr>
              <th>ELEMENTS</th>
              <th>DEFICIENCIES</th>
              <th>EXCESSES</th>
            </tr>
            <tr>
              <td>Nitrogen</td>
              <td>
                <ul>
                  <li>Reduced total yield </li>
                  <li>Reduced tuber sizing </li>
                  <li>Pale stunted foliage </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Delayed maturity </li>
                  <li>Excessive top growth </li>
                  <li>Hollow heart & growth cracks </li>
                  <li>Increased susceptibility to disease</li>
                  <li>Difficulty in top killing </li>
                  <li>Reduced tuber specific gravity </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Phosphorus</td>
              <td>
                <ul>
                  <li> Reduced early vigor </li>
                  <li> Delayed maturity </li>
                  <li>Reduced yield </li>
                </ul>
              </td>

              <td>
                <ul>
                  <li>Ties up other elements such as calcium and zinc </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Potassium <br />
                (Potash)
              </td>
              <td>
                <ul>
                  <li> Reduced nitrogen uptake </li>
                  <li>Reduced yield </li>
                  <li> Increased storage black spot</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Reduced tuber specific gravity </li>
                  <li>Reduced calcium and/or magnesium uptake </li>
                  <li> Poor soil structure </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Calcium </td>
              <td>
                <ul>
                  <li>Reduced root growth</li>
                  <li>Deformed foliage growth tips </li>
                  <li>Reduced yield </li>
                  <li>Reduced tuber quality </li>
                  <li>Poor soil structure </li>
                </ul>
              </td>
              <td>Reduced magnesium uptake</td>
            </tr>
            <tr>
              <td>Magnesium</td>
              <td>
                <ul>
                  <li> Reduced photosynthesis rate </li>
                  <li> Reduced yield</li>
                  <li>Reduced tuber formation </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li> Reduced calcium uptake</li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}
export default PotatoDeficiency;
