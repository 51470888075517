import React from "react";

import styles from "./CalculatorInfo.module.css";

export default function CalculatorInfo() {
  return (
    <>

      <div className="calculatorConatinerInfo">
          <h2>Area-Lenght dimension Info</h2>
          <p> Below are the some area and length units that are used in India Region like Punjab,Himachal etc.</p>
          <br />
     
        <div className="accordion accordion-flush" id="accordionFlushExample">
                 {/*_______________________  new Section ___________________________________*/}
          <div className="accordion-item">
            <h2 className="accordion-header " id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Killa: is unit of Area
              </button>
          
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <img src="/assests/infoKilla.png" alt="nice" />
              </div>
            </div>
          </div>
           {/*_______________________  new Section ___________________________________*/}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
               Hectare: is unit of Area
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <img src="/assests/infoHectare.png" alt="nice" />
              </div>
            </div>
          </div>
             {/*_______________________  new Section ___________________________________*/}
             <div className="accordion-item">
            <h2 className="accordion-header" id="flush-heading3">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse3"
                aria-expanded="false"
                aria-controls="flush-collapse3"
              >
               Bigha: is unit of Area
              </button>
            </h2>
            <div
              id="flush-collapse3"
              className="accordion-collapse collapse"
              aria-labelledby="flush-heading3"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <img src="/assests/infoBigha.png" alt="nice" />
              </div>
            </div>
          </div>
{   /*_______________________  new Section ___________________________________*/}

<div className="accordion-item">
            <h2 className="accordion-header" id="flush-heading4">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse4"
                aria-expanded="false"
                aria-controls="flush-collapse4"
              >
               Kannal: is unit of Area
              </button>
            </h2>
            <div
              id="flush-collapse4"
              className="accordion-collapse collapse"
              aria-labelledby="flush-heading4"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <img src="/assests/infoKannal.png" alt="nice" />
              </div>
            </div>
          </div>
{   /*_______________________  new Section ___________________________________*/}
    
<div className="accordion-item">
            <h2 className="accordion-header" id="flush-heading5">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse5"
                aria-expanded="false"
                aria-controls="flush-collapse5"
              >
               Biswa: is unit of Area
              </button>
            </h2>
            <div
              id="flush-collapse5"
              className="accordion-collapse collapse"
              aria-labelledby="flush-heading5"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <img src="/assests/infoBiswa.png" alt="nice" />
              </div>
            </div>
          </div>
{   /*_______________________  new Section ___________________________________*/}
<div className="accordion-item">
            <h2 className="accordion-header" id="flush-heading6">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse6"
                aria-expanded="false"
                aria-controls="flush-collapse6"
              >
               Marla: is unit of Area
              </button>
            </h2>
            <div
              id="flush-collapse6"
              className="accordion-collapse collapse"
              aria-labelledby="flush-heading6"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <img src="/assests/infoMarla.png" alt="nice" />
              </div>
            </div>
          </div>
{   /*_______________________  new Section ___________________________________*/}
<div className="accordion-item">
            <h2 className="accordion-header" id="flush-heading7">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse7"
                aria-expanded="false"
                aria-controls="flush-collapse7"
              >
               Sarsahi: is unit of Area
              </button>
            </h2>
            <div
              id="flush-collapse7"
              className="accordion-collapse collapse"
              aria-labelledby="flush-heading7"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <img src="/assests/infoSarsahi.png" alt="nice" />
              </div>
            </div>
          </div>
{   /*_______________________  new Section ___________________________________*/}
<div className="accordion-item">
            <h2 className="accordion-header" id="flush-heading8">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse8"
                aria-expanded="false"
                aria-controls="flush-collapse8"
              >
               Lenght info
              </button>
            </h2>
            <div
              id="flush-collapse8"
              className="accordion-collapse collapse"
              aria-labelledby="flush-heading8"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <img src="/assests/infoLenght.png" alt="nice" />
              </div>
            </div>
          </div>
{   /*_______________________  new Section ___________________________________*/}
        </div>
      </div>
    </>
  );
}
