import React from "react";
import "./Fard.css";

import { useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Fard.css";
function FardOwnerParterships(props) {
  const [name, setName] = React.useState("");
  const [ownerships, setOwnerships] = React.useState(0);

  const actionchangeName = (e) => {
    // do cool stuff here!
    let someKilla = e.target.value;
    console.log("killa value ", someKilla);
    setName(someKilla);
  };

  const actionChangeOwnerships = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setOwnerships(someKilla);
  };

  const handleClose = (e) => {
    // let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    // setLiquidVolume(someKilla);
    console.log("Thisis to call");
    props.actionOnwerParter();
  };

  const actionOK = (e) => {
    // let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    // setLiquidVolume(someKilla);
    console.log("Thisis to call");
    props.callbackOwnerParternship(name, ownerships);
    setOwnerships(0);
    setName("");
  };

  const actionCancel = (e) => {
    // let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    // setLiquidVolume(someKilla);
    console.log("Thisis to call");
    props.actionOnwerParter();
    setOwnerships(0);
    setName("");
  };

  return (
    <>
      <Modal show={props.open} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Owner name and Oweships </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label> Enter Name of Owner</label>

          <input
            type="text"
            className="form-control"
            id="colFormLabel"
            placeholder="Enter name"
            onChange={actionchangeName}
            // ref={biswaIThis}
          ></input>
          <br />
          <label> Enter Ownership in number </label>
          <input
            type="number"
            className="form-control"
            id="colFormLabel"
            placeholder="Enter OwnerShips"
            onChange={actionChangeOwnerships}
            // ref={biswaIThis}
          ></input>
          <hr />
          <div>
            <button onClick={actionCancel}> Cancel </button>
            <button onClick={actionOK}> OK </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
function FardMarlaKanaal(props) {
  const [kannalV, setKannalV] = React.useState(0);
  const [marlaV, setMarlaV] = React.useState(0);
  const handleClose = (e) => {
    // let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    // setLiquidVolume(someKilla);
    console.log("Thisis to call");
    props.callbackClosenMarlaKannal();
  };

  const actionOK = (e) => {
    // let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    // setLiquidVolume(someKilla);
    console.log("Thisis to call");
    props.callbackmarlaKannal(kannalV, marlaV);
    setKannalV(0);
    setMarlaV(0);
  };
  const actionChangeKannal = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setKannalV(someKilla);
  };
  const actionChangeMarla = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setMarlaV(someKilla);
  };

  return (
    <>
      <Modal show={props.openMarlaKannal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add kannal name and marla </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label> Enter Kannal</label>

          <input
            type="number"
            className="form-control"
            id="colFormLabel"
            placeholder="Enter kannal "
            onChange={actionChangeKannal}
            // onChange={actionChangeKannal}
            // ref={biswaIThis}
          ></input>
          <br />
          <label> Enter Marla</label>
          <input
            type="number"
            className="form-control"
            id="colFormLabel"
            placeholder="Enter marla"
            onChange={actionChangeMarla}
            // ref={biswaIThis}
          ></input>
          <hr />
          <div>
            <button> Cancel </button>
            <button onClick={actionOK}> OK </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function Fard() {
  const [open, setOpen] = React.useState(false);
  const [openMarlaKannal, setOpenMarlaKannal] = React.useState(false);
  const [dictOwners, setdictOwners] = React.useState([]);
  const [dictKanaalMarla, setDictKanaalMarla] = React.useState([]);
  const handleOpen = (e) => {
    // let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    // setLiquidVolume(someKilla);
    console.log("Thisis to call,handleOpen", open);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleSelectMenu = (e) => {
    // let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    // setLiquidVolume(someKilla);
    console.log("Thisis to call");
  };
  const actionOnwerParter = () => {
    console.log("this is ad👁d to method cLLED ");
    handleClose();
  };
  const actionMarlaKannalOpen = () => {
    console.log("this is ad👁d to method cLLED ");

    setOpenMarlaKannal(true);
  };
  const actionMarlaKannalClose = () => {
    console.log("this is ad👁d to method cLLED ");

    setOpenMarlaKannal(false);
  };

  const callbackOwnerParternship = (name, ownership) => {
    console.log(" &&&&&%%🧑🏼‍🚒%%%% ", name, ownership);

    setOpen(false);

    setdictOwners((oldArray) => [
      ...oldArray,
      { name: name, ownership: ownership },
    ]);
  };

  function getGrandMarla() {
    return totalKannal * 20 + totalMarla;
  }
  function parterneship(totalMarlas , Ownerships){
    return totalMarlas *Ownerships
  }

  const totalKannal = dictKanaalMarla.reduce(
    (prevValue, currentValue) => prevValue + currentValue.kannal,
    0
  );
  const totalMarla = dictKanaalMarla.reduce(
    (prevValue, currentValue) => prevValue + currentValue.marla,
    0
  );

  const callbackmarlaKannal = (kannal, marla) => {
    console.log(" &&&&&%%%%%% ", kannal, marla);
    setOpenMarlaKannal(false);
    setDictKanaalMarla((oldArray) => [
      ...oldArray,
      { kannal: kannal, marla: marla },
    ]);
  };
  const callbackClosenMarlaKannal = () => {
    setOpenMarlaKannal(false);
  };

  return (
    <>
      {/* <p>
        {" "}
        the count is {dictOwners.length} , {dictKanaalMarla.length},{" "}
        {totalMarla} ,{" "}
      </p> */}

      <FardOwnerParterships
        open={open}
        actionOnwerParter={actionOnwerParter}
        callbackOwnerParternship={callbackOwnerParternship}
      />

      <FardMarlaKanaal
        openMarlaKannal={openMarlaKannal}
        callbackmarlaKannal={callbackmarlaKannal}
        callbackClosenMarlaKannal={callbackClosenMarlaKannal}
        callbackOwnerParternship={callbackOwnerParternship}
      />
      <div className="FardBackgrondPlus">
        <div>
          <p>
            {" "}
            <span className="spanHeading">Khasra Number</span> in rural area,
            <b>plots of land are assigned Khasra numbers</b>.This is similar to
            plot or survey numbers in urban areas.it is an essential number for
            providing owership rights to a landholder.Khasra numbers don't
            change if the plot is sold{" "}
          </p>

          <p>
            {" "}
            <span className="spanHeading"> Khata Number [ Khewat number] </span>
            <b>This number is provided to co-owners of a plot </b>
            in rural pockets. The Khata number provides ownerships details along
            with the total land being owned by the joint landholder. The
            Ownerships of this number changes if the plot is sold.{" "}
          </p>

          <p>
            {" "}
            <span className="spanHeading">Khatauni Number</span> Khatauni Number
            is
            <b>
              {" "}
              provided to a group of cultivator who till or cultivate the land
              across plots having different Khasra numbers.{" "}
            </b>
            To know the details about cultivators and their area of cultivation,
            you can reffer to the Khatauni Number
          </p>
        </div>
        <hr />
        <div className="calculatorFard">
          <h2> Calculate the Ownership of land from Fard</h2>
          <table id="customers">
            <tr>
              <th>
                <Button onClick={handleOpen}>Click here to add Owner name and ownerShips</Button>
              </th>
              <th>
                <Button onClick={actionMarlaKannalOpen}>
                Click here to  Add the land in Marla and kanaal
                </Button>
              </th>
              <th> Total Parternship of Owner in land</th>
            </tr>
            <tr>
              <td>
                {dictOwners.map((user) => (
                  <div>
                    {" "}
                    <b>={user["name"]}</b> have <b>{user["ownership"]} </b>{" "}
                    partnership
                  </div>
                ))}
              </td>
              <td>
                {dictKanaalMarla.map((user) => (
                  <div>
                    {" "}
                    <b>={user["kannal"]}</b> kanaal{" "}
                    <b>{user["marla"]} marla </b> partnership
                  </div>
                ))}
              </td>
              <td>
                {dictOwners.map((user) => (
                  <div>
                    {" "}
                    <b>={user["name"]}</b> have <b>{
                    
                    parterneship(user["ownership"],getGrandMarla() )
                    } </b>{" "}
                    partnership in marla
                  </div>
                ))}
              </td>
            </tr>

            <tr>
              <td>Total Partners are {dictOwners.length}</td>
              <td> Total Area is <br/>
                ={totalKannal} Kanaal + {totalMarla} Marla
                <br />= {getGrandMarla()} Marlas
              </td>
                <td> </td>
            </tr>
          </table>
        </div>

        <div><h2>General Terms</h2>
<table>
    <thead>
        <tr>
            <th>ID</th>
            <th>Punjabi Term</th>
            <th>Meaning (Gurmukhi)</th>
            <th>Meaning (English)</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>ਆੜ ਰਹਿਨ</td>
            <td>ਗਹਿਣੇ ਹੋਣਾ</td>
            <td>To have debt</td>
        </tr>
        <tr>
            <td>2</td>
            <td>ਫੱਕ ਆੜ ਰਹਿਨ</td>
            <td>ਗਹਿਣੇ ਨੂੰ ਛੱਡਵਾ ਲੈਣਾ</td>
            <td>To be free from debt</td>
        </tr>
        <tr>
            <td>3</td>
            <td>ਚਾਹੀ</td>
            <td>ਜਿਸ ਨੂੰ ਕੇਵਲ ਖੂਹ ਦਾ ਪਾਣੀ ਲੱਗੇ</td>
            <td>Needs only well water</td>
        </tr>
        <tr>
            <td>4</td>
            <td>ਬਰਾਨੀ</td>
            <td>ਜਿਸ ਨੂੰ ਪਾਣੀ ਨਾ ਲੱਗਦਾ ਹੋਵੇ</td>
            <td>Does not need water</td>
        </tr>
        <tr>
            <td>5</td>
            <td>ਚੱਕ ਜਾਂ ਮਿਨ</td>
            <td>ਜਮੀਨ ਦਾ ਟੁਕੜਾ</td>
            <td>Piece of land</td>
        </tr>
    </tbody>
</table>

<h2>Agricultural Terms</h2>
<table>
    <thead>
        <tr>
            <th>ID</th>
            <th>Punjabi Term</th>
            <th>Meaning (Gurmukhi)</th>
            <th>Meaning (English)</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>6</td>
            <td>ਹਿੱਬਾ</td>
            <td>ਦਾਨ</td>
            <td>Donation</td>
        </tr>
        <tr>
            <td>7</td>
            <td>ਮਜਰੂਆ</td>
            <td>ਕਾਸ਼ਤ ਵਾਲੀ ਜਮੀਨ</td>
            <td>Cultivated land</td>
        </tr>
        <tr>
            <td>8</td>
            <td>ਗੈਰ ਮਜਰੂਆ</td>
            <td>ਬਿਨਾ ਕਾਸ਼ਤ ਵਾਲੀ ਜਮੀਨ</td>
            <td>Non-cultivated land</td>
        </tr>
        <tr>
            <td>9</td>
            <td>ਦੇਹ</td>
            <td>ਪਿੰਡ</td>
            <td>Village</td>
        </tr>
        <tr>
            <td>10</td>
            <td>ਹੱਦਬੰਦੀ</td>
            <td>ਦੋ ਪਿੰਡਾ ਦੀ ਸਾਝੀ ਵੱਟ</td>
            <td>Boundary of two villages</td>
        </tr>
    </tbody>
</table>

<h2>Legal and Administrative Terms</h2>
<table>
    <thead>
        <tr>
            <th>ID</th>
            <th>Punjabi Term</th>
            <th>Meaning (Gurmukhi)</th>
            <th>Meaning (English)</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>11</td>
            <td>ਤਰਮੀਮ</td>
            <td>ਸੋਧ ਕਰਨਾ</td>
            <td>To amend</td>
        </tr>
        <tr>
            <td>12</td>
            <td>ਤੈਦਾਦੀ</td>
            <td>ਗਿਣਤੀ ਜਾਂ ਗਿਣਤੀ ਕਰਨਾ</td>
            <td>To count or enumeration</td>
        </tr>
        <tr>
            <td>13</td>
            <td>ਤਲ਼ਫ ਕਰਨਾ</td>
            <td>ਨਸ਼ਟ ਕਰਨਾ</td>
            <td>To waste</td>
        </tr>
        <tr>
            <td>14</td>
            <td>ਨੰਬਰ ਸ਼ੁਮਾਰ</td>
            <td>ਲੜੀ ਨੰਬਰ</td>
            <td>Serial number</td>
        </tr>
        <tr>
            <td>15</td>
            <td>ਤਸੱਵਰ</td>
            <td>ਮੰਨ ਲੈਣਾ</td>
            <td>To assume</td>
        </tr>
        <tr>
            <td>16</td>
            <td>ਮੁਸ਼ਤਰੀ</td>
            <td>ਖਰੀਦਦਾਰ</td>
            <td>Buyer</td>
        </tr>
        <tr>
            <td>17</td>
            <td>ਮੁਰਤਹਿਨ</td>
            <td>ਗਹਿਣੇ ਲੈਣ ਵਾਲਾ</td>
            <td>One who takes the loan</td>
        </tr>
        <tr>
            <td>18</td>
            <td>ਕੈਫ਼ੀਅਤ</td>
            <td>ਵਿਸੇਸ਼ ਕਥਨ</td>
            <td>Special statement</td>
        </tr>
        <tr>
            <td>19</td>
            <td>ਉਜਰਤ</td>
            <td>ਫੀਸ</td>
            <td>Fee</td>
        </tr>
        <tr>
            <td>20</td>
            <td>ਅਹਿਲਦਾਰ</td>
            <td>ਕਰਮਚਾਰੀ</td>
            <td>Employee</td>
        </tr>
        <tr>
            <td>21</td>
            <td>ਗਰਿੰਦਾ</td>
            <td>ਲੈਣ ਵਾਲਾ</td>
            <td>Receiver</td>
        </tr>
        <tr>
            <td>22</td>
            <td>ਖਾਵੰਦ-ਜੋਜ਼ਾ</td>
            <td>ਪਤੀ-ਪਤਨੀ</td>
            <td>Husband-Wife</td>
        </tr>
        <tr>
            <td>23</td>
            <td>ਨਜ਼ਰਸਾਨੀ</td>
            <td>ਪੁਨਰ-ਨਿਰੀਖਣ ਕਰਨਾ</td>
            <td>To re-inspect</td>
        </tr>
        <tr>
            <td>24</td>
            <td>ਫਰੀਕ</td>
            <td>ਧਿਰ</td>
            <td>Party</td>
        </tr>
        <tr>
            <td>25</td>
            <td>ਫਰੀਕ ਅੱਵਲ</td>
            <td>ਪਹਿਲੀ ਧਿਰ</td>
            <td>First party</td>
        </tr>
        <tr>
            <td>26</td>
            <td>ਫਰੀਕ ਦੋਇਮ</td>
            <td>ਦੂਜੀ ਧਿਰ</td>
            <td>Second party</td>
        </tr>
        <tr>
            <td>27</td>
            <td>ਫਰੀਕ ਸਾਨੀ</td>
            <td>ਵਿਰੋਧੀ ਧਿਰ</td>
            <td>Opposing party</td>
        </tr>
        <tr>
            <td>28</td>
            <td>ਫਰਦ ਬਦਰ</td>
            <td>ਰਿਕਾਰਡ ਦੀ ਸੋਧ</td>
            <td>Record amendment</td>
        </tr>
        <tr>
            <td>29</td>
            <td>ਮੁਸ਼ਤਰਕਾ</td>
            <td>ਸਾਂਝਾ</td>
            <td>Joint</td>
        </tr>
        <tr>
            <td>30</td>
            <td>ਮੌਰੁਸੀ</td>
            <td>ਜੱਦੀ</td>
            <td>Inherited</td>
        </tr>
        <tr>
            <td>31</td>
            <td>ਲਫ਼</td>
            <td>ਨੱਥੀ ਕੀਤਾ ਹੋਇਆ</td>
            <td>Wrapped</td>
        </tr>
        <tr>
            <td>32</td>
            <td>ਲਫ਼ ਦਾ ਅਹਰ</td>
            <td>ਨੱਥੀ ਦਾ ਢੰਗ</td>
            <td>Way of wrapping</td>
        </tr>
        <tr>
            <td>33</td>
            <td>ਲਫ਼ ਦਾ ਸੂਤਰ</td>
            <td>ਨੱਥੀ ਦਾ ਢੰਗ ਦੇ ਆਧਾਰ</td>
            <td>Based on the way of wrapping</td>
        </tr>
        <tr>
            <td>34</td>
            <td>ਲਫ਼ ਦੇ ਟੁਕੜੇ</td>
            <td>ਨੱਥੀ ਦੇ ਛੋਟੇ ਟੁਕੜੇ</td>
            <td>Small pieces of wrapping</td>
        </tr>
    </tbody>
</table></div>
      </div>
    </>
  );
}

export default Fard;
