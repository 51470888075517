import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebaseDB from "./Firebase.js";
import styles from "./Contact.scss";
import { collection, addDoc } from "firebase/firestore";
function Contact() {
  const [state, setState] = useState({
    Name: "",
    Email: "",
    Subject: "",
    Message: "",
  });

  const { Name, Email, Subject, Message } = state;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Name || !Email || !Subject || !Message) {
      toast.error("Please provide value in each input field");
    } else {
      //firebaseDB.child("contacts").push(state);

      const loadPost = async () => {
        // Till the data is fetch using API
        // the Loading page will show.
        

       
      //   try {
      //     const docRef = await addDoc(
      //       collection(firebaseDB, "contacts"),
      //       state
      //     );
      //     console.log("Document written with ID: ", docRef.id);
      //     setState({ Name: "", Email: "", Subject: "", Message: "" });
      //     toast.success("Form Submitted Successfully");
      //   } catch (e) {
      //     console.error("Error adding document: ", e);
      //   }
      // };


      let formData = new FormData();


      for (let key in state) {
          formData.append(key, state[key]);
      }

      fetch(
        "https://script.google.com/macros/s/AKfycbyTnQ7eOqEayiMU5sYebo5vJDqU6Km_6V6taZbK-uY24jDsrFxeYRM0Z7DK7wSKA62jew/exec",
        {
        method: "POST",
       
        body: formData,
        }
       
      ).then(response => {
        // Response ko JSON mein parse karein
        return response.json();
      })
      .then(data => {
        // Response data ke saath kuch kriyaein karein
        console.log('Response received:', data);
        toast.success(data.msg);
      })
      .catch(error => {
        // Koi error aane par use handle karein
        console.error('Error:', error);
        toast.success(error);
      });
        // .then((res) => res.json())
   




  }


  loadPost()


      // firebaseDB.collection('contacts').add(state)
    }
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  return (
    <section className="contact-section">
      <div className="container">
        <ToastContainer position="top-center" />
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="wrapper">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className="contact-wrap w-100 p-lg-5 p-4">
                    <h3 className="mb-4">Send us a message or testimony</h3>
                    <form
                      id="contactForm"
                      className="contactForm"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control form-control1 "
                              name="Name"
                              placeholder="Name"
                              onChange={handleInputChange}
                              value={Name}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control form-control1  "
                              name="Email"
                              placeholder="Email"
                              onChange={handleInputChange}
                              value={Email}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control form-control1  "
                              name="Subject"
                              placeholder="Subject"
                              onChange={handleInputChange}
                              value={Subject}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              type="text"
                              className="form-control form-control1 "
                              name="Message"
                              placeholder="Message"
                              cols="30"
                              rows="6"
                              onChange={handleInputChange}
                              value={Message}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="submit"
                              value="Send Message"
                              className="btn btn-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="info-wrap w-100 p-lg-5 p-4 img">
                    <h3>Contact us</h3>
                    <p className="mb-4">
                      We're open for any suggestion or just to have a chat
                    </p>
                    <div className="dbox w-100 d-flex align-items-start">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-map-marker"></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Address:</span> Raji farm: Near Gurmat
                          Missionary College, Chaunta Kalan,Ropar, Punjab
                          140111,India
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-phone"></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Phone:</span>
                          <a href="tel://7347200037">
                            7347200037 , 9814164511,9914999433
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-paper-plane"></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Email:</span>
                          <a href="mailto:rajifarm@gmail.com">
                            rajifarm@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-globe"></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Website:</span>
                          <a href="/">Rajifarm.com</a>
                        </p>
                      </div>
                 
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
