import "./VolumeCalculator.css";
import React, { useState, useEffect } from "react";


import VolumeCalculator_Liter from "./VolumeCalculator_Liter.js";
import VolumeCalculator_Pump from "./VolumeCalculator_Pump.js";

function VolumeCalculator() {


  return (
    <>
      <div className="volumeMaincontainer">
      <VolumeCalculator_Pump/>
      <VolumeCalculator_Liter/>
     
      </div>
    </>
  );
}
export default VolumeCalculator;
