import React, { useState, useEffect } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./iconsColor.css";
import "./AllCustomers.css";
import AddNewBill from "./AddNewBill.js";

import ToolTipsPopover from "./ToolTipsPopover.js";

const AllCustomers = ({ callbackToCusomter }) => {
  const apiUrl = `${process.env["REACT_APP_API_URL"]}customer/`;
  const [listing, setListing] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const toolTipsPopover = (title) => (
    <Popover
      className="d-inline-block"
      id="popover-trigger-hover-focus"
      title="rrr"
    >
      <div className="standardPading">
        <strong>{title}</strong>
      </div>
    </Popover>
  );

  useEffect(() => {
    getAll_Api();
  }, []);

  const handleEdit = (index) => {
    callbackToCusomter( index); // assuming callbackToCustomer is a function defined elsewhere
};

  const getAll_Api = async () => {
    console.log("api is called");
    try {
      const response = await axios.get(`${apiUrl}get`);
      if (response.data.data.length) {
        setListing(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching clones:", error);
    }
  };
  const Highlighted = ({ text = "", highlight = "" }) => {
    if (!highlight?.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return (
      <span>
        {parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <mark style={{ color: "red" }} key={i}>
                <b>{part}</b>
              </mark>
            ) : (
              <span key={i}>{part}</span>
            )
          )}
      </span>
    );
  };

  const renderRow = (row, index) => {
    return (
      <tr key={index}>
        <td>
          {<Highlighted text={String(row.srno)} highlight={searchTerm} />}
        </td>
        <th>
          <Highlighted text={row.name} highlight={searchTerm} />
        </th>

        <th>
          <a href={"tel:" + row.phone1}>
            <Highlighted text={row.phone1} highlight={searchTerm} />
          </a>
        </th>
        <th>
          <Highlighted text={row.address} highlight={searchTerm} />
        </th>
        <td>
          <OverlayTrigger
            trigger={["hover"]}
            placement="top"
            overlay={toolTipsPopover("Edit edit")}
          >
            <button
              className="btnRF-edit btn-sm"
               onClick={ () =>  handleEdit(row)}
            >
              <i className="bi bi-pen"></i>{" "} 
            </button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  // Function to filter listing based on search term
  const filteredListing = listing.filter((customer) => {
    return (
      customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.phone1.includes(searchTerm) ||
      String(customer.srno).includes(searchTerm) ||
      customer.address.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div>
      <h2>All customers</h2>
      <div className="search-box">
        <Form>
          <Form.Group controlId="search">
            <Form.Label>Search the Customer </Form.Label>
            <Form.Control
              type="text"
              placeholder="Search by name, phone number, or address"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Form>
      </div>

      <table>
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Name</th>
            <th>Phone number</th>
            <th>Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredListing.map((row, index) => renderRow(row, index))}
        </tbody>
      </table>
    </div>
  );
};

export default AllCustomers;
