import React, { useState, useEffect } from "react";

import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./iconsColor.css";

import "./PlantCart.css";
const PlantCart = ({bill}) => {


    const apiUrl = `${process.env["REACT_APP_API_URL"]}plantCart/`;
    const toolTipsPopover = (title) => (
      <Popover
        className="d-inline-block"
        id="popover-trigger-hover-focus"
        title="rrr"
      >
        <div className="standardPading">
          <strong>{title}</strong>
        </div>
      </Popover>
    );
  
    const [listing, setListing] = useState([]);
    const [newRow, setNewRow] = useState({
        description: "",
        quantity: 0,
        unitPrice: 0,
      });

    const [editingIndex, setEditingIndex] = useState(null);
  
    useEffect(() => {
        getItems();
    }, []);
  
    const getItems = async () => {
      console.log("api is called ");
      try {
        const response =  await axios
        .post(`${apiUrl}get`, {billId: bill._id})
        if (response.data.data.length) {
          const updatedListing = response.data.data.map((item) => ({
            ...item,
            isEditing: false, // Initially set to false
            isDeletedTapped: false,
            temporary: {},
          }));
          setListing(updatedListing);
        }
      } catch (error) {
        console.error("Error fetching clones:", error);
      }
    };
  
    const handleInputChangeForNew = (field, value) => {
      setNewRow({ ...newRow, [field]: value });
    };
  
    const handleInputChange = (field, value, index) => {
      //setNewRow({ ...newRow, [field]: value });
  
      const updatedListing = [...listing];
  
      updatedListing[index] = {
        ...updatedListing[index],
        temporary: {
          ...updatedListing[index].temporary,
          [field]: value,
        },
      };
  
      // Set the state with the updated listing array
      setListing(updatedListing);
      console.log("After", listing, updatedListing);
    };
  
    const handleEdit = (index) => {
      const updatedListing = [...listing];
  
      // Update the isEditing property of the item at the specified index to false
      console.log("Before", listing[index]);
      updatedListing[index] = {
        ...updatedListing[index],
        isEditing: true,
        temporary: updatedListing[index],
      };
  
      // Set the state with the updated listing array
      setListing(updatedListing);
      console.log("After", listing, updatedListing);
    };
  
    const handleCancelEdit = (index) => {
      const updatedListing = [...listing];
  
      // Update the isEditing property of the item at the specified index to false
      updatedListing[index] = {
        ...updatedListing[index],
        isEditing: false,
        isDeletedTapped: false,
        temporary: {},
      };
  
      // Set the state with the updated listing array
      setListing(updatedListing);
    };
  
    const handleDelete = async (index) => {
      const updatedListing = [...listing];
  
      // Update the isEditing property of the item at the specified index to false
      updatedListing[index] = {
        ...updatedListing[index],
        isEditing: false,
        isDeletedTapped: true,
        temporary: {},
      };
  
      // Set the state with the updated listing array
      setListing(updatedListing);
  
  
      try {
        // Perform delete operation using the clone ID or any other identifier
        const deletedClone = listing[index];
        console.log("handleDelete is called", deletedClone._id, listing[index]);
        // Here, you should use the appropriate identifier for the clone to be deleted
        const response = await axios.get(
          `http://localhost:4545/clone/delete/${deletedClone._id}`
        );
        // After successful deletion, update the listing
  
        if (response.data.data.length) {
          const updatedListing = response.data.data.map((item) => ({
            ...item,
            isEditing: false, // Initially set to false
            isDeletedTapped: false,
            temporary: {},
          }));
          setListing(updatedListing);
        }
  
        setListing(listing.filter((_, idx) => idx !== index));
      } catch (error) {
        console.error("Error deleting clone:", error);
      }
    };
  
    const handleSaveEdit = async (index) => {
      try {
        const editedClone = listing[index].temporary;
        // Perform update operation using the clone ID or any other identifier
        await axios
          .post(`${apiUrl}createClone`, editedClone)
          .then(function (response) {
            // navigate("/listing");
            if (response.data.status) {
              console.log("Return colone", response.data.clone);
              const updatedListing = [...listing];
  
              // Update the isEditing property of the item at the specified index to false
              updatedListing[index] = {
                ...response.data.clone,
                isEditing: false,
                isDeletedTapped: false,
                temporary: {},
              };
  
              // Set the state with the updated listing array
              setListing(updatedListing);
  
              setNewRow({ cloneName: "", colorName: "", hexValue: "" });
  
              //   navigate("/listing");
              // window.page.reload();
            } else {
              alert("Something went Wrong");
              // window.page.reload();
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      } catch (error) {
        console.error("Error updating clone:", error);
      }
    };
  
    const handleAddRow = async () => {
       
    
        // return
    //   if (newRow.cloneName === "") {
    //     alert("Please select");
    //     return;
    //   }

  
      try {
        await handleSubmit();
        await getItems(); // Reload the listing after adding a new clone
      } catch (error) {
        console.error("Error adding clone:", error);
      }
    };
  
    const handleSubmit = async () => {

        let some = newRow
        some = {...some , ofBill:bill._id}
      await axios
        .post(`${apiUrl}createUpdate`, some)
        .then(function (response) {
          // navigate("/listing");
          if (response.data.status) {
            setNewRow({    description: "",
            quantity: 0,
            unitPrice: 0 });
  
            //   navigate("/listing");
            // window.page.reload();
          } else {
            alert("Something went Wrong");
            // window.page.reload();
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    };





















    //   const handleBackCallback = (index) => {
    //    //backCallback();
    //   };
    //   const handleRemoveRow = (index) => {
    //     const updatedRows = [...rows];
    //     updatedRows.splice(index, 1);
    //     setRows(updatedRows);
    //   };
    //   const handleAddRow = () => {
    //     setRows([
    //       ...rows,
    //       {
    //         description: newRow.description,
    //         quantity: newRow.quantity,
    //         unitPrice: newRow.unitPrice,
    //       },
    //     ]);
    
    //     setNewRow({ description: "", quantity: 0, unitPrice: 0 });
    //   };
    
    //   const calculateTotal = () => {
    //     return rows.reduce((sum, row) => sum + row.quantity * row.unitPrice, 0);
    //   };
      
  return (
    <div>
      <h2>PlantCart View</h2>
      {/* {JSON.stringify(bill)}
          {JSON.stringify(newRow)} */}
          csasa
      <table>
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Item Description</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listing.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.description}</td>
                <td>{row.quantity}</td>
                <td>{row.unitPrice}</td>
                <td>{row.quantity * row.unitPrice}</td>
                <td>
            {/* //      <button onClick={() => handleRemoveRow(index)}>Cross</button> */}
                </td>
              </tr>
            ))}
            {/* Conditionally render input fields for the last row */}
            <tr>
              <td>
                jkadhs
                {/* <input type="text" value={rows.length + 1} /> */}
              </td>
              <td>
                <input
                  type="text"
                  value={newRow.description}
                  onChange={(e) =>
                    handleInputChangeForNew("description", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={newRow.quantity}
                  onChange={(e) =>
                    handleInputChangeForNew("quantity", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={newRow.unitPrice}
                  onChange={(e) =>
                    handleInputChangeForNew("unitPrice", e.target.value)
                  }
                />
              </td>
              <td>****</td>
              <td>
                <button onClick={handleAddRow}>Aded</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="total">
          <p>
            {/* <strong>Total: </strong> {calculateTotal()}{" "} */}
          </p>
        </div>
    </div>
  )
};

  // export default Admin;
export default PlantCart;

