import React, { useState, useEffect } from "react";

import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./iconsColor.css";

import "./Clone.css";

import DynamicPopover from "./DynamicPopover"

const Clone = () => {
  const apiUrl = `${process.env["REACT_APP_API_URL"]}clone/`;
  const toolTipsPopover = (title) => (
    <Popover
      className="d-inline-block"
      id="popover-trigger-hover-focus"
      title="rrr"
    >
      <div className="standardPading">
        <strong>{title}</strong>
      </div>
    </Popover>
  );

  const [listing, setListing] = useState([]);
  const [newRow, setNewRow] = useState({
    cloneName: "",
    colorName: "",
    hexValue: "",
  });
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    getClones();
  }, []);

  const getClones = async () => {
    console.log("api is called");
    try {
      const response = await axios.get(`${apiUrl}getClones`);
      if (response.data.data.length) {
        const updatedListing = response.data.data.map((item) => ({
          ...item,
          isEditing: false, // Initially set to false
          isDeletedTapped: false,
          temporary: {},
        }));
        setListing(updatedListing);
      }
    } catch (error) {
      console.error("Error fetching clones:", error);
    }
  };

  const handleInputChangeForNew = (field, value, index) => {
    setNewRow({ ...newRow, [field]: value });
  };

  const handleInputChange = (field, value, index) => {
    // setNewRow({ ...newRow, [field]: value });

    const updatedListing = [...listing];

    updatedListing[index] = {
      ...updatedListing[index],
      temporary: {
        ...updatedListing[index].temporary,
        [field]: value,
      },
    };

    // Set the state with the updated listing array
    setListing(updatedListing);
    console.log("After", listing, updatedListing);
  };

  const handleEdit = (index) => {
    const updatedListing = [...listing];

    // Update the isEditing property of the item at the specified index to false
    console.log("Before", listing[index]);
    updatedListing[index] = {
      ...updatedListing[index],
      isEditing: true,
      temporary: updatedListing[index],
    };

    // Set the state with the updated listing array
    setListing(updatedListing);
    console.log("After", listing, updatedListing);
  };

  const handleCancelEdit = (index) => {
    const updatedListing = [...listing];

    // Update the isEditing property of the item at the specified index to false
    updatedListing[index] = {
      ...updatedListing[index],
      isEditing: false,
      isDeletedTapped: false,
      temporary: {},
    };

    // Set the state with the updated listing array
    setListing(updatedListing);
  };

  const handleDelete = async (index) => {
    const updatedListing = [...listing];

    // Update the isEditing property of the item at the specified index to false
    updatedListing[index] = {
      ...updatedListing[index],
      isEditing: false,
      isDeletedTapped: true,
      temporary: {},
    };

    // Set the state with the updated listing array
    setListing(updatedListing);


    try {
      // Perform delete operation using the clone ID or any other identifier
      const deletedClone = listing[index];
      console.log("handleDelete is called", deletedClone._id, listing[index]);
      // Here, you should use the appropriate identifier for the clone to be deleted
      const response = await axios.get(
        `http://localhost:4545/clone/deleteClone/${deletedClone._id}`
      );
      // After successful deletion, update the listing

      if (response.data.data.length) {
        const updatedListing = response.data.data.map((item) => ({
          ...item,
          isEditing: false, // Initially set to false
          isDeletedTapped: false,
          temporary: {},
        }));
        setListing(updatedListing);
      }

      setListing(listing.filter((_, idx) => idx !== index));
    } catch (error) {
      console.error("Error deleting clone:", error);
    }
  };

  const handleSaveEdit = async (index) => {
    try {
      const editedClone = listing[index].temporary;
      // Perform update operation using the clone ID or any other identifier
      await axios
        .post(`${apiUrl}createClone`, editedClone)
        .then(function (response) {
          // navigate("/listing");
          if (response.data.status) {
            console.log("Return colone", response.data.clone);
            const updatedListing = [...listing];

            // Update the isEditing property of the item at the specified index to false
            updatedListing[index] = {
              ...response.data.clone,
              isEditing: false,
              isDeletedTapped: false,
              temporary: {},
            };

            // Set the state with the updated listing array
            setListing(updatedListing);

            setNewRow({ cloneName: "", colorName: "", hexValue: "" });

            //   navigate("/listing");
            // window.page.reload();
          } else {
            alert("Something went Wrong");
            // window.page.reload();
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    } catch (error) {
      console.error("Error updating clone:", error);
    }
  };

  const handleAddRow = async () => {
    if (newRow.cloneName === "") {
      alert("Please select");
      return;
    }

    try {
      await handleSubmit();
      await getClones(); // Reload the listing after adding a new clone
    } catch (error) {
      console.error("Error adding clone:", error);
    }
  };

  const handleSubmit = async () => {
    await axios
      .post(`${apiUrl}createClone`, newRow)
      .then(function (response) {
        // navigate("/listing");
        if (response.data.status) {
          setNewRow({ cloneName: "", colorName: "", hexValue: "" });

          //   navigate("/listing");
          // window.page.reload();
        } else {
          alert("Something went Wrong");
          // window.page.reload();
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const renderRow = (row, index) => {
    if (row.isEditing) {
      return (
        <tr key={index}>
          <td>{index + 1} </td>
          <td>
            <input
              type="text"
              value={row.temporary.cloneName}
              onChange={(e) =>
                handleInputChange("cloneName", e.target.value, index)
              }
            />
          </td>
          <td>
            <input
              type="text"
              value={row.temporary.colorName}
              onChange={(e) =>
                handleInputChange("colorName", e.target.value, index)
              }
            />
          </td>
          <td>
            <Form.Control
              type="color"
              id="exampleColorInput"
              defaultValue={row.temporary.hexValue}
              value={row.temporary.hexValue}
              title="Choose your color"
              onChange={(e) =>
                handleInputChange("hexValue", e.target.value, index)
              }
            />
          </td>
          <td>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={toolTipsPopover("Cancel editing")}
            >
              <button
                className="btnRF-cross btn-sm"
                onClick={() => handleCancelEdit(index)}
              >
                <i class="bi bi-x-square-fill"></i>{" "}
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={toolTipsPopover("Save it")}
            >
              <button
                className="btnRF-save btn-sm"
                onClick={() => handleSaveEdit(index)}
              >
                <i class="bi bi-floppy-fill"></i>{" "}
              </button>
            </OverlayTrigger>
          </td>
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <td>{index + 1} </td>
          <td>{row.cloneName}</td>
          <td>{row.colorName}</td>
          <td>
            {/* <td>{row.hexValue}</td> */}
            <Form.Control
              type="color"
              disabled
              defaultValue={row.hexValue}
              value={row.hexValue}
            />
          </td>
          <td>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={toolTipsPopover("Edit it")}
            >
              <button
                className="btnRF-edit btn-sm"
                onClick={() => handleEdit(index)}
              >
                {" "}
                <i class="bi bi-pen"></i>{" "}
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={ toolTipsPopover("Delete it")}
            >
              <button
                className="btnRF-delete btn-sm "
                onClick={() => handleDelete(index)}
              >
                <i class="bi bi-trash"></i>
              </button>
            </OverlayTrigger  >

         

       
          </td>
        </tr>
      );
    }
  };

  return (
    <div>
      <h2>Clone View</h2>
      <div className="parent-container-tools ">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          splacement="top"
          overlay={toolTipsPopover("Refresh")}
        >
          <button className="btn-sm child-element-tools " onClick={getClones}>
            <i class="bi bi-arrow-clockwise"></i>
          </button>
        </OverlayTrigger>
      </div>
      <table>
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Clone name</th>
            <th>Color Name</th>
            <th>Color hexcode</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listing.map((row, index) => renderRow(row, index))}
          <tr>
            <td>new element</td>
            <td>
              <input
                type="text"
                value={newRow.cloneName}
                onChange={(e) =>
                  handleInputChangeForNew("cloneName", e.target.value)
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newRow.colorName}
                onChange={(e) =>
                  handleInputChangeForNew("colorName", e.target.value)
                }
              />
            </td>
            <td>
              <Form.Control
                type="color"
                id="exampleColorInput"
                defaultValue="#563d7c"
                value={newRow.hexValue}
                title="Choose your color"
                onChange={(e) =>
                  handleInputChangeForNew("hexValue", e.target.value)
                }
              />
            </td>
            <td>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                splacement="top"
                overlay={toolTipsPopover("Add new clone")}
              >
                <button className="btn-sm" onClick={handleAddRow}>
                  <i className="bi bi-plus-square"></i>
                </button>
              </OverlayTrigger>


              
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Clone;



