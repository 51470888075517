import "./RoleFERTILIZER.css";
import React, { useState, useEffect } from "react";
import essentialBeneficiaElements from "../Assests/Fertilzers/EssentialBeneficiaElements.png";


import Role_CommonComplexF from "./Role_CommonComplexF.js";


function RoleFERTILIZER() {
  return (
    <>
      <div className="mainView">
        <div>
          <h2>WHY FERTILIZE</h2>
          <p>
            Soils need fertility maintenance. Soil is a natural body of finely
            divided rocks,minerals and organic matter. Sand, silt, clay and
            organic matter help provide tilth, necessary aeration and favorable
            water intake rates, but they seldom maintain adequate plant food to
            sustain continuous healthy plant growth.
          </p>
        </div>
        <div className="divImageElement">
          <img src={essentialBeneficiaElements} alt="alt text" className="" />
        </div>

        <div>
          <h2>WHAT IS FERTILIZER?</h2>
          <p>
            There are 17 elements that are known to be essential for plant
            growth and development. Fertilizers (also called plant food
            elements) are materials produced to supply these elements in a
            readily available form for plant use.
          </p>
        </div>

        <div className="divTable_Fer">
          <table>
            <tr>
              <th>Element</th>
              <th>Symbol-Atomic number</th>
              <th>Classification</th>
              <th>Chemical formula Taken into Plant </th>
              <th>Source</th>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_Non_EM">Hydrogen</td>
              <td className="td_color_Non_EM">H (1)</td>
              <td className="td_color_Non_EM">Non mineral Nutrient</td>
              <td className="td_color_Non_EM">
                H<sub>2</sub>O
              </td>
              <td className="td_color_Non_EM">Air-Water</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_Non_EM">Oxygen</td>
              <td className="td_color_Non_EM">O (8)</td>
              <td className="td_color_Non_EM">Non mineral Nutrient</td>
              <td className="td_color_Non_EM">
                O<sub>2</sub> and CO <sub>2</sub>
              </td>
              <td className="td_color_Non_EM">Air-Water</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_Non_EM">Carbon </td>
              <td className="td_color_Non_EM">C (6)</td>
              <td className="td_color_Non_EM">Non mineral Nutrient</td>
              <td className="td_color_Non_EM">
                {" "}
                CO<sub>2</sub>
              </td>
              <td className="td_color_Non_EM">Air</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Nitrogen</td>
              <td className="td_color_EM">N (7)</td>
              <td className="td_color_EM">
                <b>Macro</b>-nutrient (Primary)
              </td>
              <td className="td_color_EM">
                NH<sub>4</sub>
                <sup>+</sup> and NO<sub>3</sub> <sup>-</sup>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>

            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Phosphorus</td>
              <td className="td_color_EM">P (15)</td>
              <td className="td_color_EM">
                <b>Macro</b>-nutrient (Primary)
              </td>
              <td className="td_color_EM">
                H<sub>2</sub>PO<sub>4</sub>
                <sup>-</sup> and H<sub>2</sub>PO<sub>4</sub>
                <sup>2-</sup>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>

            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Potasium</td>
              <td className="td_color_EM">K (19)</td>
              <td className="td_color_EM">
                <b>Macro</b>-nutrient(Primary)
              </td>
              <td className="td_color_EM">
                K<sup>+</sup>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Calcium</td>
              <td className="td_color_EM">Ca (20)</td>
              <td className="td_color_EM">Secondary element</td>
              <td className="td_color_EM">
                {" "}
                Ca<sup>2+</sup>{" "}
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Magnesium</td>
              <td className="td_color_EM">Mg (12)</td>
              <td className="td_color_EM">Secondary element</td>
              <td className="td_color_EM">
                Mg<sup>2+</sup>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Sulfur</td>
              <td className="td_color_EM">S (16)</td>
              <td className="td_color_EM">Secondary element</td>
              <td className="td_color_EM">
                SO<sup>2-</sup>
                <sub>4</sub>{" "}
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Boron</td>
              <td className="td_color_EM">B (5)</td>
              <td className="td_color_EM">
                <b>Micro</b>-nutrient
              </td>
              <td className="td_color_EM">
                B(OH)<sub>3</sub>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Chlorine</td>
              <td className="td_color_EM">Cl(17)</td>
              <td className="td_color_EM">
                <b>Micro</b>-nutrient
              </td>
              <td className="td_color_EM">
                Cl<sup>-</sup>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Copper</td>
              <td className="td_color_EM">Cu (29)</td>
              <td className="td_color_EM">
                <b>Micro</b>-nutrient
              </td>
              <td className="td_color_EM">
                Cu<sup>2+</sup>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Iron</td>
              <td className="td_color_EM">Fe (26)</td>
              <td className="td_color_EM">
                <b>Micro</b>-nutrient
              </td>
              <td className="td_color_EM">
                Fe<sup>2+</sup> and Fe<sup>3+</sup>{" "}
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Manganese</td>
              <td className="td_color_EM">Mn (25)</td>
              <td className="td_color_EM">
                <b>Micro</b>-nutrient
              </td>
              <td className="td_color_EM">
                Mn<sup>2+</sup>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Molybdenum</td>
              <td className="td_color_EM">Mo (42)</td>
              <td className="td_color_EM">
                <b>Micro</b>-nutrient
              </td>
              <td className="td_color_EM">
                MoO<sup>2-</sup> <sub>4</sub>
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Nickel</td>
              <td className="td_color_EM">Ni (28)</td>
              <td className="td_color_EM">
                <b>Micro</b>-nutrient
              </td>
              <td className="td_color_EM">
                Ni<sup>2+</sup>{" "}
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_EM">Zinc</td>
              <td className="td_color_EM">Zn (30)</td>
              <td className="td_color_EM">
                <b>Micro</b>-nutrient
              </td>
              <td className="td_color_EM">
                Zn<sup>2+</sup>{" "}
              </td>
              <td className="td_color_EM">Soil & FERTILIZER</td>
            </tr>

            {/* ---------- */}
            <tr>
              <td className="td_color_BME">Sodium</td>
              <td className="td_color_BME">Na (11)</td>
              <td className="td_color_BME">Beneficial Mineral Element</td>
              <td className="td_color_BME"> -- </td>
              <td className="td_color_BME">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_BME">Cobalt</td>
              <td className="td_color_BME">Co (27)</td>
              <td className="td_color_BME">Beneficial Mineral Element</td>
              <td className="td_color_BME"> -- </td>
              <td className="td_color_BME">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_BME">Silicon</td>
              <td className="td_color_BME">Si (14)</td>
              <td className="td_color_BME">Beneficial Mineral Element</td>
              <td className="td_color_BME"> -- </td>
              <td className="td_color_BME">Soil & FERTILIZER</td>
            </tr>
            {/* ---------- */}
            <tr>
              <td className="td_color_BME">Selenium</td>
              <td className="td_color_BME">Se (34)</td>
              <td className="td_color_BME">Beneficial Mineral Element</td>
              <td className="td_color_BME"> -- </td>
              <td className="td_color_BME">Soil & FERTILIZER</td>
            </tr>
            {/* -------Above this --- */}
          </table>
        </div>
        <div>
          <p>
            Three of the seventeen essential elements, carbon, hydrogen and
            oxygen are taken primarily from the air and water. Oxygen and
            hydrogen are obtained by plants from water. Carbon and hydrogen are
            taken in by the leaves from the air. The other fourteen elements
            utilized by the plant must come from the soil or from added
            fertilizer materials. <br />
            Crop removal of these elements, plus leaching, volatilization and
            erosion causes the soil fertility to be continually reduced. Turf
            and landscape plants will have poor color (yellow-green to yellow),
            poor plant density allowing weed invasion and low plant vigor which
            increases plants suseptibility to disease and insect damage.
            <br />
            Soil productivity can be maintained by well managed, scheduled
            applications of multiple element fertilizers.
          </p>
        </div>
        <div>
          <h2>WHY DOESN’T FERTILIZER HAVE 100% PLANT FOOD? </h2>
          <p>
            A fertilizer 16-6-8 analysis adds up to 30% plant food or thirty
            pounds per hundred pounds of material. <br />
            <i>What is the other 70%?</i> <br />
            It is not a filler; it is the way the plant food is chemically
            compounded so plants can utilize it.
            <br />
            Plants can’t use elemental nitrogen (N), they only take up nitrogen
            when it is in the NO3 or NH4 form. This means that for each part of
            nitrogen you have three parts of oxygen with (NO3 ) or 4 parts of
            hydrogen with (NH4 ). When nitrogen is in a compound which is
            available to plants, nitrogen is only part of the compound. The same
            is true with phosphorus and the other elements. Phosphorus is
            absorbed by plants as H2 PO4 –, HPO4 = or PO4 = depending upon soil
            pH.
            <br /> <br />
            If fertilizers were in the elemental form, they would be difficult
            to handle:
            <br /> <br />
            Elemental <b>nitrogen (N)</b> –a colorless inert gas that could
            drift off into the air.
            <br />
            Elemental <b>phosphorus (P) </b> –catches fire spontaneously when
            exposed to the air. It is actually poisonous to plants in
            concentrated forms.
            <br />
            Elemental <b>potassium (K)</b> –placed in contact with water it will
            catch fire, explode and decompose into a strong caustic solution.
            <br />
          </p>
        </div>

        <h2>
          FUNCTIONS OF THE 14 ELEMENTS OBTAINED FROM THE SOIL AND ADDED
          FERTILIZER
        </h2>
        <div className="primaryFer">
          <div>
            <div>
              <h3>A. Primary Plant Food Elements</h3>
              <p>
                <b> Nitrogen(N),Phosphorus(P),Potassium(K) </b>
                Plants rapidly utilize these elements and unfertilzed soils
                normally cannot provide then in quality needed for best plant
                growth.
              </p>
            </div>

            <div>
              <h3>Nitrogen (N)</h3>
              <ol className="fertC">
                <li>
                  Promotes rapid vegetative growth (leaf and stems) hastening
                  recovery after mowing and imparting vigor to the turf.
                </li>
                <li>
                  . A vital element in the formation and function of
                  chlorophyll–the key ingredient imparting dark green color.
                </li>
                <li>Synthesizes amino acids which in turn form protein.</li>

                <li>Regulates the uptake of other nutrients</li>

                <li>
                  {" "}
                  Basic ingredient of vital compounds - Nucleic acid and enzymes
                </li>
              </ol>
            </div>

            <div>
              <h3>Phosphorus (P)</h3>
              <ol className="fertC">
                <li>
                  Stimulates early root formation and growth–gets plants off to
                  a good start and forms a root filter system in the soil to
                  efficiently pick up the other available plant nutrients and
                  water. Improves the strength and stamina of the plant.
                </li>
                <li>Hastens maturity (conversion of starch to sugar).</li>
                <li>Stimulates blooming and seed development</li>
                <li>
                  Causes energy transformation and conversion processes in which
                  sugars are converted to hormones, protein and energy to grow
                  new leaves and fruit.
                </li>
                <li>Forms nucleic acids (DNA and RNA).</li>
                <li>Vital for photosynthesis (greening for plants).</li>
                <li> Essential for cell division.</li>
              </ol>
            </div>

            <div>
              <h3>Potassium (K)</h3>
              <ol className="fertC">
                <li>Aids in the development of stems and leaves.</li>
                <li>
                  Increases disease resistance and hardiness which helps
                  wearability.
                </li>
                <li>
                  Strengthens cell walls, causing grass to stand up and reduces
                  lodging.
                </li>
                <li>
                  Affects water intake by plant cells–plants with inadequate
                  potassium may wilt in the presence of ample moisture.
                </li>
                <li>Acts as a catalyst in Iron uptake.</li>
                <li>
                  Essential to the formation and translocation of protein,
                  starches, sugar and oil–improving the size and quality of
                  fruit, grains and tubers.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="secondaryFer">
          <div>
            <h3>B. Secondary Plant Food Elements</h3>
            <p>
              <b>Calcium(Ca), Magnesium(Mg),Sulfur(S)</b> These are used in
              somewhat less quantities than the primary elements, but they are
              just as essential for plant growth and quality.
            </p>
          </div>
          <div>
            <h3>Calcium (Ca)</h3>
            <ol className="fertC">
              <li>
                Calcium is an essential part of cell wall structure and must be
                present for the formation of new cells
              </li>
              <li>
                {" "}
                Deficiency of calcium causes weakened stems and premature
                shedding of blossoms and buds.
              </li>
            </ol>
          </div>

          <div>
            <h3>Magnesium (Mg)</h3>
            <ol className="fertC">
              <li>Essential for photosynthesis (greening of plant)</li>
              <li>
                Activator for many plant enzymes required in growth process.
              </li>
            </ol>
          </div>
          <div>
            <h3>Sulfur (S)</h3>
            <ol className="fertC">
              <li>
                A constituent of three amino acids and is therefore essential in
                the formation of protein.
              </li>
              <li>Helps maintain green color in plants.</li>
              <li> Improves alkaline soils.</li>
              <li>
                Helps compacted soils–making them loose and allowing better
                water penetration.
              </li>
            </ol>
            <br />
            <p>
              Sulfur Note–There are commonly two types of sulfur applied to
              plants and soils: Sulfate Sulfur - (SO4); Elemental Sulfur (S)
            </p>
          </div>

          <div>
            <h4>Sulfate Sulfur (SO4)</h4>
            <p>
              Sulfate Sulfur (SO4 ) is the form taken up for plant food. Many
              plants require as much sulfur as phosphate in their growth
              processes. <br />
              <br />
              Sulfate Sulfur (SO4 ) is contained in gypsum (CaSO4 ) and other
              sulfate fertilizers–Ammonium Sulfate, Ammonium Phosphate Sulfate
              and many turf fertilizers. <br />
              <br />
              Gypsum (CaSO4 ) will help reclaim alkali soils and make them loose
              and friable. Alkali soils contain sodium which causes soil to
              disperse, puddle and seal up. The free calcium from gypsum will
              replace the sodium on the clay particle and allow the sodium to be
              leached out of the soil. <br />
              <br />
              It also causes the small soil particles to flocculate (join
              together in small crumbs), leaving space between them for air and
              water movement.
            </p>
          </div>
          <div>
            <h4>Elemental Sulfur (S)</h4>
            <p>
              Elemental Sulfur (S) will convert to sulfate sulfur in the soil.
              This reaction can be slow, depending upon the sulfur particle size
              and the soil conditions. Once it has converted to sulfate sulfur
              (SO4 ), it is available to the plant. If the soil contains
              calcium, it can form gypsum in the soil and be used for
              reclamation of alkaline soils. <br />
              <br />
              Elemental sulfur will lower the pH of the soil at the location of
              the pellet as it converts to sulfate.
            </p>
          </div>
        </div>
        <div className="micronutrients_div">
          <div>
            <h3>C. Micronutrients: Iron, Zinc and Manganese</h3>
            <p>
              Even though micronutrients are used by plants in very small
              amounts, they are just as essential for plant growth as large
              amounts of primary and secondary nutrients. They must be
              maintained in balance in order for all nutrients and water to be
              used efficiently <br />
              <br />
              On turf grass there are three micronutrients that are particularly
              important in order to maintain green color and plant vigor:
            </p>
            <div>
              <h3>Iron (Fe)</h3>
              <p>
                Yellowing of grass (Iron Chlorosis) is often due to iron
                deficiency. Iron is required for the formation of chlorophyll in
                the plant cell (causes turf to maintain a healthy green color).
                It serves as a catalyst for biological processes such as
                respiration, symbiotic fixation of nitrogen and photosynthesis.
                <br />
                <br />
                Applications of iron can correct iron deficiency, but it may be
                temporary in high pH soils, due to tie up with calcium. This may
                require acidification of the soil with elemental sulfur or the
                use of ammonium forms of nitrogen or some other acidification
                agents. As ammonium converts to nitrate in the soil, it has an
                acidifying effect. This acidifying effect makes iron and many
                other elements more available in high pH soils.
              </p>
            </div>
            <div>
              <h3>Zinc (Zn)</h3>
              <p>
                Zinc is an essential component of several plant enzymes. It is a
                part of auxins and controls the synthesis of indoleacetic acid
                which regulates growth compounds. Zinc also affects the intake
                and efficient use of water by plants.
              </p>
            </div>
            <div>
            <h3>Manganese (Mn)</h3> 
            <p>Manganese serves as an activator for enzymes in
plants. Without Manganese, the plants cannot use the
iron which they have absorbed. It assists the iron in
chlorophyll formation which causes yellowish turf to
green up.</p>
            </div>
          </div>
        </div>
        <Role_CommonComplexF/>
      </div>
    </>
  );
}
export default RoleFERTILIZER;
