import React, { useState } from "react";
import styles from "./FertilizerNutrientCalculator2.css";

function FertilizerNutrientCalculator2(props) {
  // ----------------------------------------
  // ----------------------------------------
  const [fn2_PureElement, setFn2_PureElement] = useState(0);

const getfn2_PureElementInput = (e) => {

    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setFn2_PureElement(someKilla);

  
  };

  const renderValuePure = () => {

    return props.MultiplyByPure * fn2_PureElement
  }
// -----------------------fff--------------------------------1

const [fc2_OxideForm, setFc2_OxideForm] = useState(0);


const getffc2_OxideForm = (e) => {

    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setFc2_OxideForm(someKilla);

  
  };

  const renderValuefc2_OxideForm = () => {

    return props.MultiplyByOxideform * fc2_OxideForm
  }


// -------------------------------------------------------2


   
    const  trst =  () => {
       
        return props.themeTR  + " " + props.themN 
    
    }

    const tdValue = () => {
        return "commonFC2_tr" 

    }
  return (
    
    <>
    <div className="SuperFCContainer">
    <div className={trst()}>
          <table>
            <tr className={props.themeTR} align="right"  >
              <th className={tdValue()}>Pure Element</th>
              <th className={tdValue()} >Multiply by</th>
              <th className={tdValue()}>Oxide form</th>
              <th className={tdValue()}>Multiply by</th>
              <th className={tdValue()}>Pure Element</th>
            </tr>
            <tr className={trst()}>
              <td className={tdValue()} >{props.PureElement}</td>
              <td className={tdValue()} >{" ✖ "} {props.MultiplyByPure} {" = "}</td>
              <td className={tdValue()} >{props.Oxideform}</td>
              <td className={tdValue()} >{" ✖ "}{props.MultiplyByOxideform} {" = "}</td>
              <td className={tdValue()} >{props.PureElement}</td>
            </tr>
            <tr className={trst()}>
              <td className={tdValue()} >
                {" "}
                <input
                  type="number"
                  className="fertilizerUnitInput  commonFC2_Input "
                  name="number_n_fertilizerInput"
                  placeholder= {props.PureElement}
                    onChange={getfn2_PureElementInput}
                    value={fn2_PureElement}

              
                />
              </td>
              <td className={tdValue()} >{" ✖ "} {props.MultiplyByPure} {" = "}</td>
              <td className={tdValue()} ><span className="commonResultSpan"> {renderValuePure()}</span></td>
              <td colSpan={2}></td>
            </tr>
            <tr className={trst()}>
              <td colSpan={2}></td>
              
              <td >
              <input
                type="number"
                className="nutrientPercentageUnit  commonFC2_Input"
                name="number_n_fertilizerInput"
                placeholder= {props.Oxideform}
                  onChange={getffc2_OxideForm}
                  value={fc2_OxideForm}




              />
</td>
              <td className={tdValue()} > {" ✖ "}{props.MultiplyByOxideform} {" = "}</td>
              <td className={tdValue()} ><span className="commonResultSpanPure"> {renderValuefc2_OxideForm()}</span></td>
            </tr>
          </table>
      
      </div>
    </div>

    
    </>
  );
}

export default FertilizerNutrientCalculator2;
