// Sidebar.js
import { React, useState } from "react";
import "./Sidebar.css";
const Sidebar = ({ onSelectView }) => {
  const [selectedView, setSelectedView] = useState("dashboard");

  const handleViewChange = (view) => {
    setSelectedView(view);
    onSelectView(view);
  };

  return (
    <div className="sidebar">
      <h2>Sidebar</h2>
      <ul>
        <li
          className={selectedView === "dashboard" ? "selected" : ""}
          onClick={() => handleViewChange("dashboard")}
        >
          Dashboard
        </li>
        <li
          className={selectedView === "settings" ? "selected" : ""}
          onClick={() => handleViewChange("settings")}
        >
          Settings
        </li>
        <li
          className={selectedView === "addNewBill" ? "selected" : ""}
          onClick={() => handleViewChange("addNewBill")}
        >
          Add New Bill
        </li>
        <li
          className={selectedView === "Clone" ? "selected" : ""}
          onClick={() => handleViewChange("Clone")}
        >
          Clone
        </li>
        <li
          className={selectedView === "AllCustomers" ? "selected" : ""}
          onClick={() => handleViewChange("AllCustomers")}
        >
          All Customers
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
