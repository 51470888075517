// MainContent.js
import React, { useState } from "react";

import SettingsView from "../NestedFolders/SettingsView.js";
import DashboardView from "../NestedFolders/DashboardView.js";
import AddNewBill from "../NestedFolders/AddNewBill.js";
import Clone from "../NestedFolders/Clone.js";
import AllCustomers from "../NestedFolders/AllCustomers.js";

const MainContent = ({ selectedView }) => {
  const [customerData, setCustomerData] = useState(null);

  return (
    <div className="main-content">
      {selectedView === "dashboard" ? <DashboardView /> : null}
      {selectedView === "settings" ? <SettingsView /> : null}
      {selectedView === "addNewBill" ? <AddNewBill /> : null}
      {selectedView === "Clone" ? <Clone /> : null}

      {selectedView === "AllCustomers" && customerData == null ? (
        <AllCustomers
          callbackToCusomter={(vlaue) => {
            setCustomerData({
              ...vlaue,
              isEditing: false,
              temporary: vlaue,
              fromAddCustomer: true,
            });
          }}
        />
      ) : selectedView === "AllCustomers" && customerData !=null ? (
        <>
          <AddNewBill formDataPass={customerData} backCallback={ () => {
             setCustomerData(null)
          }
          } />
        </>
      ) : null}
    </div>
  );
};

export default MainContent;
