import "./VolumeCalculator_Pump.css";
import React, { useState, useEffect } from "react";

function VolumeCalculator_Liter() {
  const [selectedTab, setSelectedTab] = useState(93);

  const get_Number_pumps = () => {
    switch (selectedTab) {
      case 91:
        return waterVolume / 15;
      case 92:
        return waterVolume / 20;
      case 93:
        return waterVolume / 25;
    }
  };

  const get_pumpsInfo = () => {
    switch (selectedTab) {
      case 91:
        return 15;
      case 92:
        return 20;
      case 93:
        return 25;
    }
  };

  const get_perPumpLiquidfromTotal = () => {
    switch (selectedTab) {
      case 91:
        return result_totalLiquid_ml() / 15;
      case 92:
        return result_totalLiquid_ml() / 20;
      case 93:
        return result_totalLiquid_ml() / 25;
    }
  };

  const actionSelectButton = (e) => {
    setSelectedTab(e);
  };
  const selectedStyle = (e) => {
    if (e == selectedTab) {
      return " buttonPumpsCapacity buttonPumpsCapacityOn";
    }

    return " buttonPumpsCapacity ";
  };

  // ----------------------------------------
  const [waterVolume, setWaterVolume] = useState(200);
  const get_waterVolume = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setWaterVolume(someKilla);
  };

  // ----------------------------------------
  const [pumpVolume, setPumpVolume] = useState(2);
  const get_pumpVolume = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setPumpVolume(someKilla);
  };

  // ----------------------------------------
  const [liquidVolume, setLiquidVolume] = useState(23);
  const get_liquidVolume = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setLiquidVolume(someKilla);
  };

  const result_totalLiquid_ml = () => {
    return liquidVolume * waterVolume;
  };

  const result_totalWaterNeed_Pump = () => {
    return get_pumpsInfo() * pumpVolume;
  };


  const result_totalLiquid_ml_Pumps = () => {
    return liquidVolume * pumpVolume;
  };

  const result_totalLiquid_l_Pumps = () => {
    return result_totalLiquid_ml_Pumps() / 1000;
  };

  const result_totalLiquid_l = () => {
    return result_totalLiquid_ml() / 1000;
  };

  return (
    <>
      <div className="p_">
        <div className="p_div_SubContainer">
          <h1 className="p_volume_HeadingCenter">
            {" "}
            Volume calculator based on Pump size
          </h1>

          <div>
            <p>
              The below calculations are used to calculate the total amount of
              liquid used for given amount of water and number of pumps and
              liquid per pump.
              <ul>
                <li> First select the size of pump.</li>
                <li> Write the liquid prefer of selected size pump.</li>
                <li> At last add the number of Pumps you need in field.</li>
              </ul>
            </p>

            <table>
              <tr className="p_tr_otherPumps">
                <td colSpan={2} className="p_tr_otherPumps">
                  <div>
                    {" "}
                    <div className="p_containerbuttonN">
                      <button
                        type="button"
                        // className="p_buttonPumpsCapacity"
                        className={selectedStyle(91)}
                        onClick={() => actionSelectButton(91)}
                      >
                        15 Liter Pump
                      </button>

                      <button
                        type="button"
                        // className="p_buttonPumpsCapacity"
                        className={selectedStyle(92)}
                        onClick={() => actionSelectButton(92)}
                      >
                        20 Liter Pump
                      </button>

                      <button
                        type="button"
                        // className="p_buttonPumpsCapacity volume_PumpSelected"
                        className={selectedStyle(93)}
                        onClick={() => actionSelectButton(93)}
                      >
                        25 liter Pump
                      </button>
                    </div>
                  </div>
                </td>
              </tr>

              <tr className="p_tr_LiquidP">
                <td colSpan={2} className="p_volume_HeadingCenter">
                  {" "}
                  Recommended
                  <span className="p_volume_Liquid"> Liquid </span> by company
                  in ml (Milli-liters) for <b> {get_pumpsInfo()} </b> liter
                  pump.
                </td>
              </tr>
              <tr>
                <td className="p_volumeWidth volume_HeadingCenter">
                  {" "}
                  1 Pump of {get_pumpsInfo()} liter of pump needs
                </td>
                <td>
                  <input
                    type="number"
                    className="p_form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_LiquidInput"
                    id="colFormLabel"
                    placeholder="Liquid in ml"
                    onChange={get_liquidVolume}
                    value={liquidVolume}
                    // ref={hectareThis}
                  />{" "}
                  <span className="p_volume_Liquid"> ml (milli-liters) </span>
                </td>
              </tr>
              <tr className="p_tr_LiquidP">
                <th>
                  {" "}
                  Number of{" "}
                  <span className=" ">{get_pumpsInfo()} liters pumps </span> of
                  water you need (** Multipler of 15,20,25){" "}
                </th>
                <th>
                  {" "}
                  Total amount <span className="p_volume_Liquid">
                    {" "}
                    Liquid
                  </span>{" "}
                  used.{" "}
                </th>
              </tr>
              <tr>
                <td>
                  <input
                    type="number"
                    className="p_form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme p_volume_pumpInput"
                    id="colFormLabel"
                    placeholder="Number of pumps needed"
                    onChange={get_pumpVolume}
                    value={pumpVolume}
                  />{" "}
                  <span className="p_volume_Pump"> Pumps </span>
                </td>
                <td className="p_volume_Liquid_result">
                  <span> {result_totalLiquid_ml_Pumps()}</span> ml
                  (milli-liters) <br />
                  <span> {result_totalLiquid_l_Pumps()}</span> liters <br />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className=" p_volume_waterBasic"> <b> {result_totalWaterNeed_Pump()}</b> liter water need. </td>
              </tr>

      
            </table>

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VolumeCalculator_Liter;
