import React from "react";
import "./NitrogenCalculator.css";
import  { useState } from "react";




function NitrogenCalculator(props) {


  const [selectedTab, setSelectedTab] = useState(92);
 // 91 Selected 
 // 92 nonSelected

 const selectedStyle = (e) => {
    if (e == selectedTab) {
        return "buttonStyleN selectedN"
    }

    return "buttonStyleN"
 }

  var renderRow = (year, doseN, ureaN , dosage) => {

    var value1 = doseN
     var valueUrea = ureaN

    var percentage =  doseN * 0.33
    var percentageUrea =  valueUrea * 0.33

    if (selectedTab == 91) {

        value1 =  value1 +  percentage
        valueUrea = valueUrea + percentageUrea

    }else if (selectedTab == 92) {

    }else if (selectedTab == 93) {
        value1 =  value1 -  percentage
        valueUrea = valueUrea - percentageUrea
    }
    var value =   Number((value1/3).toFixed(1)); // 6.7
    var valueurea =   Number((valueUrea/3).toFixed(1)); // 6.7
    return (
        <tr >
        <td className="trNormal"> {year} year</td>
        <td className="trNormal" >{value1} gram N - {valueUrea} gram urea </td>
        <td>

          <table className="nestedTableN">
            <tr className="trN">
              <td>May</td>
              <td>{value} gram N - {valueurea} gram urea </td>
            </tr>
            <tr className="trN">
              <td> June </td>
              <td>{value} gram N - {valueurea} gram urea</td>
            </tr>
            <tr className="trN">
              <td> September </td>
              <td>{value} gram N - {valueurea} gram urea  </td>
            </tr>
          </table>
        </td>
        <td> {dosage}</td>
      </tr>
    );
  };

  const actionSelectButton = (e) => {

     setSelectedTab(e)
    
  }

  return (
    <div className="mianNitrogenContainter">
      <h1 className="h1Headng"> Nitrogen(N) fertilizer calculator for Poplar Trees</h1>
      <div className="subContainerTable">
        <table>
          <tr>
            <th colSpan={4}>

            <div className="containerbuttonN">

            <button
              type="button"
              className= {selectedStyle(91)}
              onClick={ () => actionSelectButton(91)}
            >
             
              Low Nitrogen soils (25% more)
            </button>

            <button
              type="button"
              className= {selectedStyle(92)}
              onClick={ () => actionSelectButton(92)}
            >
               Normal Nitrogen Soils
             
            </button>

            <button
              type="button"
              className= {selectedStyle(93)}
              onClick={ () => actionSelectButton(93)}
            >
               High Nitrogen soils (25% less)
            </button>

          </div>

            </th>
          </tr>
          <tr className="trHeading">
            <th >Year </th>
            <th>Dosage  (gram/plant/ <b> Year</b>  ) </th>
            <th>Dosage (gram/plant/<b> Months</b>  ) </th>
            <th> ਪਾਉਂਣ ਦਾ ਤਰੀਕਾ </th>
          </tr>
    
          {renderRow("1st", 80 , 170 , "1 ਮੀਟਰ ਘੇਰੇ ਵਿੱਚ")}
          {renderRow("2nd", 120 ,260, "2 ਮੀਟਰ ਘੇਰੇ ਵਿੱਚ")}
          {renderRow("3rd", 160 ,350, "3 ਮੀਟਰ ਚੌੜੀ ਪੱਟੀ (1.5 ਮੀਟਰ ਬੂਟੇ ਦੀ ਕਾਤਰ ਦੇ ਦੋਨੋ ਪਾਸੇ)")}
          {renderRow("4th", 200 ,430, "3 ਮੀਟਰ ਚੌੜੀ ਪੱਟੀ (1.5 ਮੀਟਰ ਬੂਟੇ ਦੀ ਕਾਤਰ ਦੇ ਦੋਨੋ ਪਾਸੇ)")}
          {renderRow("5th", 240 , 520, "3 ਮੀਟਰ ਚੌੜੀ ਪੱਟੀ (1.5 ਮੀਟਰ ਬੂਟੇ ਦੀ ਕਾਤਰ ਦੇ ਦੋਨੋ ਪਾਸੇ)" )}
          {renderRow("6th", 280 ,610, "3 ਮੀਟਰ ਚੌੜੀ ਪੱਟੀ (1.5 ਮੀਟਰ ਬੂਟੇ ਦੀ ਕਾਤਰ ਦੇ ਦੋਨੋ ਪਾਸੇ)")}

        </table>
      </div>
    </div>
  );
}

export default NitrogenCalculator;
