import React from "react";

import { useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./RoughEstimate.css";

function RoughEstimate(props) {
  const [name, setName] = React.useState("");
  const [ownerships, setOwnerships] = React.useState(0);
  const [date, setDate] = useState(new Date());
  const [dateAfter, setDateAfter] = useState(new Date());
  const actionchangeName = (e) => {
    // do cool stuff here!
    let someKilla = e.target.value;
    console.log("killa value ", someKilla);
    setName(someKilla);
  };

  const [lenghtInput, setLenthInput] = useState(0);
  const [lenghtInputAfterAdd, setLenghtInputAfterAdd] = useState("");

  const getLenthInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setLenthInput(someKilla);

    var result = new Date(date);
    result.setDate(result.getDate() + lenghtInput);
    setLenghtInputAfterAdd(result.toDateString());
  };
  function actionMarlaToSqFt(param) {
    return param * 9 * 5.5 * 5.5;
  }
  function actionBiswaToSqFt(param) {
    return param * 15 * 5.5 * 5.5;
  }
  function getCurretTopSqareFeet() {
    switch (OptionsOfLeftArea) {
      case "Killa":
        return actionBiswaToSqFt(areaLeftUpperSide * 96);

        break;

      case "Bigha":
        return actionBiswaToSqFt(areaLeftUpperSide * 20.0);

        break;

      case "Kanaal":
        return actionBiswaToSqFt(areaLeftUpperSide * 12.0);
        break;

      case "biswa":
        return actionBiswaToSqFt(areaLeftUpperSide);
        break;

      case "marla":
        return actionMarlaToSqFt(areaLeftUpperSide);
        break;

      case "Sarsahi":
        return actionMarlaToSqFt(areaLeftUpperSide / 9);
        break;

      case "Square_meter":
        return areaLeftUpperSide * 10.7639;
        break;

      case "Square_Feet":
        return areaLeftUpperSide;
        break;

      case "Hectare":
        return areaLeftUpperSide * 107639;
        break;

      default:
        return areaLeftUpperSide * 1;
        break;
    }
  }

  function getCurretBottomSqareFeet() {
    switch (OptionsOfLeftAreaBottom) {
      case "Killa":
        return actionBiswaToSqFt(areaLeftBottomSide * 96);

        break;

      case "Bigha":
        return actionBiswaToSqFt(areaLeftBottomSide * 20.0);

        break;

      case "Kanaal":
        return actionBiswaToSqFt(areaLeftBottomSide * 12.0);
        break;

      case "biswa":
        return actionBiswaToSqFt(areaLeftBottomSide);
        break;

      case "marla":
        return actionMarlaToSqFt(areaLeftBottomSide);
        break;

      case "Sarsahi":
        return actionMarlaToSqFt(areaLeftBottomSide / 9);
        break;

      case "Square_meter":
        return areaLeftBottomSide * 10.7639;
        break;

      case "Square_Feet":
        return areaLeftBottomSide;
        break;

      case "Hectare":
        return areaLeftBottomSide * 107639;
        break;

      default:
        return areaLeftBottomSide * 1;
        break;
    }
  }

  // const sqFt_hectare = hectareInput * 107639;

  // const sgFt_kanaal = actionBiswaToSqFt(kanaalInput * 12.0);

  // const sgFt_biswa = actionBiswaToSqFt(biswaInput);

  // const sgFt_marla = actionMarlaToSqFt(marlaInput);

  // const sgFt_sarsahi = actionMarlaToSqFt(sarsahiInput / 9);

  const [unitRightSide, setUnitRightSide] = useState("-");
  const get_unitChange = (e) => {
    setUnitRightSide(e.target.value);
  };

  const [rightHandSide, setRightHandSide] = useState(0);
  const get_rightHandSide = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setRightHandSide(someKilla);
  };

  const [areaLeftUpperSide, setAreaLeftUpperSide] = useState(0);
  const get_areaLeftUpperSide = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setAreaLeftUpperSide(someKilla);
  };

  const [convertedSqt, setConvertedSqt] = useState(0);
  const [OptionsOfLeftArea, setOptionsOfLeftArea] = useState("Killa");
  const onChangeOptionsOfLeftArea = (value) => {
    // alert(value);
    setOptionsOfLeftArea(value);
    // <option value={"Killa"}>Killa</option>
    // <option value={"Bigha"}>Bigha</option>
    // <option value={"Kanaal"}>Kanaal</option>
    // <option value={"biswa"}>biswa</option>
    // <option value={"marla"}>marla</option>
    // <option value={"Sarsahi"}>Sarsahi</option>
    // <option value={"Square_meter"}> Square meter </option>
    // <option value={"Square_Feet"}> Square Feet</option>
    // <option value={"Hectare"}>Hectare </option>
    switch (value) {
      case "Killa":
        setConvertedSqt(actionBiswaToSqFt(areaLeftUpperSide * 96));

        break;

      case "Bigha":
        setConvertedSqt(actionBiswaToSqFt(areaLeftUpperSide * 20.0));
        break;

      case "Kanaal":
        setConvertedSqt(actionBiswaToSqFt(areaLeftUpperSide * 12.0));
        break;

      case "biswa":
        setConvertedSqt(actionBiswaToSqFt(areaLeftUpperSide));
        break;

      case "marla":
        setConvertedSqt(actionMarlaToSqFt(areaLeftUpperSide));
        break;

      case "Sarsahi":
        setConvertedSqt(actionMarlaToSqFt(areaLeftUpperSide / 9));
        break;

      case "Square_meter":
        setConvertedSqt(areaLeftUpperSide * 10.7639);
        break;

      case "Square_Feet":
        setConvertedSqt(areaLeftUpperSide);
        break;

      case "Hectare":
        setConvertedSqt(areaLeftUpperSide * 107639);
        break;

      default:
        setConvertedSqt(areaLeftUpperSide * 2);
        break;
    }
  };
  const [convertedSqtBelow, setConvertedSqtBelow] = useState(0);
  const [areaLeftBottomSide, setAreaLeftBottomSide] = useState(0);
  const get_areaLeftBottomSide = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setAreaLeftBottomSide(someKilla);
  };

  const [OptionsOfLeftAreaBottom, setOptionsOfLeftAreaBottom] =
    useState("Killa");
  const handleChangeField = (value) => {
    setOptionsOfLeftAreaBottom(value);

    switch (value) {
      case "Killa":
        setConvertedSqtBelow(actionBiswaToSqFt(areaLeftUpperSide * 96));

        break;

      case "Bigha":
        setConvertedSqtBelow(actionBiswaToSqFt(areaLeftUpperSide * 20.0));
        break;

      case "Kanaal":
        setConvertedSqtBelow(actionBiswaToSqFt(areaLeftUpperSide * 12.0));
        break;

      case "biswa":
        setConvertedSqtBelow(actionBiswaToSqFt(areaLeftUpperSide));
        break;

      case "marla":
        setConvertedSqtBelow(actionMarlaToSqFt(areaLeftUpperSide));
        break;

      case "Sarsahi":
        setConvertedSqtBelow(actionMarlaToSqFt(areaLeftUpperSide / 9));
        break;

      case "Square_meter":
        setConvertedSqtBelow(areaLeftUpperSide * 10.7639);
        break;

      case "Square_Feet":
        setConvertedSqtBelow(areaLeftUpperSide);
        break;

      case "Hectare":
        setConvertedSqtBelow(areaLeftUpperSide * 107639);
        break;

      default:
        setConvertedSqtBelow(areaLeftUpperSide * 2);
        break;
    }
  };

  function twoDaysDifference() {
    const diffTime = Math.abs(dateAfter - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  return (
    <>
      <div className="RoughCalculateBackgrondPlus">
        <h1> Rough estimate of units on field </h1>

        <table>
          <tr>
            <td colSpan={3}> Add the unit of Right hand side </td>

            <td>
              <input
                type="text"
                className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_waterInput"
                id="colFormLabel"
                placeholder="Add the unit of Right hand side  "
                onChange={get_unitChange}
                value={unitRightSide}
              />
            </td>
          </tr>
          <tr>
            {" "}
            <td colSpan={4}>if</td>
          </tr>
          <tr>
            <td>
              <input
                type="number"
                className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_waterInput"
                id="colFormLabel"
                placeholder="Type required area "
                onChange={get_areaLeftUpperSide}
                value={areaLeftUpperSide}
              />
            
            </td>
            <td> 
               <select
                onChange={(e) => onChangeOptionsOfLeftArea(e.target.value)}
              >
                <option value={"Killa"}>Killa</option>
                <option value={"Bigha"}>Bigha</option>
                <option value={"Kanaal"}>Kanaal</option>
                <option value={"biswa"}>biswa</option>
                <option value={"marla"}>marla</option>
                <option value={"Sarsahi"}>Sarsahi</option>
                <option value={"Square_meter"}> Square meter </option>
                <option value={"Square_Feet"}> Square Feet</option>
                <option value={"Hectare"}>Hectare </option>
              </select></td>
            <td>needs</td>
            <td>
              <input
                type="number"
                className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_waterInput"
                id="colFormLabel"
                placeholder="amount on right side value"
                onChange={get_rightHandSide}
                value={rightHandSide}
              />

              {unitRightSide}
            </td>
          </tr>
          <tr>
            {" "}
            <td colSpan={4}>then</td>
          </tr>

          <tr>
            <td>
              <input
                type="number"
                className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_waterInput"
                id="colFormLabel"
                placeholder="Amount of water in Liters"
                onChange={get_areaLeftBottomSide}
                value={areaLeftBottomSide}
              />

            </td>
            <td>
              <select onChange={(e) => handleChangeField(e.target.value)}>
                <option value={"Killa"}>Killa</option>
                <option value={"Bigha"}>Bigha</option>
                <option value={"Kanaal"}>Kanaal</option>
                <option value={"biswa"}>biswa</option>
                <option value={"marla"}>marla</option>
                <option value={"Sarsahi"}>Sarsahi</option>
                <option value={"Square_meter"}> Square meter </option>
                <option value={"Square_Feet"}> Square Feet</option>
                <option value={"Hectare"}>Hectare </option>
              </select></td>
            <td> needs </td>
            <td>  {(rightHandSide / getCurretTopSqareFeet()) *
                getCurretBottomSqareFeet()}{" "}
              {unitRightSide}{" "}</td>
          </tr>

        
       

          {/* <tr>
            <td colSpan={2}>
              {" "}
              {getCurretTopSqareFeet()} {"  "} square feet or (
              {areaLeftUpperSide} {OptionsOfLeftArea}) needs {rightHandSide}{" "}
              {unitRightSide}{" "}
            </td>
          </tr> */}

          {/* <tr>
            <td colSpan={2}>
              1 square foot needs {rightHandSide / getCurretTopSqareFeet()}{" "}
              {unitRightSide}{" "}
            </td>
          </tr>
          <tr>
            <td>
              here come second row {areaLeftBottomSide}{" "}
              {OptionsOfLeftAreaBottom} or {getCurretBottomSqareFeet()} square
              feet
            </td>
            <td> needs </td>
            <td>
              {(rightHandSide / getCurretTopSqareFeet()) *
                getCurretBottomSqareFeet()}{" "}
              {unitRightSide}{" "}
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              1 square foot needs {rightHandSide / getCurretTopSqareFeet()}{" "}
              {unitRightSide}{" "}
            </td>
          </tr> */}
        </table>
      </div>
    </>
  );
}

export default RoughEstimate;
