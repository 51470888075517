import React, { useState ,useEffect } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import BillViewContoller from "./BillViewContoller";

import "./OrdersDetail.css";
const apiUrl = `${process.env["REACT_APP_API_URL"]}bill/`;
const apiUrlOrders = `${process.env["REACT_APP_API_URL"]}order/`;
const OrdersDetail = (userData) => {
  const [allBills, setAllBills] = useState(null);
  useEffect(() => {
    getAllBills();
  }, []);

  const toolTipsPopover = (title) => (
    <Popover
      className="d-inline-block"
      id="popover-trigger-hover-focus"
      title="rrr"
    >
      <div className="standardPading">
        <strong>{title}</strong>
      </div>
    </Popover>
  );
  const handleAddRow = async (event) => {
    event.preventDefault();
    try {
      await handleSubmit();

      //   await getClones(); // Reload the listing after adding a new clone
    } catch (error) {
      console.error("Error adding clone:", error);
    }

    // const isFormValid = validateForm();
    // if (isFormValid) {
    //   try {
    //     await handleSubmit();

    //     //   await getClones(); // Reload the listing after adding a new clone
    //   } catch (error) {
    //     console.error("Error adding clone:", error);
    //   }
    // } else {
    //   // Form is not valid, display error message
    //   console.log(
    //     "Form submission failed. Please fill in all required fields."
    //   );
    // }
  };
  const handleSubmit = async () => {
    await axios
      .post(`${apiUrl}createUpdate`, { ofOrder: userData.userData.haveOder })
      .then(function (response) {
        // navigate("/listing");
        if (response.data.status) {
          getAllBills();

          //   callback(response.data.item);
          //   let item = response.data.item;
          //   item = {
          //     ...item,
          //     isEditing: false, // Initially set to false
          //     isDeletedTapped: false,
          //     fromAddCustomer: formUserData.fromAddCustomer,
          //     temporary: {},
          //   };
          //   callback(item);

          //   navigate("/listing");
          // window.page.reload();
        } else {
          alert("Something went Wrong");
          // window.page.reload();
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const getAllBills = async () => {
    await axios
      .post(`${apiUrlOrders}getAllBills`, {
        orderID: userData.userData.haveOder,
      })
      .then(function (response) {
        //   callback(response.data.item);
        // navigate("/listing");
       
        if (response.data.status) {
          setAllBills(response.data.data);

          //   callback(response.data.item);
          //   let item = response.data.item;
          //   item = {
          //     ...item,
          //     isEditing: false, // Initially set to false
          //     isDeletedTapped: false,
          //     fromAddCustomer: formUserData.fromAddCustomer,
          //     temporary: {},
          //   };
          //   callback(item);

          //   navigate("/listing");
          // window.page.reload();
        } else {
          alert("Something went Wrong");
          // window.page.reload();
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  return (
    <div>
      <h2>Order Details</h2>
      {JSON.stringify(userData.userData.haveOder)}
      <Form>
        <Form.Group as={Col} controlId="formGridEmdail">
          <OverlayTrigger
            trigger={["hover", "focus"]}
            splacement="top"
            overlay={toolTipsPopover("Add new Bill")}
          >
            <button className="btn-sm" onClick={handleAddRow}>
              <i className="bi bi-plus-square"></i>
            </button>
          </OverlayTrigger>
        </Form.Group>
      </Form>
       
       { allBills ? (

        <>
{allBills.map((row, index) => (
    <BillViewContoller index={index} bill={row} />

))}
        
        
        
        </>
        
        
   
       
       
       
       
       )
       :(
<></>
       )
        
       }
       
    </div>
  );
};

// export default Admin;
export default OrdersDetail;
