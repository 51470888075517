import "./VolumeCalculator_Liter.css";
import React, { useState, useEffect } from "react";


function VolumeCalculator_Liter() {
  const [selectedTab, setSelectedTab] = useState(93);

  const get_Number_pumps = () => {
    switch (selectedTab) {
      case 91:
        return waterVolume / 15;
      case 92:
        return waterVolume / 20;
      case 93:
        return waterVolume / 25;
    }
  };

  const get_perPumpLiquidfromTotal = () => {
    switch (selectedTab) {
      case 91:
        return result_totalLiquid_ml() / 15;
      case 92:
        return result_totalLiquid_ml() / 20;
      case 93:
        return result_totalLiquid_ml() / 25;
    }
  };

  const actionSelectButton = (e) => {
    setSelectedTab(e);
  };
  const selectedStyle = (e) => {
    if (e == selectedTab) {
      return " buttonPumpsCapacity buttonPumpsCapacityOn";
    }

    return " buttonPumpsCapacity ";
  };

  // ----------------------------------------
  const [waterVolume, setWaterVolume] = useState(200);
  const get_waterVolume = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setWaterVolume(someKilla);
  };

  // ----------------------------------------
  const [liquidVolume, setLiquidVolume] = useState(23);
  const get_liquidVolume = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    setLiquidVolume(someKilla);
  };

  const result_totalLiquid_ml = () => {
    return liquidVolume * waterVolume;
  };
  const result_totalLiquid_l = () => {
    return result_totalLiquid_ml() / 1000;
  };

  return (
    <>
      <div className="">
 

        <div className="div_SubContainer">
          <h1 className="volume_HeadingCenter"> Volume calculator  based on liter </h1>

          <div>
            <p>
              The below calculations are used to calculate the total amount of
              liquid used for given amount of water and number of pumps and
              liquid per pump.
            </p>

            <table>
              <tr className="tr_LiquidP">
                <th colSpan={2} className="volume_HeadingCenter">
                  {" "}
                  Recommended
                  <span className="volume_Liquid"> Liquid </span> by company in
                  ml (Milli-liters) for 1 liter water.
                </th>
              </tr>
              <tr>
                <td className="volumeWidth volume_HeadingCenter"> 1 liter</td>
                <td>
                  <input
                    type="number"
                    className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_LiquidInput"
                    id="colFormLabel"
                    placeholder="Liquid in ml"
                    onChange={get_liquidVolume}
                    value={liquidVolume}
                    // ref={hectareThis}
                  />{" "}
                  <span className="volume_Liquid"> ml (milli-liters) </span>
                </td>
              </tr>
              <tr className="tr_LiquidP">
                <th>
                  {" "}
                  Number of liters of{" "}
                  <span className="volume_waterBasic"> Water </span> you need in
                  liters (** Multipler of 15,20,25){" "}
                </th>
                <th>
                  {" "}
                  Total amount <span className="volume_Liquid">
                    {" "}
                    Liquid
                  </span>{" "}
                  used.{" "}
                </th>
              </tr>

              <tr>
                <td>
                  <input
                    type="number"
                    className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_waterInput"
                    id="colFormLabel"
                    placeholder="Amount of water in Liters"
                    onChange={get_waterVolume}
                    value={waterVolume}
                  />{" "}
                  <span className="volume_waterBasic"> Liters (water ) </span>
                </td>
                <td className="volume_Liquid_result">
                  <span> {result_totalLiquid_ml()}</span> ml (milli-liters){" "}
                  <br />
                  <span> {result_totalLiquid_l()}</span> liters <br />
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr className="tr_otherPumps">
                <th colSpan={2} className="volume_HeadingCenter tr_otherPumps ">
                  Calculate number of pumps , liquid per pump based on above
                  calculations. Select below options
                </th>
              </tr>
              <tr className="tr_otherPumps">
                <td colSpan={2} className="tr_otherPumps">
                  <div>
                    {" "}
                    <div className="containerbuttonN">
                      <button
                        type="button"
                        // className="buttonPumpsCapacity"
                        className={selectedStyle(91)}
                        onClick={() => actionSelectButton(91)}
                      >
                        15 Liter Pump
                      </button>

                      <button
                        type="button"
                        // className="buttonPumpsCapacity"
                        className={selectedStyle(92)}
                        onClick={() => actionSelectButton(92)}
                      >
                        20 Liter Pump
                      </button>

                      <button
                        type="button"
                        // className="buttonPumpsCapacity volume_PumpSelected"
                        className={selectedStyle(93)}
                        onClick={() => actionSelectButton(93)}
                      >
                        25 liter Pump
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="tr_otherPumps">
                <td className="volume_HeadingCenter tr_otherPumps ">
                  {" "}
                  Number of pumps
                </td>
                <td className="volume_Pump tr_otherPumps">
                  {" "}
                  <b> {get_Number_pumps()}</b> pumps
                </td>
              </tr>
              <tr className="tr_otherPumps">
                <td className="volume_HeadingCenter tr_otherPumps ">
                  {" "}
                  ml Per pump <span className="volume_Liquid">liquid </span>
                </td>
                <td className="volume_Liquid_result tr_otherPumps ">
                  {" "}
                  <b> {get_perPumpLiquidfromTotal()}</b> ml
                </td>
              </tr>
            </table>

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VolumeCalculator_Liter;
