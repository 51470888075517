import React from "react";
import "./Fard.css";

import { useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./DaysCalculator.css";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

function DaysCalculator(props) {
  const [name, setName] = React.useState("");
  const [ownerships, setOwnerships] = React.useState(0);
  const [date, setDate] = useState(new Date());
  const [dateAfter, setDateAfter] = useState(new Date());
  const actionchangeName = (e) => {
    // do cool stuff here!
    let someKilla = e.target.value;
    console.log("killa value ", someKilla);
    setName(someKilla);
  };

  const [lenghtInput, setLenthInput] = useState(0);
  const [lenghtInputAfterAdd, setLenghtInputAfterAdd] = useState("");


 
  const getLenthInput = (e) => {
    // do cool stuff here!
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    console.log("killa value ", someKilla);
    setLenthInput(someKilla);

    
    var result = new Date(date);
    result.setDate(result.getDate() + lenghtInput);
    setLenghtInputAfterAdd(result.toDateString())

  };

  function lenghtInputAfterAddFunc() {

      
    var result = new Date(date);
    result.setDate(result.getDate() + lenghtInput);
    

    return result.toDateString()
  }

  function twoDaysDifference() {

   
    const diffTime = Math.abs(dateAfter - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
   
    

    return diffDays

  }


  return (
    <>
     <div>
     <table>
  <tr>
    <th> Select the Date </th>
    <th><DatePicker selected={date} onChange={(date) => 
      
      
      setDate(date)} /></th>
  </tr>
  <tr>
    <td>Add days in dasys</td>
    <td><input type="number"     onChange={getLenthInput}  /></td>
  </tr>
  <tr>
    <td>After <b>{lenghtInput} days</b> , date is </td>
    <td> {lenghtInputAfterAddFunc()}</td>
  </tr>


  <tr>
    <td colSpan={2}> Days between two days</td>
  
  </tr>

  <tr>
    <td colSpan={2}> </td>
  
  </tr>
  <tr>
    <td colSpan={2}></td>
  
  </tr>

  <tr>
    <td>Add second date </td>
    <th><DatePicker selected={dateAfter} onChange={(dateAfter) => 
      
      
      setDateAfter(dateAfter)} /></th>
  </tr>

  <tr>
    <td>Days diffence between two days <b>{dateAfter.toDateString()}</b>  to  <b>{date.toDateString()}</b></td>
    <th>{twoDaysDifference()}</th>
  </tr>

  

</table>
     
      
    </div>






    </>
  );
}


export default DaysCalculator;
