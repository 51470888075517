// import logo from './logo.svg';

import NavBarRajiFarm from "./components/NavBarRajiFarm.js";
import FooterRF from "./FooterRF.js";
import "./App.css";


import React, { useState  } from "react";
import { BrowserRouter    } from "react-router-dom";
function App() {
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('isLoggedInRF') ? localStorage.getItem('isLoggedInRF') : false);

  return (
    <>
    <div className="App">
    <BrowserRouter >
     <NavBarRajiFarm isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />
     <FooterRF/>
    </BrowserRouter>

    </div>
 
     
    </>

  );
}

export default App;
