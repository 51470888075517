import React from "react";
import { Link, Route, Routes } from "react-router-dom";

import { useState, useEffect, useRef } from "react";

import Calculator1 from "./Calculator1.js";
import CalculatorInfo from "./CalculatorInfo.js";

import MoneyCalculator from "./MoneyCalculator.js";
import LenghtCalculator from "./LenghtCalculator.js";
import SellYourTrees from "./SellYourTrees.js";
import DaysCalculator from "./DaysCalculator.js";

import CurrentLocationRajiFarm from "./CurrentLocationRajiFarm.js";
import CounterApp from "./CounterApp.js";

import { RFarm_Title_2, RFarm_Title, RFarm_Title_2Com } from "./GerenicView.js";
import "./NavBarRajiFarm.css";

import mainLogoWall from "../Assests/wallLogo.png";

import ContactUs from "./Contact.js";
import Home from "./Home.js";

import PlantCalulator from "./PlantCalulator.js";
import RoleFERTILIZER from "./RoleFERTILIZER.js";
import FertilizerNutrientCalculator from "./FertilizerNutrientCalculator.js";

import MapCart from "./MapCart.js";
import Fard from "./Fard.js";
import LostVillagesOfPunjab from "./LostVillagesOfPunjab.js";


import PotatoDeficiency from "./PotatoDeficiency.js";

import VolumeCalculator from "./VolumeCalculator.js";
import WheatRiceCalculator from "./WheatRiceCalculator.js";

import RoughEstimate from "./RoughEstimate.js";

import Admin from "./Admin.js";
import Dashboard from "./Dashboardapp/Dashboard.js";
import PrivateRoute from "./PrivateRoute";

export default function NavBarRajiFarm({ isLoggedIn, setLoggedIn }) {
  var navbar_height = 0;

  useEffect(() => {
    // subscribe event
    window.addEventListener("scroll", function () {
      if (window.scrollY > 50) {
        document.getElementById("navbar_top").classList.add("fixed-top");
        // add padding top to show content behind navbar
        navbar_height = document.querySelector(".navbar").offsetHeight;
        document.body.style.paddingTop = navbar_height + "px";
      } else {
        document.getElementById("navbar_top").classList.remove("fixed-top");
        // remove padding top from body
        document.body.style.paddingTop = "0";
      }
    });
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    // Handle login logic here

    setLoggedIn(false);
    localStorage.removeItem("isLoggedInRF");
  };

  return (
    <>
      <nav
        id="navbar_top"
        className="navbar navbar-expand-lg navbar-light bg-light"
        styleName="width: 900px; "
      >
        <div className="container-fluid">
          <img
            src={mainLogoWall}
            alt="alt text"
            className="float: left imageWall"
          />

          <Link className="navbar-brand" to="/">
            <RFarm_Title_2Com />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Blogs
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/RoleFERTILIZER">
                      Role of Fertilzer
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/Fard">
                      Fard
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/LostVillagesOfPunjab">
                    Lost Villages of Punjab
                    </Link>
                  </li>


                  {/* <li>
                    <Link className="dropdown-item" to="/LenghtCalculator">
                      Lenght Calculator
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/calculatorInfo">
                      Area Calculator Info
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/MoneyCalculator">
                      Money Calculator
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="/PlantCalulator">
                        Poplar Plant Calulator
                    </a> */}
                  {/* </li> */}
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Crops
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/PotatoDeficiency">
                      Potato
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Calculator
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/calculator">
                      Area Calculator
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/LenghtCalculator">
                      Lenght Calculator
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/calculatorInfo">
                      Area Calculator Info
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/DaysCalculator">
                      Day Calulator
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/RoughEstimate">
                      Rough Field - unit Estimator
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/MoneyCalculator">
                      Money Calculator
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/PlantCalulator">
                      Poplar Plant Calulator
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/FertilizerNutrientCalculator"
                    >
                      Fertilizer-Nutrient Calculator
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/VolumeCalculator">
                      Volume Calculator
                    </Link>
                  </li>
                  <hr className="dropdown-divider" />
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/CurrentLocationRajiFarm"
                    >
                      Current Location App
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Apps
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/CounterApp">
                      Counter app
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="/LenghtCalculator">
                      Lenght Calculator
                    </Link>
                  </li> */}

                  {/* <li>
                    <Link className="dropdown-item" to="/calculatorInfo">
                      Area Calculator Info
                    </Link>
                  </li> */}
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/contactus">
                  Contact Us
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/SellYourTrees">
                  Sell Poplar trees
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/MapCart">
                  Map Cart
                </Link>
              </li>

              {!isLoggedIn && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/Admin">
                      Admin
                    </Link>
                  </li>
                </>
              )}

              {isLoggedIn && (
                <>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Console
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                  


                      <li>
                    <Link className="dropdown-item" to="/Dashboard">
                    Dashboard
                    </Link>
                  </li>


                      <li  className="dropdown-item"  onClick={handleLogout}> Logout</li>

                      {/* <li>
                    <Link className="dropdown-item" to="/calculatorInfo">
                      Area Calculator Info
                    </Link>
                  </li> */}
                    </ul>
                  </li>
                </>
              )}

              {/* <li className="nav-item">
          <a className="nav-link disabled">Disabled</a>
        </li> */}
            </ul>
            {/* <form className="d-flex">
        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button className="btn btn-outline-success" type="submit">Search</button>
      </form> */}
          </div>
        </div>
      </nav>

      <div>
        <Routes>
          <Route exact path="/calculator" element={<Calculator1 />} />
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route exact path="/calculatorInfo" element={<CalculatorInfo />} />
          <Route
            exact
            path="/LenghtCalculator"
            element={<LenghtCalculator />}
          />
          <Route exact path="/PlantCalulator" element={<PlantCalulator />} />
          <Route
            exact
            path="/VolumeCalculator"
            element={<VolumeCalculator />}
          />
          <Route
            exact
            path="/WheatRiceCalculator"
            element={<WheatRiceCalculator />}
          />

          <Route exact path="/RoleFERTILIZER" element={<RoleFERTILIZER />} />

          <Route exact path="/Fard" element={<Fard />} />
          <Route exact path="/LostVillagesOfPunjab" element={<LostVillagesOfPunjab />} />
          

          <Route exact path="/CounterApp" element={<CounterApp />} />

          <Route
            exact
            path="/PotatoDeficiency"
            element={<PotatoDeficiency />}
          />

          <Route exact path="/DaysCalculator" element={<DaysCalculator />} />

          <Route
            exact
            path="/FertilizerNutrientCalculator"
            element={<FertilizerNutrientCalculator />}
          />

          <Route exact path="/MoneyCalculator" element={<MoneyCalculator />} />
          <Route exact path="/SellYourTrees" element={<SellYourTrees />} />

          <Route exact path="/MapCart" element={<MapCart />} />

          <Route exact path="/" element={<Home />} />

          <Route exact path="/RoughEstimate" element={<RoughEstimate />} />

          <Route
            exact
            path="/CurrentLocationRajiFarm"
            element={<CurrentLocationRajiFarm />}
          />

          <Route
            exact
            path="/Admin"
            element={<Admin setLoggedIn={setLoggedIn} />}
          />

          <Route
            exact
            path="/Dashboard"
            element={isLoggedIn ? <Dashboard /> : <Home />}
          />
        </Routes>
      </div>
    </>
  );
}
