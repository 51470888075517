import React, { useState } from "react";
import "./MapCelMain.css";

function MapCelMain(props) {
  const handleChangeDistict = (value) => {
    switch (value) {
      case "amritsar":
        return "mapCellBackGroundAmritsar";
        break;
      case "bathinda":
        return "mapCellBackGroundBathinda";
        break;
      case "barnala":
        return "mapCellBackGroundBarnala";
        break;
      case "Faridkot":
        return "mapCellBackGroundFaridkot";
        break;
      case "Fatehgarh Sahib":
        return "mapCellBackGroundFatehgarh";
        break;
      case "fazilka":
        return "mapCellBackGroundfazilka";
        break;
      case "Ferozepur":
        return "mapCellBackGroundFerozepur";
        break;
      case "Gurdaspur":
        return "mapCellBackGroundGurdaspur";
        break;
      case "Hoshiarpur":
        return "mapCellBackGroundHoshiarpur";
        break;
      case "Jalandhar":
        return "mapCellBackGroundJalandhar";
        break;
      case "Kapurthala":
        return "mapCellBackGroundKapurthala";
        break;

      case "Ludhiana":
        return "mapCellBackGroundLudhiana";
        break;
      case "Mansa":
        return "mapCellBackGroundMansa";
        break;
      case "Moga":
        return "mapCellBackGroundMoga";
        break;
      case "SahibzadaAjitSinghNagar":
        return "mapCellBackGroundSahibzadaAjitSinghNagar";
        break;
      case "Muktsar":
        return "mapCellBackGroundMuktsar";
        break;
      case "Pathankot":
        return "mapCellBackGroundPathankot";
        break;
      case "Patiala":
        return "mapCellBackGroundPatiala";
        break;
      case "Rupnagar":
        return "mapCellBackGroundRupnagar";
        break;
      case "Sangrur":
        return "mapCellBackGroundSangrur";
        break;
      case "ShahidBhagatSinghNagar":
        return "mapCellBackGroundShahidBhagatSinghNagar";
        break;
      case "TarnTaran":
        return "mapCellBackGroundTarnTaran";
        break;

      default:
        return "mapCellBackGroundAmritsar";
        break;
    }
  };

  const Highlighted = ({ text = "", highlight = "" }) => {
    if (!highlight?.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return (
      <span>
        {parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <mark style={{ color: "red" }} key={i}>
                <b>{part}</b>
              </mark>
            ) : (
              <span key={i}>{part}</span>
            )
          )}
      </span>
    );
  };
  const loadArray = (item) => {
  

  };

  return (
    <>
    <tr className="trCity">
    <td> 
    <a href= {"https://www.google.com/maps/place/" + props.item.lat + "," + props.item.lng} target="_blank" >
      
    <Highlighted
          text={props.item.name}
          highlight={props.searchedtext && props.searchedtext}
        />
      
       </a>

    
    </td>
    <td>  {props.item.otherInfo} </td>
    <td>{props.item.districtNameType}</td>
 
    {/* <td>  {props.item.formattedAddress}</td> */}
  </tr>

    </>
  );
}

export default MapCelMain;
