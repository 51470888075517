import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// , collection, getDocs 
const firebaseConfig = {
      apiKey: "AIzaSyDjz5hr-6fDOgG77VVspwSv-qpY3kt-Ft8",
      authDomain: "rajifarm-14cb3.firebaseapp.com",
      projectId: "rajifarm-14cb3",
      storageBucket: "rajifarm-14cb3.appspot.com",
      messagingSenderId: "136850468211",
      appId: "1:136850468211:web:14ecbbbf2cd8e0ebcce906"
    };

    const app = initializeApp(firebaseConfig);

    const fireDb =   getFirestore(app);




  export default fireDb;