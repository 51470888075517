// InvoiceForm.js

import React, { useState } from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import "./AddNewBill.css";
import "./iconsColor.css";

import NewBuyerDetails from "./NewBuyerDetails.js";
import BuyerDetails from "./BuyerDetails.js";
import OrdersDetail from "./OrdersDetail.js";



const AddNewBill = ({ formDataPass, backCallback }) => {
  const toolTipsPopover = (title) => (
    <Popover
      className="d-inline-block"
      id="popover-trigger-hover-focus"
      title="rrr"
    >
      <div className="standardPading">
        <strong>{title}</strong>
      </div>
    </Popover>
  );
  const [formData, setFormData] = useState(formDataPass ? formDataPass : null);
  const handleBackCallback = (index) => {
    backCallback();
  };


  return (
    <div>
      <div className="back-btn-tools-div">
        {formData?.fromAddCustomer ? (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={toolTipsPopover("Back to All customers")}
          >
            <button
              className="btnRF-back  btn-sm float-left "
              onClick={() => handleBackCallback()}
            >
              <i className="bi bi-arrow-left"></i>
              {/* {String(formData.fromAddCustomer)} */}
            </button>
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </div>
  
      <div id="invoice">
        <h1> Raji Agri Farm - Poplar Nursery Farm </h1>
        <h2>Invoice</h2>
        {formData && formData.isEditing == false ? (
          <div>
            <BuyerDetails
              formdata={formData}
              callback={(item) => {
                setFormData({
                  ...formData,
                  isEditing: true,
                  temporary: formData,
                });
              }}
            />
            {/* Add more UI elements as needed */}
          </div>
        ) : formData && formData.isEditing == true ? (
          <NewBuyerDetails
            formdata={formData}
            callback={(item) => {
              setFormData(item); // Setting the item into formData state
            }}
          />
        ) : (
          <NewBuyerDetails
            callback={(item) => {
              setFormData(item); // Setting the item into formData state
            }}
          />
        )}

     {formData  !== null && formData["haveOder"] !== undefined ? (
        <OrdersDetail userData={formData}/>
      ) : (
        <p>ID is not present in the object</p>
      )}


   
     

      
        <div>
          <h4 class="h4_heading">Extra notes</h4>
          <li> Transport [For] added in above item</li>
        </div>
        <hr />
        <div>
          1. Goods once sold will not be returned. <br />
          2. All Subject to ROPAR jurisdictions only E.&OE
        </div>
      </div>
    </div>
  );
};

export default AddNewBill;
