import React from "react";
import "./PagerClass.css";
import Carousel from "react-bootstrap/Carousel";
import { RFarm_Title_2 } from "./GerenicView";
// import wheatPoplar from "../Assests/poplartreesWheat.png";
function PagerClass(props) {
  // pauDifference.JPG
  // pauPoplar.JPG
  // pauZn1.JPG
  // pauFertilizerUrea.JPG
  // pauWheat.JPG
  // pauNutriient_N_Urea.JPG
  // pauZN.JPG

  var imagesFunc = () => {
    var images = [];
    images.push("award1.jpg");
    images.push("certficateAward1.jpg");
    images.push("picRF_news_n.jpg");
    images.push("picaward2.jpg");
    images.push("award3.jpeg");
    images.push("award4.jpeg");
    images.push("balachorNews.jpg");


    images.push("newsContent.jpeg");
    images.push("newsIqbal.jpeg");
    images.push("newsNominatino.jpeg");


    return images;
  };

  const getImage = (image) => {
    return (
      <div className="contentPager">
        <img
          className="l-block w-100 imageCustomThigs1"
          src={require(`../Assests/news/${image}`)}
          alt="First slide"
        />
      </div>
    );
  };

  return (
    <div className="superMain1">
      <h1>
        {" "}
        <RFarm_Title_2 /> in news
      </h1>

      <div className="subMain">
      

        <div className="mainContinerPau1">
          <Carousel variant="dark">
            {imagesFunc().map((item) => (
              <Carousel.Item>{getImage(item)}</Carousel.Item>
            ))}
          </Carousel>
          
        </div>

        
        <div className="bg-imagee"></div>
      </div>
    </div>
  );
}

export default PagerClass;

// <Carousel.Item>
// <img
//   className="l-block w-100 imageCustomThigs"
//   src={wheatPoplar}
//   alt="Second slide"
// />
// <Carousel.Caption>
//   <h5>Second slide label</h5>
//   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
// </Carousel.Caption>
// </Carousel.Item>
// <Carousel.Item>
// <img
//   className="l-block w-100 imageCustomThigs"
//   src={wheatPoplar}
//   alt="Third slide"
// />
// <Carousel.Caption>
//   <h5>Third slide label</h5>
//   <p>
//     Praesent commodo cursus magna, vel scelerisque nisl consectetur.
//   </p>
// </Carousel.Caption>
// </Carousel.Item>
