import React from "react";
import "./LostVillagesOfPunjab.css";

function LostVillagesOfPunjab() {
  // Introduction about the villages
  const introText = `The city of Chandigarh, often referred to as the best-planned city in India, came into existence in the early 1950s.
  However, during its construction, several villages were displaced to make way for the new urban layout.
  This table lists the names of these villages, their original Punjabi names, their English translations, and the sectors in Chandigarh where they once stood.`;

  // Data for displaced villages
  const displacedVillages = [
    { id: 1, punjabiName: "ਬਜਵਾੜੀ", englishName: "Bajwari", sector: "Sector 23" },
    { id: 2, punjabiName: "ਦਲਹੇੜੀ ਜੱਟਾ", englishName: "Dalheri Jatta", sector: "Sector 28" },
    { id: 3, punjabiName: "ਦਲਹੇੜੀ", englishName: "Dalheri", sector: "Sector 19" },
    { id: 4, punjabiName: "ਗੁਰਦਾਸਪੁਰਾ", englishName: "Gurdaspura", sector: "Sector 28 (Industrial Area)" },
    { id: 5, punjabiName: "ਹਮੀਰਗੜ ( ਕੰਚਨਪੁਰ )", englishName: "Hamirgarh (Kanchanpur)", sector: "Sectors 7-26" },
    { id: 6, punjabiName: "ਕਾਲੀਬੜ", englishName: "Kalibar", sector: "Sectors 4-5-8-9" },
    { id: 7, punjabiName: "ਕੈਲੜ", englishName: "Kaler", sector: "Sectors 15-16-24" },
    { id: 8, punjabiName: "ਕਾਂਜੀ ਮਾਜਰਾ", englishName: "Kanjimajra", sector: "Sector 14 (Punjab University)" },
    { id: 9, punjabiName: "ਖੇੜੀ", englishName: "Kheri", sector: "Sectors 20-30-32" },
    { id: 10, punjabiName: "ਮਹਿਲਾ ਮਾਜਰਾ", englishName: "Mahila Majra", sector: "Sectors 2-3" },
    { id: 11, punjabiName: "ਨਗਲਾ", englishName: "Nagla", sector: "Sector 27" },
    { id: 12, punjabiName: "ਰਾਮ ਨਗਰ ( ਭੰਗੀ ਮਾਜਰਾ )", englishName: "Ram Nagar (Bhangi Majra)", sector: "Sectors 6-7" },
    { id: 13, punjabiName: "ਰੁੜਕੀ", englishName: "Rurki", sector: "Sectors 17-18-21-22" },
    { id: 14, punjabiName: "ਸੈਣੀ ਮਾਜਰਾ", englishName: "Saini Majra", sector: "Sector 25" },
    { id: 15, punjabiName: "ਸਹਿਜਾਦਪੁਰ", englishName: "Sehjadpur", sector: "Sectors 11-12 (31–47)" },
    { id: 16, punjabiName: "ਬਜਵਾੜਾ", englishName: "Bajwara", sector: "Sectors 35-36" },
    { id: 17, punjabiName: "ਬਜਵਾੜੀ ਬਖਤਾ", englishName: "Bajwari Bakhta", sector: "Sector 37" },
    { id: 18, punjabiName: "ਫਤਿਹਗੜ ( ਮਾਦੜਾਂ )", englishName: "Fatehgarh (Madaran)", sector: "Sectors 33-34" },
    { id: 19, punjabiName: "ਗੱਗੜ ਮਾਜਰਾ", englishName: "Gaggar Majra", sector: "Airport Area" },
    { id: 20, punjabiName: "ਕੰਥਾਲਾ", englishName: "Kanthala", sector: "Sector 31 (Tribune Chowk)" },
    { id: 21, punjabiName: "ਜੈਪੁਰ", englishName: "Jaipur", sector: "Unknown Sector" },
    { id: 22, punjabiName: "ਸਲਾਹਪੁਰ", englishName: "Salahpur", sector: "Unknown Sector" },
    { id: 23, punjabiName: "ਦਤਾਰਪੁਰ", englishName: "Datarpur", sector: "Ram Darbar (Airport Area)" },
    { id: 24, punjabiName: "ਚੂਹੜਪੁਰ", englishName: "Chuharpur", sector: "Unknown Sector" },
    { id: 25, punjabiName: "ਕਰਮਾਣ", englishName: "Karman", sector: "Sector 29 (Industrial Area)" },
    { id: 26, punjabiName: "ਝੁਮਰੂ", englishName: "Jhumroo", sector: "Sectors 49-50" },
    { id: 27, punjabiName: "ਨਿਜਾਮਪੁਰ", englishName: "Nizampur", sector: "Sector 48" },
    { id: 28, punjabiName: "ਸਾਹਪੁਰ", englishName: "Sahapur", sector: "Sector 38" }
  ];

  // Data for partially displaced villages
  const partiallyDisplacedVillages = [
    { id: 1, punjabiName: "ਮਨੀ ਮਾਜਰਾ", englishName: "Mani Majra", sector: "Sector 13" },
    { id: 2, punjabiName: "ਧਨਾਸ", englishName: "Dhanas", sector: "Sector 14" },
    { id: 3, punjabiName: "ਮਲੋਆ, ਡੱਡੂ ਮਾਜਰਾ", englishName: "Maloa, Daddumajra", sector: "Sector 39" },
    { id: 4, punjabiName: "ਬਡਹੇੜੀ, ਬੁਟੇਰਲਾ", englishName: "Badheri, Buterla", sector: "Sector 41" },
    { id: 5, punjabiName: "ਅਟਾਵਾ", englishName: "Attawa", sector: "Sector 42" },
    { id: 6, punjabiName: "ਬੁੜੈਲ", englishName: "Burail", sector: "Sector 45" },
    { id: 7, punjabiName: "ਕਜਹੇੜੀ", englishName: "Kajheri", sector: "Sector 52" },
    { id: 8, punjabiName: "ਮਦਨਪੁਰ", englishName: "Madanpur", sector: "Sector 54" },
    { id: 9, punjabiName: "ਪਲਸੋਰਾ", englishName: "Palasora", sector: "Sector 55" }
  ];

  return (
    <>
      <h1>Lost Villages of Punjab</h1>
      <p>{introText}</p>

      <h2>Displaced Villages</h2>
      <table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Punjabi Name</th>
            <th>English Translation</th>
            <th>Sector/Area in Chandigarh</th>
          </tr>
        </thead>
        <tbody>
          {displacedVillages.map((village) => (
            <tr key={village.id}>
              <td>{village.id}</td>
              <td>{village.punjabiName}</td>
              <td>{village.englishName}</td>
              <td>{village.sector}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Partially Displaced Villages</h2>
      <table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Punjabi Name</th>
            <th>English Translation</th>
            <th>Sector in Chandigarh</th>
          </tr>
        </thead>
        <tbody>
          {partiallyDisplacedVillages.map((village) => (
            <tr key={village.id}>
              <td>{village.id}</td>
              <td>{village.punjabiName}</td>
              <td>{village.englishName}</td>
              <td>{village.sector}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default LostVillagesOfPunjab;
