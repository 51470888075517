import "./WheatRiceCalculator.css";
import React, { useState, useEffect } from "react";
// import  StyleKitName  from   "./StyleKitName.js";
function WheatRiceCalculator() {
  //   console.log("some thibngd 🍟🍟🍟ff🍟🍟🍟" )

  //   StyleKitName.drawCanvas1("mainCanvas")

  // ----------------------------------------
  const [perBagWeight, set_perBagWeight] = useState(37.500);
  const get_perBagWeight = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    set_perBagWeight(someKilla);
  };
  // ----------------------------------------
  const [perRuppes100kg, set_perRuppes100kg] = useState(2203);

  const get_perRuppes100kg = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    set_perRuppes100kg(someKilla);
  };
  const getRatePerKg = () => {
    return perRuppes100kg / 100;
  };

  // ----------------------------------------
  const [fieldCount, set_fieldCount] = useState(0);

  const get_fieldCount = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    set_fieldCount(someKilla);
  };
  // ----------------------------------------
  const [bagCount, set_bagCount] = useState(0);

  const get_set_bagCount = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    set_bagCount(someKilla);
  };

  // ----------------------------------------
  const [extraKg, set_extraKg] = useState(0);

  const get_extraKg = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    set_extraKg(someKilla);
  };

  // ----------------------------------------
  const [labourRate, set_labourRate] = useState(6.70);

  const get_labourRate = (e) => {
    let someKilla = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    set_labourRate(someKilla);
  };

  // ----------------------------------------
  const c_TotalKgInBags = () => {
    return perBagWeight * bagCount;
  };

  const c_TotalKgPlusExtaInBags = () => {
    return extraKg + c_TotalKgInBags();
  };
  const c_TotalBagPlusExtraRuppes = () => {
    return c_TotalKgPlusExtaInBags() * getRatePerKg();
  };

  const c_AvgYieldKg = () => {
    return c_TotalKgPlusExtaInBags() / fieldCount;
  };

  const c_AvgYieldRuppes = () => {
    return c_TotalBagPlusExtraRuppes() / fieldCount;
  };
  const c_LabourRate = () => {
    return bagCount * labourRate;
  };

  const c_LabourKgEstimate = () => {
    var oneRyppes = 100 / perRuppes100kg;
    return c_LabourRate() * oneRyppes;
  };

  return (
    <>
      <div className="WheatRiceCalculator">
        <div className="div_SubContainer_Rice">
          <h1 className="volume_HeadingCenter"> 🌾  Paddy-Wheat calculator  🌾</h1>

          <div>
            <div>
              <p>
                {" "}
                This Calculator is used to calculate total and Average of Paddy
                in kg and ₹ Ruppes
              </p>
            </div>
         
      
            <div>
              <table>
                <tr className="td_riceGovt">
                  <th className="td_riceGovt">
                    {" "}
                    Weight of Paddy crop in one Bag in kg by Govt{" "}
                  </th>
                  <td className="td_riceGovt">
                    {" "}

                    <span className="boldSize">    <input
                      type="number"
                      className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_LiquidInput"
                      id="colFormLabel"
                      placeholder=" Weight per bags by govt 𐄷"
                      onChange={get_perBagWeight}
                      value={perBagWeight}
                    // ref={hectareThis}
                    />{" "}
                      kg</span>
                  </td>
                </tr>

                <tr className="td_riceGovt">
                  <th className="td_riceGovt">
                    {" "}
                    Rate per Quntal (100 Kg) 💰 by Govt
                  </th>
                  <td className="td_riceGovt">

                    <span className="boldSize">    {"₹ "}
                      <input
                        type="number"
                        className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_LiquidInput"
                        id="colFormLabel"
                        placeholder="Rate per Quntal (100 Kg"
                        onChange={get_perRuppes100kg}
                        value={perRuppes100kg}
                      // ref={hectareThis}
                      />{" "} </span>


                  </td>
                </tr>
                <tr className="td_riceGovt">
                  <td className="td_riceGovt"> Rate per Kg </td>
                  <td className="td_riceGovt"> <span className="boldSize">  ₹ {getRatePerKg()}</span>  </td>
                </tr>

                <tr className="td_riceFarmer">
                  <td colSpan={2}> Farmer inputs</td>
                </tr>

                <tr className="td_riceFarmer">
                  <th className="td_riceFarmer"> Enter the Field Count </th>
                  <td className="td_riceFarmer">
                    {" "}
                    <input
                      type="number"
                      className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_LiquidInput"
                      id="colFormLabel"
                      placeholder="Liquid in ml"
                      onChange={get_fieldCount}
                      value={fieldCount}

                    // ref={hectareThis}
                    />{" "}
                    Acres
                  </td>
                </tr>

                <tr className="td_riceFarmer">
                  <th className="td_riceFarmer"> Enter the Bags Count </th>
                  <td className="td_riceFarmer">
                    {" "}
                    <input
                      type="number"
                      className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_LiquidInput"
                      id="colFormLabel"
                      placeholder="Liquid in ml"
                      onChange={get_set_bagCount}
                      value={bagCount}

                    // ref={hectareThis}
                    />{" "}
                  </td>
                </tr>

                <tr className="td_riceFarmer">
                  <th className="td_riceFarmer"> Enter the Extra kg </th>
                  <td className="td_riceFarmer">
                    {" "}


                    <span className="boldSize">   <input
                      type="number"
                      className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_LiquidInput"
                      id="colFormLabel"
                      placeholder="Liquid in ml"
                      onChange={get_extraKg}
                      value={extraKg}

                    // ref={hectareThis}
                    />{" "}
                      Kg</span>

                  </td>
                </tr>

                <tr className="td_riceGovt">
                  <td colSpan={2}> </td>
                </tr>
                <tr className="td_riceGovt">
                  <td colSpan={2}> Result </td>
                </tr>
                <tr className="td_riceGovt">
                  <td className="td_riceGovt"> Total Kgs in Bags </td>
                  <td className="td_riceGovt">  <span className="boldSize">{c_TotalKgInBags()} kg </span>  </td>
                </tr>

                <tr className="td_riceGovt">
                  <td className="td_riceGovt"> Total Weight in Bags + Exta </td>
                  <td className="td_riceGovt">
                    {" "}
                    <span className="boldSize">   {c_TotalKgPlusExtaInBags()} kgs{" "} </span>

                  </td>
                </tr>

                <tr className="td_riceGovt">
                  <td className="td_riceGovt"> Total ₹ Ruppes </td>
                  <td className="td_riceGovt">
                    {" "}  <span className="boldSize">    ₹ {c_TotalBagPlusExtraRuppes()}{" "} </span>

                  </td>
                </tr>
                
                <tr className="td_riceGovt">
                  <td className="td_riceGovt"> Total Weight in Bags + Exta IN Mann </td>
                  <td className="td_riceGovt">
                    {" "}  <span className="boldSize">    {c_TotalKgPlusExtaInBags()/40} mann {" "} </span>

                  </td> 
                </tr>

                <tr className="td_riceGovt">
                  <td colSpan={2}></td>
                </tr>

                <tr className="tdAvg">
                  <td className="tdAvg"> Average per acre (Kgs) </td>
                  <td className="tdAvg">  <span className="boldSize">{c_AvgYieldKg()} Kg </span>  </td>
                </tr>

                <tr className="tdAvg">
                  <td className="tdAvg"> Average per acre (Rupees ) </td>
                  <td className="tdAvg">  <span className="boldSize">  {c_AvgYieldRuppes()} ₹  </span></td>
                </tr>

                <tr className="tdAvg">
                  <td colSpan={2}></td>
                </tr>

                <tr className="td_riceLabour">
                  <td colSpan={2}> Labour Calulation</td>
                </tr>
                <tr className="td_riceLabour">
                  <td className="td_riceLabour"> Labour Rate per bag</td>
                  <td className="td_riceLabour">
                    <input
                      type="number"
                      className="form-control widthExtra inlineExtra  labelme inlineExtra inputVoulme volume_LiquidInput"
                      id="colFormLabel"
                      placeholder=" Weight per bags by govt 𐄷"
                      onChange={get_labourRate}
                      value={labourRate}
                    // ref={hectareThis}
                    />{" "}
                    ₹ Ruppes per bag
                  </td>
                </tr>

                <tr className="td_riceLabour">
                  <td className="td_riceLabour">
                    {" "}
                    Laobour Rate on  <b >{bagCount}  </b> bags{" "}
                  </td>
                  <td className="td_riceLabour"> <span className="boldSize">₹ {c_LabourRate()}   </span>  </td>
                </tr>

                <tr className="td_riceLabour">
                  <td className="td_riceLabour">
                    {" "}
                    Estimate kgs of Rice of Labor{" "}
                  </td>
                  <td className="td_riceLabour">  <span className="boldSize">{c_LabourKgEstimate()} Kg </span>   </td>
                </tr>
              </table>
            </div>
            <div>
              <table>
                <tr>
                  <th>
                    Year
                  </th>
                  <th>
                    Crop
                  </th>
                  <th>
                    Rate of Crop per Quntal
                  </th>
                  <th>
                    Bag Weight in Kg
                  </th>
                  <th>
                    Price of Labour
                  </th>
                </tr>

                <tr>
                  <td>
                    2022 -
                  </td>
                  <td>
                    Rice
                  </td>
                  <td>
                  Rs 2060 per Quantal
                  </td>
                  <td>
                    37.5 kg
                  </td>
                  <td>
                    6.5
                  </td>
                </tr>

                <tr>
                  <td>
                    2023 April
                  </td>
                  <td>
                    Wheat
                  </td>
                  <td>
                  Rs 2125 per Quantal
                  </td>
                  <td>
                    50 kg
                  </td>
                  <td>
                  
                  </td>
                </tr>

                <tr>
                  <td>
                    2023 - October 
                  </td>
                  <td>
                    Rice
                  </td>
                  <td>
                  Rs 2203 per Quantal
                  </td>
                  <td>
                    37.5 kg
                  </td>
                  <td>
                    6.70 Rs
                  </td>
                </tr>



              </table>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WheatRiceCalculator;
