import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const Admin = ({ setLoggedIn}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Username:', username);
    console.log('Password:', password);


    const staticUserId = 'virvijay37@gmail.com';
    const staticPassword = 'digitalFarm@37';

    // Validate the user ID and password
    if (username === staticUserId && password === staticPassword) {
      // Successful login
    setLoggedIn(true);
    localStorage.setItem('isLoggedInRF', true);
    navigate('/Dashboard');
    } else {
      setError('Incorrect user ID or password. Please try again.');
    }



    // You can add your login logic here, such as sending data to a backend server
  };
  
  return (
    <div>
      <h2>Admin Login</h2>
      <form>
  <div class="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email"
     class="form-control"
      id="exampleInputEmail1" 
      aria-describedby="emailHelp"
       placeholder="Enter email"
       value={username}
       onChange={(e) => setUsername(e.target.value)}
       />
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">  
    <label for="exampleInputPassword1">Password</label>
    <input type="password" 
    class="form-control" 
    id="exampleInputPassword1"
     placeholder="Password"
     value={password}
       onChange={(e) => setPassword(e.target.value)}
     />
  </div>
  <div class="form-group form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div>
  <button type="submit" onClick={handleSubmit} class="btn btn-primary">Submit</button>
</form>
{error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

// export default Admin;
export default Admin;