import React, { useState, useEffect } from "react";

import mainLogoWall from "../Assests/wallLogo.png";
import image_raji from "../Assests/picCont_Raji.jpg";
import image_ajay from "../Assests/picAjay.jpg";

const ImageSliderOwner = () => {
  const images = [
    mainLogoWall, // Replace with your image paths
    image_raji,
    image_ajay,
  ];
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3700); // Change image every 1 second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [images.length]);

  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={images[currentImageIndex]}
        alt="Slideshow"
        style={{
         padding : "10px",
          width: "150px",
          height: "150px",
          float: "left",
          borderRadius :"25px",
          transition: "opacity  5.5s ease-in-out",
        }}
      />
    </div>
  );
};

export default ImageSliderOwner;
