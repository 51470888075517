import React from "react";
import "./Workshop.css";

import wheatPoplar from "../Assests/poplartreesWheat.png";
import poptreeWintr from "../Assests/poptreeWintr.png";
import { TextBanner } from "./TextBanner.js";
import { ImageClass } from "./ImageClass.js";
import mainLogoWall from "../Assests/wallLogo.png";
import {
  RFarm_Title_2,
  RFarm_Title,
  RFarm_poplarTileTitle_2,

} from "./GerenicView.js";


{
  /* <ImageClass src={mainLogoWall} className="p_imageContainer_left"/> */
}

function Workshop() {
  const recommentation = () => {
    var elements = [];
    elements.push({
      title: "Poplar clones:",
      desc: " G-48,PL-I ,PL-2,PL-3,PL-4,PL-5, PL-6,PL-7, L-47/88 and L-48/89, W-22, WSL-39,A-26,OTHE, are recommended for commercial cultivation in central-plain region and L-48/89 in semi-arid region of Punjab.",
    });

    elements.push({
      title: "Spacing and geometry of trees:",
      desc: "Plant at 8 m x 2.5 m spacing with 8 m wider strip in north- south direction to minimise the negative effect of tree shade on intercrops.\n\n On the boundaries of the fields poplar should be planted at plant to plant spacing of 3 m, and on the water channels on both sides with a plant to plant spacing of 2 m. \n The standard spacing of trees of poplar in block plantation is 4 m x 4 m or 5 m x 4 m. However, spacing of poplar may be 10 m x 2 m (10 m row to row and 2 m plant to plant) in north-south direction or plant at 8 m x 2.5 m spacing with 8 m wide strips in north-south direction to minimize the adverse effects of tree shading on intercrops.\n Farmer can also take paired row i.e. 18 m m x 2 m (18 m row to row and 2 m plant to plant in paired rows).\n In wider row spacing, combine harvester can harvest wheat otherwise manual harvesting reduces the income of farmers. Some farmers grow poplar at narrow spacing and try to take more number of trees/acre, which adversely affects the crop yield.\n A proper tree spacing as per the standard recommendations should be followed. \n \n Poplar needs to be irrigated at frequent intervals during summer months. However, at maturity stage of intercrops farmers cannot apply irrigation, which adversely affects the growth of poplar trees. \nThis problem can be addressed by making bunds along poplar rows and irrigation can be applied in channels. Likewise farmer can also apply fertilizer through irrigation.",
    });

    elements.push({
      title: "Crop combinations:",
      desc: "Kharif season(cowpea, pearl millet, moong, mentha and arvi);Rabiseason(wheat, potato, barley, oats, berseem and mustard) and annual crops (sugarcane, turmeric and lemon grass) recommended as intercrops with poplar trees.",
    });

    elements.push({
      title: "Fertlizers application:",
      desc: "Fertilizer management is very important for the growth of poplar.\n Generally, farmers apply fertilizers only for inter crops and do not include the requirements of poplar trees as a result fertility of the soil gets depleted after few rotations and then yield of both intercrops and the poplar is decreased. \n\n In soils with medium in available N, apply 23 g N (50 g urea) and 39 g P205(85 g DAP) in each pit at the planting time of poplar. Apply 78 g N (170 g urea) in I m diameter ring around the tree during ISt year, 120 g N (260 g urea) in 2 m diameter ring during 2nd year and 161,198, 239,281 g N (350, 430, 520 and 610 g urea), respectively in 3 m wide strip (1.5 m on each side of tree row) during 3 , 4 , 5 and 6th year of tree growth, respectively. \nTime 3rd 4th 5th of application is also important. Every year, apply 1/3rd urea during May after harvesting of wheat, 1/3rd urea in July and the remaining 1/3rd urea in September. There is no need of P application to poplar every year. ",
    });

    elements.push({
      title: "Zinc application:",
      desc: " The deficiency of Zn is exhibited in the nursery by interveinal yellowing of recently matured leaves. \n Apply 40 kg zinc suphate heptahydrate (21 %) or 25 kg zinc sulphate monohydrate (33%) per acre in Zn-deficient soils at the planting time of nursery.\n Repeat the dose after 2 years. In the plantation apply 100, 200 and 300 g/plant zinc sulphate heptahydrate or 65, 130 and 190 g/plant zinc sulphate monohydrate in Zn-deficient soils during 1 st, 3rd and 5th year of poplar growth in I m diameter basin, 2 m diameter basin or 3 m wide strip (1.5 m on each side of tree row), respectively around the plants after harvesting of the rabi intercrop.",
    });

    elements.push({
      title: "Pest's control:",
      desc: "DMoth appears during March-April and peak activity is from July-October from overwintering pupae. The larvae eat away all the tissue of the leaves leaving the veins. Spray in nursery and plantation on appearance of larval damage during July and October with Quinalphos 25 EC @ 4 ml/litre or Profenophos @ 2 ml/litre using 500 litres of water per acre. Plough the field 2—3 times in December to bury pupae in soil debris.",
    });
    return elements;
  };

  const disease = () => {
    var elements = [];

    // elements.push({
    //     title: "Drs: ",
    //     desc: " G-4ab.",
    // });

    elements.push({
      title: "Drechslera leaf spot caused by Drechslera maydis: ",
      desc: "Drechslera leaf spot of poplar is a serious disease especially in nursery causing premature defoliation. The disease starts as minute specks of light brown colour on lower leaves of the plant. These enlarge to form spots of size 0.4 —0.8mm. The spots are light brown with dark purplish brown margins that may be surrounded by chlorotic area. Medium to large sized spots have ring type pattern. The disease appears in rainy season. The disease is more serious in nursery sown in the same place year after year. ",
    });

    elements.push({
      title: "Myrothecium leaf spot caused by Myrothecium roridum: ",
      desc: "Myrothecium leaf spot of poplar occurs in nurseries near the riverbed during hot and humid months. Spots on leaves are round to oblong or irregular shaped, 5 to 20 mm in diameter, which are light brown in the center with dark brown margins. Spots coalesce to form bigger patches giving blight appearance. Spots are characterized by the appearance of sporodochia in the center of the spots as raised, rounded structures which are initially white and later turned olive green to black in colour. ",
    });

    elements.push({
      title: "Cercospora leaf spot caused by Cercospora populina: ",
      desc: "Brown to dark brown round spots of variable sizes appears on leaves, the centre of which contains a dark speck giving a dot like appearance. The colour of the spots is same on both the sides. The spots coalesce to form bigger patches, to give blightened appearance. Premature defoliation is very common due to this. The disease is more during the month of August and September.",
    });

    elements.push({
      title: "Alternaria leaf spot caused by Alternaria alternate:",
      desc: "The disease starts as minute spots of brown colour with dark margins. These spots merge with each other to form large irregular patches. A close observation of spots show ill defined concentric rings. The maximum incidence may occur up to 90 per cent. ",
    });

    elements.push({
      title:
        "Cutting rot caused by Botryodipolodia palmarum&CIadosporium lunata:",
      desc: " Cutting rot is a serious disease of nursery. The infection appears on the cuttings as black raised areas having pycnidia in it. These pycnidia release millions of spores of the fungus when they absorb water. Black discoloration and fungal mass below the bark can be seen. Browning of the wood portion and rotting is also common. Ultimately the cutting along with the roots rots and sprouts wilts lead to the death of the plant. The disease intensity appears to increase with the termites' injury to the cuttings. ",
    });

    elements.push({
      title: "Stem canker and die back caused by Botryodiplodia:",
      desc: "Stem canker is characterized by sunken lesions on the bark, on southern side of main trunk facing the scorching sun during the day time. The bark is predisposed to the fungal attack by sun burns and bark becomes thin and dry. Disease can be easily identified from the black pycnidial bodies on the sunken bark which can cause further spread of the disease. Due to oozing of sap in some plantations bark starts rotting and attacked by weak pathogens like Phomopsis sp. and Botryodiplodia spp. Injuries due to tractor or farm operations or pruning, etc. predispose the plant to attack of the canker fungi.",
    });

    return elements;
  };

  const instectOnPoplar = () => {
    var elements = [];

    // elements.push({
    //   title: "Drs: ",
    //   desc: " G-4ab.",
    //   image: "",
    // });

    elements.push({
      title: "Poplar defoliators (PD):",
      desc: "Clostera fulgurita is a major defoliator of poplar resulting in severe damage in nursery as well as plantations. The incidence of the insect was recorded in all agro- climatic zones. The peak period of activity is from July to November with two distinct peaks during July and October. Population of this pest is more during second peak.DefoIiator'spopuIation followed trend similar as of its incidence. The moths are grey brown with larger forewings (3.6-4.6 cm wing expanse), white irregular markings on the anterior half. Male moth has an anal tuft of hairs. It lays light yellow coloured eggs in clusters formed of many rows on the leaves and twigs (rarely). Full grown larva is pale brown covered sparsely with short pale hairs. Pupa is obtect and dark brown in colour. The female pupa is larger than the male. There are 10 generations per year. ... Spray  Monocrotophos   500 - 600ml,  Chloropyriphos + cypermethrin 750ml,  Lara or  Hiltron  1.5%  V.V. per acre. Attack noticed in month of July. ",
      image: "Poplardefoliators.jpg",
    });

    elements.push({
      title: "Phalanta leaf feeder:",
      desc: "Phalanta phalanta causes heavy damage by eating away all the leaf tissue. This is a minor defoliator of poplar and the peak activity period is limited from July to September. Adults are light brown with black spots on the forewings and the hindwings. The larvae are creamish initially and finally turn brown in the later instars. There are black spines like structures all over the larval body. They eat the leaf wholly without skeletonising it. The pupation takes place on the leaves, branches and trunk. The pupa is fluorescent green in colour. ",
      image: "Phalantaleaffeeder1.png",
    });

    elements.push({
      title: "Poplar leaf webber:",
      desc: "Asphadastis cryphomycha eats away all the leaf tissue by webbing two leaves together and feeding inside. Newly hatched larvae are creamy white with shining black head, which changed to greenish yellow with light brown head in the fifth instar. The prepupae are broad anteriorly and tapering posteriorly. The colour of the pupa is yellow initially which changed to dark brown finally. The general body colour of the moth is dusky brown; the forewings are light brown with irregular black spots dorsally, having one black band on ventral side and fringed with black hairs at apical margins. ",
      image: "Poplar leaf webber.jpg",
    });

    elements.push({
      title: "Bark eating caterpillar:",
      desc: "Indarbela quadrinotata is a serious and major pest. Extent of damage caused by this insect may be up to 30 per cent. The attack is more pronounced in plantations where it causes heavy qualitative and quantitative loss. The neglected plantations are more prone to the attack of this insect. Repeated and severe attack by this insect for 2-3 years results in complete death of the plant. The peak activity period of the pest extends from July to October. The larvae of the insect are usually nocturnal feeders. In the field, first instar larvae are dirty brown while the full grown caterpillars (5-6 cm in length) have pale brown to brown body with dark brown head capsule.",
      image: " Bark eating caterpillar-.jpg",
    });

    elements.push({
      title: "Poplar stem borer:",
      desc: "Apriona cinerea makes circuitous tunnel/gallery formations in the branches and stem of the host plants, make the plants weak and unsuitable for timber purposes. The trunk and branches are hollowed out. Mostly 1-3 years old plants are more prone to attack and older ones are free from attack. In the nurseries the grubs bore through the stools and make circuitous galleries downwards. Generally single grub is found in the nursery plants. In the plantations, the galleries extend from the branches, trunk and finally to the roots. Circular holes at 10-12 cm distance can be seen on the branches/trunk. The grubs are active up to October.",
      image: "poplar-borer-0284067-sized.jpg",
    });

    elements.push({
      title: "Termites",
      desc: "  The incidence of termites may be up to 80 per cent.lt is a very serious pest which can wipe out the entire population of trees if not controlled in time.  Gain entry from the  injured  part  of  the roots/stem near the  ground  and seriously  damaged  the  xylem, often resulting in the death of the plants. Treated  with  chloropyriphos 2.5 ltr. per acre or 4 to 5ml per plants twice in a year with frequent irrigation should be arranged.",
      image: "termite-infestation.jpg",
    });

    elements.push({
      title: " Stem Borer :-",
      desc: "   Spread Phorate -h   8kg  per  acre  upto  second   year   of plantation or inject kerosene oil in stem  2 to 5 ml per hole.",
      image: "wallLogo.png",
    });
    elements.push({
      title: "Pygeria :- ",
      desc: "Spray Carbyl 50%w .P   250 - 300gm with 2 ppm ratio or   400 - 500ml Monocrotophos 36SL per acre.",
      image: "wallLogo.png",
    })
    elements.push({
      title: "Bark Insects :-",
      desc: " Bordeaux paste upto 4 feet fromground level in month of march.",
      image: "wallLogo.png",
    })

    elements.push({
      title: "Mites :-",
      desc: "   Spray    with     2ml    Roger 30   E.C or Matasystox   per    ltr of water and to be repeated after interval of 15 days.",
      image: "wallLogo.png",
    })
    return elements;
  };




  const getImage = (image) => {
    return <img className="imageContainer_Table_insect" src={require(`../Assests/${image}`)} />
  }

  const renderInsects = () => {
    return (
      <>
        <table>
          <tbody>

            <tr>
              <th>Insect Name </th>
              <th>Description</th>
              <th>Insect Picture</th>
            </tr>




            {instectOnPoplar().map((item) =>
            (
              <tr key={item.title}>
                <td>{item.title}</td>
                <td>{item.desc}</td>
                <td>{getImage(item.image)}</td>

              </tr>))}
          </tbody>
        </table>


      </>
    );
  };

  const renderDisease = () => {
    return (
      <>
        <ul>
          {disease().map((item) => (
            <li className="a" key={item.title}>
              {item.title}
              <ul className="b">
                <li>{item.desc}</li>
              </ul>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const renderRecomenstation = () => {
    return (
      <>
        <ul>
          {recommentation().map((item) => (
            <li className="a" key={item.title}>
              {item.title}
              <ul className="b">
                <li>{item.desc}</li>
              </ul>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <>
      <div className="mainContainerWorkShop">
        <h2> Proceeding of the workshop-2018</h2>

        <h6> attended by <RFarm_Title_2 /> in Haryana.</h6>


        <h4 className="subMainHeading"> Recommendations for farmer</h4>
        <ImageClass src={wheatPoplar} className="p_imageContainer_left" />

        <div> {renderRecomenstation()}</div>
        <TextBanner />
        <hr></hr>

        <h4 className="subMainHeading"> Disease of poplar</h4>
        <ImageClass src={poptreeWintr} className="p_imageContainer_right" />

        <div className="p_DivContauner"> {renderDisease()}</div>
        <hr></hr>
        <h4 className="subMainHeading"> Insects on poplar</h4>


        <div> {renderInsects()}</div>
        <br />
        <TextBanner />





        <h4> Major plant deficiencies and their solutions:  </h4>
        <ul>1. Zinc Deficiency :- Its  quite  common  in   many areas and its shown yellowing of inter-venal space of the leaves. Zink Sulphate - 21% applied with 10kg per acre.</ul>
        <ul>2. Sun Scratches or Fire Damaging :- The bark  becomes cancured in patches and may flick off. The scars are usually in both cases on one side. While wash of the stem with lime water containing small quantities of Copper Sulphate can help to minimize this damage.</ul>

        <h4>Some major diseases of plants and their solutions: </h4>
        <ul>1. Color Rot :-  Color  Rot  cause  death   of    plants through  roots.   Treat   roots    with Emisan -6, 4 to 5gm per plant in deep root zone area.</ul>
        <ul>2. Blights :- It effects the leaves during the month of August and September control with 0.2% Bavastin  or  0.1%  Balaton sprayed twice in a month.</ul>
        <ul>3. Wilt :- It  seems  first  time  in   Poplar  plantation during May & June. Spray with 500 gm per acre sulphur-80%  W.P  with  frequent   irrigation  in these months.</ul>

        <TextBanner />



    </div >
    </>
  );
}

//   function RFarm_Title_2() {
//     return (
//       <span className= "clones"> Raji Farm </span>
//     )
//   }

export { Workshop };
