import "./Role_CommonComplexF.css";
import React, { useState, useEffect } from "react";



function Role_CommonComplexF() {
 
    const nitrogenN = () => {

        return (
            <>
         <span className="ccf_Nitrogen" > Nitrogen </span> 
            </>
        )
    }
    const ccf_PhosphorusN = () => {

        return (
            <>
         <span className="ccf_Phosphorus" > Phosphorus </span> 
            </>
        )
    }
    const ccf_potashN = () => {

        return (
            <>
         <span className="ccf_potash" > Potash </span> 
            </>
        )
    }




    
    
    return (
    <> 
     <div className="main_Role_CommonComplexFContanier">
        <h3 className="h3_role"> Different Complex fertiliser and there nutrient</h3>
     
      <div className="tableContiner_role">
      <table>
  <tr>
    <th>Short form </th>
    <th>Name</th>
    <th>Chemical formula </th>
    <th>Nutrient</th>
   
  </tr>
  <tr>
    <td>NPK</td>
    <td>nitrogen - phosphorus - potash</td>
    <td>N-P2O5-K2O</td>
    <td> {nitrogenN()}  - {ccf_PhosphorusN()} - {ccf_potashN()}</td>
    
  </tr>
  <tr>
    <td>NPK(S)</td>
    <td>nitrogen - phosphorus - potash - Sulphur</td>
    <td>N-P2O5-K2O-S</td>
    <td>{nitrogenN()} -  {ccf_PhosphorusN()}  - {ccf_potashN()} - Sulphur</td>
    
  </tr>

  <tr>
    <td>UREA</td>
    <td>Carbamide</td>
    <td>(CH₄N₂O)</td>
    <td>{nitrogenN()} </td>
   
  </tr>


  <tr>
    <td>DAP</td>
    <td>Di-ammonium Phosphate (DAP) 18% N  + 46% Phosphorus (P2O5)</td>
    <td>(NH4)2HPO</td>
    <td>{nitrogenN()} -  {ccf_PhosphorusN()}  </td>
   
  </tr>



  <tr>
    <td>MAP</td>
    <td>Mono-ammonium Phosphate</td>
    <td>NH4H2PO4</td>
    <td>{nitrogenN()} -  {ccf_PhosphorusN()}  </td>
   
  </tr>


  <tr>
    <td>TSP</td>
    <td>Triple Super Phosphate</td>
    <td>[Ca(H₂PO₄)₂ .H₂O]</td>
    <td>calcium -  {ccf_PhosphorusN()}  </td> 
  </tr>


  <tr>
    <td>SSP</td>
    <td>Single Super Phosphate </td>
    <td>[CaH6O8P2+2]</td>
    <td>calcium -  {ccf_PhosphorusN()}  </td>
   
  </tr>

  <tr>
    <td>MOP</td>
    <td>Muriate of potash,MOP , potassium chloride contains 60% potash. </td>
    <td>KCl</td>
    <td>{ccf_potashN()} - Chlorine </td>
  </tr>

  <tr>
    <td>Gypsum</td>
    <td>Gypsum </td>
    <td>CaSO 4·2H2O</td>
    <td>Sulfur - Calcium </td>
  </tr>


      </table>
      </div>
     </div>
    
    </>
  )}

  export default Role_CommonComplexF;
