import React from 'react';

const DashboardView = () => (
  <div>
    <h2>Dashboard View</h2>
    

    <div id="invoice">
     
     <h2>Invoice</h2>
     <h1>  Raji Agri Farm - Poplar Nursery Farm </h1>

     <div id="buyerSellerDiv">
       <div id="BillNoDiv">
         <p class="p_margin2">Invoice Number: [Invoice Number]</p>
       </div>
       <div id="DateDiv">
         <p class="p_margin2">Date: [Date]</p> 
         <p class="p_margin2">Time: [Date]</p>
       </div>
     </div>


     <div id="buyerSellerDiv">
       <div id="buyerDiv">
           <table>
               <tr>
                <td class="classTablefontAddress_td" colspan="2"><h4 class="h4_heading">Seller Detail</h4></td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td" colspan="2" > Raji Agri Farm - Poplar Nursery Farm</td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td"  > Village</td>
                   <td class="classTablefontAddress_td" > Chunta Khurd (Ropar Bela Road) Near Gurmat Missionary Collage</td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td" > Phone</td>
                   <td class="classTablefontAddress_td" >  Raji : 98141-64511 [Whatsapp] , 99142-44511 </td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td" > </td>
                   <td class="classTablefontAddress_td" > Ajay : 99142-99433  </td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td" > </td>
                   <td class="classTablefontAddress_td" >Vijay : 73472-00037 </td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td">Website  </td>
                   <td class="classTablefontAddress_td"> Rajifarm.com </td>
                 </tr>

           </table>
       </div>
       <div id="SellerDiv">

           <table class="classTablefontAddress">
               <tr>
                <td colspan="2" class="classTablefontAddress_td"><h4 class="h4_heading">Buyer Detail</h4></td>
                 </tr>
                 <tr>
                   <td  colspan="2" class="classTablefontAddress_td" >S.Simrandeep Singh</td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td" > Son of</td>
                   <td class="classTablefontAddress_td" > Bikramjeet Bikramjeet Bikramjeet singh</td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td" > Phone</td>
                   <td class="classTablefontAddress_td" > 98765-98765 </td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td" >Village </td>
                   <td class="classTablefontAddress_td" >  Bhaku majra </td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td" >Town </td>
                   <td class="classTablefontAddress_td" >Nurpur  </td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td">Teh:  </td>
                   <td class="classTablefontAddress_td">  Chamkaur Sahib  </td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td"> District : </td>
                   <td class="classTablefontAddress_td">  Ropar </td>
                 </tr>

                 <tr>
                   <td class="classTablefontAddress_td"> Other Contact </td>
                   <td class="classTablefontAddress_td"> S.Simrandeep Singh </td>
                 </tr>
                 <tr>
                   <td class="classTablefontAddress_td"> Phone </td>
                   <td class="classTablefontAddress_td"> 98765-98765 </td>
                 </tr>

           </table>
       
         <br />
        
         <br />
       </div>
     </div>

     <table>
       <thead>
         <tr>
           <th>sr.no</th>
           <th>Item Description</th>
           <th>Quantity</th>
           <th>Unit Price</th>
           <th>Total</th>
         </tr>
       </thead>
       <tbody>

         <tr>
           <td>1</td>
           <td>110</td>
           <td>300</td>
           <td>Rs 50.00</td>
           <td>$100.00</td>
         </tr>
         <tr>
           <td>2</td>
           <td>109</td>
           <td>1</td>
           <td>$30.00</td>
           <td>$30.00</td>
         </tr>
         <tr>
           <td>2</td>
           <td>109</td>
           <td>1</td>
           <td>$30.00</td>
           <td>$30.00</td>
         </tr>
         <tr>
           <td>2</td>
           <td>109</td>
           <td>1</td>
           <td>$30.00</td>
           <td>$30.00</td>
         </tr>

         <tr>
           <td colspan="2">Labour</td>
           <td>400</td>
           <td>8</td>
           <td> 3200</td>
         </tr>

         <tr>
           <td colspan="4">Transport</td>
           <td> 3200</td>
         </tr>
       </tbody>
     </table>

     <div class="total">
       <p><strong>Total: $130.00</strong></p>
     </div>

     <table>
       <tr>
           <td> Date </td>
           <td> Mode  </td>
           <td> Given Amount</td>
           <td> Balance </td>
       </tr>

       <tr>
           <td> 30 Agust </td>
           <td> CASh  </td>
           <td> 5000</td>
           <td> 80000 </td>
       </tr>

       <tr>
           <td> 30 Agust </td>
           <td> CASh  </td>
           <td> 2000</td>
           <td> 60000 </td>
       </tr>

       <tr>
           <td> 30 Agust </td>
           <td> PTM  </td>
           <td> 60000</td>
           <td> 00 </td>
       </tr>

     </table>

  

     <div>
       <h4 class="h4_heading">Extra notes</h4>
       <li> Transport [For] added in above item</li>
     </div>
     <hr/>
     <div> 
       1. Goods once sold will not be returned. <br/>
       2. All Subject to ROPAR jurisdictions only E.&OE

     </div>

   </div>

  </div>
);




  // export default Admin;
  export default DashboardView;
